import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Row, Descriptions, Col, PageHeader, Modal, Spin } from "antd";
import { getDateFormate } from "../../utility/commonFunction";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { get_Gallery_details } from "../../redux/gallery/actionCreator";
import FeatherIcon from "feather-icons-react";

const ViewGallery = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [imgModalOpen, setImgModalOpen] = useState(false);
    const [previewMedia, setPreviewMedia] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);

    useEffect(() => {
        setWindowWidth(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
    }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);

    const GalleryData = useSelector((state) => state.GalleryReducer.galleryData);

    useEffect(() => {
        if (location && location.state && location.state.id !== undefined) Gallery(location.state.id);
    }, []);

    const Gallery = (id) => {
        dispatch(get_Gallery_details(location.state.id));
    };

    const showImagePreview = (img) => {
        console.log("🚀 ~ file: ViewGallery.js:36 ~ showImagePreview ~ img:", img);
        setImgModalOpen(true);

        setPreviewMedia(
            img !== "" && img !== undefined && img !== null ? (
                <div className="userModalImag">
                    <img
                        crossOrigin="anonymous"
                        alt=""
                        src={img}
                        style={{
                            objectFit: "cover",
                            height:
                                windowWidth > 992
                                    ? "450px"
                                    : windowWidth < 992 && windowWidth > 575
                                    ? "350px"
                                    : windowWidth < 575 && windowWidth > 399
                                    ? "300px"
                                    : windowWidth < 399
                                    ? "270px"
                                    : "100%",
                            width:
                                windowWidth > 992
                                    ? "450px"
                                    : windowWidth < 992 && windowWidth > 575
                                    ? "350px"
                                    : windowWidth < 575 && windowWidth > 399
                                    ? "300px"
                                    : windowWidth < 399
                                    ? "270px"
                                    : "450px",
                        }}
                    />
                </div>
            ) : (
                "No Image"
            )
        );
    };

    return (
        <>
            {Gallery && Gallery.length > 0 ? (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="GeneralAdvViewPage">
                            <div className="viewAdvertisement">
                                <PageHeader
                                    ghost={false}
                                    className="site-page-header"
                                    title={
                                        <div className="adv_pageTitle">
                                            <FeatherIcon
                                                icon="arrow-left-circle"
                                                size="28px"
                                                onClick={() => window.history.back()}
                                                style={{ cursor: "pointer", marginRight: "10px" }}
                                            />
                                            <span style={{ fontSize: "20px" }}>{t("Gallery_Details")}</span>
                                        </div>
                                    }
                                />
                            </div>

                            <div className="description_Container">
                                <Descriptions bordered column={1} labelStyle={{ width: "300px" }}>
                                    <Descriptions.Item label={t("Title")}>
                                        {GalleryData && GalleryData[i18n.language] && GalleryData[i18n.language].title}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Description")}>
                                        {GalleryData && GalleryData[i18n.language] && GalleryData[i18n.language].description}
                                    </Descriptions.Item>

                                    <Descriptions.Item label={t("Date")}>{GalleryData && getDateFormate(GalleryData.created_at)}</Descriptions.Item>
                                </Descriptions>
                            </div>
                            <div className="advertisementBanner">
                                <Divider orientation="left">
                                    <h3>{t("Gallery_Image")} </h3>
                                </Divider>
                                <div>
                                    <Row gutter={25}>
                                        <Col span={24}>
                                            <div className="galleryImageContainer">
                                                {GalleryData?.image?.map((item, index) => {
                                                    return (
                                                        <div key={item} className="galleryImageBox">
                                                            <img
                                                                src={item.url}
                                                                alt=""
                                                                style={{
                                                                    objectFit: "cover",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    borderRadius: "8px",
                                                                    verticalAlign: "center",
                                                                }}
                                                                onClick={() => showImagePreview(item.url)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="spinnerName">
                            <Spin size="large"></Spin>
                        </div>
                    </Col>
                </Row>
            )}

            <Modal open={imgModalOpen} onCancel={() => setImgModalOpen(false)} className="imageModal" footer={null} maskClosable={false} centered>
                {previewMedia}
            </Modal>
        </>
    );
};

export default ViewGallery;
