import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import VerificationModal from "./VerificationModal";
import { getRandomColor } from "../../utility/commonFunction";
import { useRouteMatch, useHistory } from "react-router-dom";
import { EyeOutlined, SmileOutlined, UserAddOutlined, UserOutlined, CloseOutlined, EditTwoTone } from "@ant-design/icons";
import {
    Input,
    Avatar,
    Pagination,
    Space,
    Tabs,
    Row,
    Spin,
    Col,
    PageHeader,
    Button,
    Empty,
    Form,
    Modal,
    Tooltip,
    Popover,
    Table,
    Divider,
    Image,
    Typography,
    Radio,
} from "antd";

import { getUserStatusData, putUserDetailsUpdate, getUpdateUserDetailList } from "../../redux/AdminPannel/actionCreator";
import UpdateDetailsModal from "./updateDeatilsModal";
import { DeactivateUserData } from "../../redux/AdminPannel/actionCreator";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getItem, setItem } from "../../utility/localStorageControl";
import { userDataExport } from "../../redux/user_list/actionCreator";
import moment from "moment";
import VerifiedUserEditModal from "./VerifiedUserEditModal";
import { global_api_data } from "../../redux/globalAPIFetch/actionCreator";

const { Text } = Typography;

const { Search } = Input;

const UsersList = () => {
    const dynamicStatusTab = getItem("userListActiveTab");

    const Push = (targetVal) => {
        history.push({
            pathname: `${path}/user_detail`,
            state: {
                id: targetVal,
                status: userStatus,
            },
        });
    };
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [userStatus, setUserStatus] = useState(dynamicStatusTab);
    const [searchValue, setSearchValue] = useState("");
    const { path } = useRouteMatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [typeOfFile, setTypeOfFile] = useState("pdf");

    var statusArr = ["pending", "verified", "update", "deactivated"];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [userId, setUserId] = useState("");

    const [value, setValue] = useState(1);
    const [imgModalOpen, setImgModalOpen] = useState(false);
    const [previewMedia, setPreviewMedia] = useState([]);
    const [windowWidth, setWindowWidth] = useState(
        window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [targetData, setTargetData] = useState({});
    const [showUpdateDetailModal, setShowUpdateDetailModal] = useState(false);

    useEffect(() => {
        // get device side
        setWindowWidth(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
    }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth])

    const onChange = (e) => {
        setValue(e.target.value);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        handleExport(typeOfFile);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleExport = async (value) => {
        console.log(value);
        const data = await dispatch(userDataExport({ collectionType: value }));
        console.log(data, data.url, "pdf");
        const link = document.createElement("a");
        link.download = "Example-PDF-File";
        link.href = data.url;
        link.click();
    };

    const userStatusDataList = useSelector((state) => state?.UsersStatusReducer?.userStatus?.data?.list);
    const data = userStatusDataList && userStatusDataList;
    const paginationCount = useSelector((state) => state?.UsersStatusReducer?.userStatus?.data?.total_counts);
    const userUpdateDetails = useSelector((state) => state.UsersStatusReducer.updatedUsers);
    const updatedUsersData = userUpdateDetails && userUpdateDetails.data;
    const updatedUsersPagination = userUpdateDetails && userUpdateDetails.pagination && userUpdateDetails.pagination.total_counts;
    const getGlobalData = useSelector((state) => state?.GlobalReducer?.global_api);

    useEffect(() => {
        usersStatusListData(searchValue, currentPage);
        updatedDetailsUsers(searchValue, currentPage);
        getGlobalDataApi();
    }, [userStatus, dataPerPage]);


    const getGlobalDataApi = async () => {
        await dispatch(global_api_data(!getGlobalData));
    }

    const usersStatusListData = async (searchKey, Page, pageNumber) => {
        const search_Val = searchKey === undefined || "" || null ? "" : searchKey;

        if (userStatus != "update") {
            let params = {
                english: search_Val.match(/[^\s]+\s*$/) && userStatus ? search_Val : "",
                hindi: search_Val.match(/^[\u0900-\u097F]+$/) && userStatus ? search_Val : "",
                status: userStatus != "update" ? userStatus : "",
                page: Page,
                limit: dataPerPage,
            };
            await dispatch(getUserStatusData(params));
        }
    };

    const updatedDetailsUsers = async (searchKey, page, pageNumber) => {
        const search_Val = searchKey === undefined || "" || null ? "" : searchKey;
        let params = {
            search: search_Val,
            page: page,
            limit: dataPerPage,
        };
        dispatch(getUpdateUserDetailList(params));
    };
    const onSearch = async (value) => {
        setSearchValue(value);

        userStatus !== "update" ? usersStatusListData(value.trim(), "", "") : updatedDetailsUsers(value.trim(), "", "");
    };

    const searchClear = () => {
        usersStatusListData("");
        setSearchValue();
    };

    const handlePagination = (page, pageNumber) => {
        userStatus !== "update" ? usersStatusListData("", page, pageNumber) : updatedDetailsUsers("", page, pageNumber);
        setCurrentPage(page);
        setDataPerPage(pageNumber);
    };
    const onChangeTab = (key) => {
        setItem("userListActiveTab", key);
        setUserStatus(key);
        setSearchValue("");
        setCurrentPage(1);
        setDataPerPage(10);
    };

    const deactiveUser = async (v) => {
        let a = await dispatch(DeactivateUserData(v));
        if (a) {
            usersStatusListData("", currentPage, "");
        }
        setOpenModal(false);
    };

    //for update users status  inactive =>active
    const confirmStatusIntoActive = (val, mail) => {
        Modal.confirm({
            title: "Are you sure you wants to Active User" + " " + `${mail}` + " " + "?",
            okText: "Yes",
            icon: <SmileOutlined style={{ fontSize: "30px", color: "#2eb605" }} />,
            okType: "success",
            onOk: () => {
                activeUser(val);
                usersStatusListData();
            },
        });
    };
    const activeUser = async (v) => {
        const obj = {};
        obj.status = "verified";
        const a = await dispatch(putUserDetailsUpdate(obj, v));
    };


    // for image preview
    const showImagePreview = (img) => {
        setImgModalOpen(true);

        img !== "" && img !== undefined && img !== null
            ? setPreviewMedia(
                <div className="userModalImag">
                    <img
                        crossOrigin="anonymous"
                        alt=""
                        src={img}
                        style={{
                            objectFit: 'cover',
                            height: windowWidth > 992 ? '450px' : windowWidth < 992 && windowWidth > 575 ? '350px' : windowWidth < 575 && windowWidth > 399 ? '300px' : windowWidth < 399 ? '270px' : '100%',
                            width: windowWidth > 992 ? '450px' : windowWidth < 992 && windowWidth > 575 ? '350px' : windowWidth < 575 && windowWidth > 399 ? '300px' : windowWidth < 399 ? '270px' : '450px'
                        }}
                    />
                </div>
            )
            : "No Image";

    };

    // for table
    const columns = [
        {
            title: t("Image"),
            dataIndex: "Image",
            key: "image",
            align: "center",
            width: 80,
            render: (t, r) => {
                if (r.profile_photo == "") {
                    return (
                        <>
                            <Avatar icon={<UserOutlined />} style={{ backgroundColor: getRandomColor() }} />
                        </>
                    );
                } else {
                    return (
                        <>
                            {/* <Image
                                height={35}
                                width={35}
                                crossOrigin="anonymous"
                                alt=""
                                src={`${r.profile_photo}`}
                                style={{
                                    borderRadius: 50,
                                }}
                                className="custom-image"
                            /> */}

                            <img
                                height={35}
                                width={35}
                                crossOrigin="anonymous"
                                alt=""
                                src={`${r.profile_photo}`}
                                style={{
                                    borderRadius: 50,
                                }}
                                className="usersTableImage"
                                onClick={() => showImagePreview(r.profile_photo)} />
                        </>
                    );
                }
            },
        },

        {
            title: t("Language"),
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 170,
            sorter: (a, b) => {
                return a.en.name.localeCompare(b.en.name);
            },
            render: (icon, value) => {
                return value && value.lang_code == "" ? "-" : value && value.lang_code == "en" ? t("English") : t("Hindi");
            },
        },
        {
            title: t("Name"),
            dataIndex: `${("name", "surname")}`,
            key: "name",
            align: "center",
            width: 200,
            sorter: (a, b) => {
                return a.en.name.localeCompare(b.en.name);
            },
            render: (icon, value) => {
                return userStatus === "pending"
                    ? value.en.name === ""
                        ? value && value.hn && "-/ " + value.hn.name + " " + value.hn.surname
                        : value && value.en && value.en.name + " " + value.en.surname + " /-"
                    : value && value[i18n.language] && value[i18n.language].name !== ""
                        ? value && value[i18n.language] && value[i18n.language].name + " " + value[i18n.language].surname
                        : "-";
            },
        },
        {
            title: t("Email"),
            dataIndex: "email",
            key: "email",
            align: "center",
            ellipsis: true,
            width: 200,
            sorter: (a, b) => a.email.localeCompare(b.email),
            render: (icon, value) => {
                return value && value.email == "" ? "-" : value && value.email;
            },
        },
        {
            title: t("Contact_Number"),
            dataIndex: "phone_number",
            key: "phone_number",
            align: "center",
            width: 180,
            sorter: (a, b) => {
                return a.phone_number - b.phone_number;
            },
            render: (icon, value) => {
                return value && value.phone_number == "" ? "-" : value && value.phone_number;
            },
        },
        {
            title: t("Postal_Address"),
            dataIndex: "postal_address",
            key: "postal_address",
            align: "center",
            ellipsis: true,
            width: 200,
            render: (icon, value) => {
                return userStatus === "pending"
                    ? value.en.name === ""
                        ? (value && value.hn && value.hn.postal_address !== undefined) || null || ""
                            ? value && value.hn && value.hn.postal_address
                            : "-"
                        : (value && value.en && value.en.postal_address !== undefined) || null || ""
                            ? value && value.en && value.en.postal_address
                            : "-"
                    : (value && value[i18n.language] && value[i18n.language].postal_address) == undefined || null || ""
                        ? "-"
                        : value && value[i18n.language] && value[i18n.language].postal_address;
            },
        },
        {
            title: t("JoinedAt"),
            dataIndex: "joined_at",
            key: "joined_at",
            align: "center",
            width: 200,
            render: (icon, value) => {
                return value && moment(value.created_at).format("LLL");
            },
        },
        {
            title: t("Last_Login"),
            dataIndex: "last_login",
            key: "last_login",
            align: "center",
            width: 200,
            render: (icon, value) => {
                return value && value.last_login ? moment(value.last_login).format("LLL") : "-";
            },
        },

        {
            title: t("Actions"),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            width: 100,
            render: (record, target) => {
                // //console.log("🚀 ~ file: UsersList.js:384 ~ UsersList ~ target:", target)
                return (
                    <div className="actionContentBox">
                        <FeatherIcon icon="more-vertical" size="20px" color="blue" style={{ cursor: "pointer" }} />
                        <div className="tableActionContent">
                            {userStatus != "update" ? (
                                <Tooltip placement="top">
                                    <EyeOutlined onClick={() => Push(target._id)} style={{ fontSize: "25px", color: "#f1ca1a" }} />
                                </Tooltip>
                            ) : (
                                <Tooltip placement="top">
                                    <EyeOutlined onClick={() => Push(target.user_id)} style={{ fontSize: "25px", color: "#f1ca1a" }} />
                                </Tooltip>
                            )}

                            <Divider type="vertical" />
                            {userStatus == "verified" ? (
                                <>
                                    <Tooltip placement="top">
                                        <FeatherIcon
                                            style={{ cursor: "pointer" }}
                                            icon="trash"
                                            size="20px"
                                            color="red"
                                            onClick={() => {
                                                setUserId(target._id);
                                                setOpenModal(true);
                                            }}
                                        />
                                    </Tooltip>

                                    <Tooltip placement="left">
                                        <EditTwoTone
                                            className="editIcon"
                                            onClick={() => {
                                                setShowVerificationModal(true);
                                                setTargetData(target)
                                            }}
                                            style={{ fontSize: "20px", marginLeft: "13px", marginTop: '1px' }}
                                        />
                                    </Tooltip>
                                </>
                            ) : userStatus == "pending" ? (
                                <VerificationModal target={target} style={{ fontSize: "25px", color: "#08c" }} />
                            ) : userStatus == "inactive" ? (
                                <UserAddOutlined
                                    style={{ fontSize: "25px", color: "#069c0e" }}
                                    onClick={() => confirmStatusIntoActive(target._id, target.email)}
                                />
                            ) : userStatus == "update" ? (
                                <Tooltip placement="left">
                                    <EditTwoTone
                                        className="editIcon"
                                        onClick={() => {
                                            setShowUpdateDetailModal(true);
                                            setTargetData(target)
                                        }}
                                        style={{ fontSize: "20px", marginLeft: "13px", marginTop: '1px' }}
                                    />
                                </Tooltip>


                            ) : (
                                ""
                            )}

                        </div>
                    </div>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };
    setItem("User_status", "pending");
    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} userId={userId}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={require("./images.png")} style={{ height: "120px" }} />
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0 -10px",
                        }}
                    >
                        {t("Are_You_Sure_?")}
                    </p>
                    <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem" }}>{t("Do_you_really_want_to_delete_these_records_This_process_cannot_be_undone")}</p>
                    </div>
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#60c7c1",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            {t("Cancel")}
                        </button>
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#f15e5e",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                deactiveUser(userId);
                            }}
                        >
                            {t("Delete")}
                        </button>
                    </div>
                </div>
            </Modal>


            <Modal
                open={imgModalOpen} onOk={handleOk} onCancel={() => setImgModalOpen(false)}
                className="imageModal"
                footer={null}
                maskClosable={false}
                centered
            // width={windowWidth > 992 ? 520 : windowWidth < 992 ? 'fit-content' : 520}
            >
                {previewMedia}
            </Modal>

            {showVerificationModal &&
                <VerifiedUserEditModal
                    showVerificationModal={showVerificationModal}
                    setShowVerificationModal={setShowVerificationModal}
                    target={targetData}
                    pageLimit={dataPerPage}
                    currentPage={currentPage}
                    english={searchValue}
                    hindi={searchValue}
                    style={{ fontSize: "25px", color: "#08c" }}
                />
            }
            {
                showUpdateDetailModal &&
                <UpdateDetailsModal
                    showUpdateDetailModal={showUpdateDetailModal}
                    setShowUpdateDetailModal={setShowUpdateDetailModal}
                    target={targetData}
                    pageLimit={dataPerPage}
                    currentPage={currentPage}
                    search={searchValue}
                    style={{ fontSize: "25px", color: "#08c" }}
                />
            }

            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    title={t("User_List")}
                    className="userlist_pageHeader"
                    extra={[
                        <>
                            <Search
                                className="custom_users_search"
                                placeholder={t("Search_by_User_name")}
                                enterButton
                                onSearch={(e) => onSearch(e)}
                                allowClear={{
                                    clearIcon: <CloseOutlined onClick={searchClear} />,
                                }}
                            />
                            <div className="exportContentBox">
                                <Button style={{ backgroundColor: "#5f63f2", color: "white" }} className="exportBtn">
                                    {" "}
                                    <FeatherIcon icon="download" size={14} style={{ color: "white" }} /> {t("Export")}
                                </Button>
                                <div className="export-content">
                                    <div className="hoverbtn">
                                        <NavLink
                                            to="#"
                                            trigger="hover"
                                            onClick={() => {
                                                handleExport("pdf")
                                                showModal();
                                                setTypeOfFile("pdf");
                                            }}
                                        >
                                            <div className="export-content-text">
                                                <FeatherIcon size={16} icon="book-open" /> <span>PDF</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <br />
                                    <div className="hoverbtn">
                                        <NavLink
                                            to="#"
                                            onClick={() => {
                                                setTypeOfFile("xlsx");
                                                showModal();
                                            }}
                                        >
                                            <div className="export-content-text">
                                                <FeatherIcon size={16} icon="x" /> <span>Excel (XLSX)</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <br />
                                    <div className="hoverbtn">
                                        <NavLink
                                            to="#"
                                            onClick={() => {
                                                setTypeOfFile("csv");
                                                showModal();
                                            }}
                                        >
                                            <div className="export-content-text">
                                                <FeatherIcon size={16} icon="file" /> <span>CSV</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <Modal title="Request a Report" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                        <div>
                                            <h3>How you would like to get it?</h3>
                                            <Radio.Group onChange={onChange} value={value}>
                                                <Space>
                                                    <Radio value={1} onClick={() => setVisible(false)}>
                                                        <b>Download</b>
                                                    </Radio>
                                                    <Radio value={2} onClick={() => setVisible(true)}>
                                                        <b>Send Via Mail</b>
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                            <br />
                                            <br />
                                            {visible && (
                                                <Form.Item
                                                    className="exportEmailInput"
                                                    name="email"
                                                    label="Email"
                                                    rules={[
                                                        {
                                                            message: "Please enter your email",
                                                        },
                                                        { type: "email", message: "A valid email is required" },
                                                    ]}
                                                >
                                                    <Input placeholder="Report will be send to this email" />
                                                </Form.Item>
                                            )}
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </>,
                    ]}
                />
            </div>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} style={{ overflowX: "auto" }}>
                    <div className="userTabs">
                        <Tabs
                            defaultActiveKey={dynamicStatusTab}
                            onChange={onChangeTab}
                            items={new Array(statusArr.length).fill(statusArr).map((val, index) => {
                                return {
                                    label:
                                        val[index] !== "update"
                                            ? t(`${val[index].charAt(0).toUpperCase()}${val[index].slice(1)}_Users`)
                                            : t(`${val[index].charAt(0).toUpperCase()}${val[index].slice(1)}_User_Details`),

                                    key: val[index],
                                    children: (
                                        <div style={{ margin: "20px" }}>
                                            <Row>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ overflowX: "auto" }}>
                                                    {userStatus !== "update" ? (
                                                        data && data.length > 0 ? (
                                                            <>
                                                                <Table
                                                                    showSorterTooltip={false}
                                                                    columns={columns}
                                                                    dataSource={data}
                                                                    pagination={false}
                                                                    form={form}
                                                                />
                                                                <Row className="pagination_Layout" justify="center">
                                                                    <Col className="usersList_pagination">
                                                                        {paginationCount && paginationCount !== undefined ? (
                                                                            <Pagination
                                                                                total={paginationCount}
                                                                                currentPage={currentPage}
                                                                                onChange={handlePagination}
                                                                                pageSizeOptions={[10, 20, 50, 100]}
                                                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                                                pageSize={dataPerPage}
                                                                                showSizeChanger
                                                                            />
                                                                        ) : (
                                                                            "-"
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ) : (userStatusDataList && userStatusDataList.length == undefined) || [] ? (
                                                            <>
                                                                <Table
                                                                    showSorterTooltip={false}
                                                                    locale={locale}
                                                                    columns={columns}
                                                                    dataSource={data}
                                                                    pagination={false}
                                                                />
                                                            </>
                                                        ) : (
                                                            <Row>
                                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                    <div className="spinnerName">
                                                                        <Spin size="large"></Spin>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    ) : updatedUsersData && updatedUsersData.length > 0 ? (
                                                        <>
                                                            <Table
                                                                showSorterTooltip={false}
                                                                columns={columns}
                                                                dataSource={updatedUsersData}
                                                                pagination={false}
                                                                form={form}
                                                            />
                                                            <Row className="pagination_Layout" justify="center">
                                                                <Col className="usersList_pagination">
                                                                    {updatedUsersPagination && updatedUsersPagination !== undefined ? (
                                                                        <Pagination
                                                                            total={updatedUsersPagination}
                                                                            currentPage={currentPage}
                                                                            onChange={handlePagination}
                                                                            pageSizeOptions={[5, 10, 20, 50, 100]}
                                                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                                            pageSize={dataPerPage}
                                                                            showSizeChanger
                                                                        />
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {" "}

                                                            {(updatedUsersData && updatedUsersData?.length !== undefined) || [] ? (
                                                                <>
                                                                    <Table
                                                                        showSorterTooltip={false}
                                                                        locale={locale}
                                                                        columns={columns}
                                                                        dataSource={updatedUsersData}
                                                                        pagination={false}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <Row>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                        <div className="spinnerName">
                                                                            <Spin size="large"></Spin>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                };
                            })}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default UsersList;
