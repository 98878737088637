const actions = {
    // for admin penal apis
    'GET_USERS_STATUS_LIST': 'GET_USERS_STATUS_LIST',
    'USER_LIST': 'USER_LIST',
    'UPDATE_USERS_DETAIL': 'UPDATE_USERS_DETAIL',
    'GET_USERS_DETAIL': 'GET_USERS_DETAIL',
    'UPDATED_USERS_DETAIL': 'UPDATED_USERS_DETAIL',
    'GET_UPDATE_USER_DETAIL': 'GET_UPDATE_USER_DETAIL',
    'DEACTIVATE_USER' : 'DEACTIVATE_USER',


    // ADMIN PENAL APIS
    getUserStatusList: data => {
        return {
            type: actions.GET_USERS_STATUS_LIST,
            data,
        };
    },

    getUsrList: data => {
        return {
            type: actions.USER_LIST,
            data,
        }
    },
    updateUserDetailList: data => {
        return {
            type: actions.UPDATE_USERS_DETAIL,
            data,
        };
    },

    getUserDetail: data => {
        return {
            type: actions.GET_USERS_DETAIL,
            data,
        };
    },
    getUpdatedUser: data => {
        return {
            type: actions.UPDATED_USERS_DETAIL,
            data,
        };
    },
    getUserUpdateDetailList: data => {
        return {
            type: actions.GET_UPDATE_USER_DETAIL,
            data,
        }
    },
    deactivateUser: data => {
        return {
            type: actions.DEACTIVATE_USER,
            data,
        };
    },

}
export default actions