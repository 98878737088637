import React, { useState } from "react";
import "./style.css";
import { Avatar, Tooltip, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { InfoWraper } from "./auth-info-style";
import { Popover } from "../../popup/popup";
import { logOut } from "../../../redux/authentication/actionCreator";
import Heading from "../../heading/heading";
import LanguageSelection from "./languageSelection/languageSelection";
import { useTranslation } from "react-i18next";
import { getItem } from "../../../utility/localStorageControl";
const AuthInfo = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const adminData = useSelector((state) => state?.UsersStatusReducer?.getUserDetail);
    const admin_Profile = getItem("user_details");

    const SignOut = (e) => {
        // e.preventDefault();
        dispatch(logOut());
        setOpenModal(false);
    };
    const userContent = (
        <div className="user-dropdwon">
            <figure className="user-dropdwon__info" style={{ display: "block", textAlign: "center" }}>
                <div style={{ backgroundColor: "whitesmoke", padding: "10px", borderRadius: "8px" }}>
                    <Avatar size={50} icon={<img src={admin_Profile?.profile_photo} alt="" />} />

                    <figcaption>
                        <Heading as="h6">
                            {adminData?.role === "user" || adminData?.role === undefined ? admin_Profile[i18n.language]?.name : adminData[i18n.language].name}
                        </Heading>
                    </figcaption>
                </div>
            </figure>
            <ul className="user-dropdwon__links">
                <li className="hoverbtn">
                    <Link to="/profile" style={{ marginLeft: "6px" }}>
                        <FeatherIcon icon="user" style={{ marginBottom: "-4px" }} /> {t("Profile")}
                    </Link>
                </li>
            </ul>{" "}
            <br />
            <Button
                onClick={() => {
                    setOpenModal(true);
                }}
                to="#"
                style={{ marginRight: "20px", backgroundColor: "#5f63f2", padding: "10px", width: "100%", height: "100%", color: "white", borderRadius: "5px" }}
            >
                <FeatherIcon icon="log-out" style={{ marginBottom: "-5px", marginRight: "10px" }} />
                {t("Sign_Out")}
            </Button>
        </div>
    );
    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {/* <img src={require("./images.png")} style={{ height: "120px" }} /> */}
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0px 15px",
                        }}
                    >
                        {t('Are_you_sure_you_want_to_signout')}
                    </p>
                    {/* <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem" }}>Do you really want to delete these records? This process cannot be undone.</p>
                    </div> */}
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                color: "white",
                                cursor: " pointer",
                                background: "#6093fa",
                                fontSize: "large",
                                borderRadius: "4px",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                // margin: "0 5px",
                                marginRight: "80px",
                            }}
                            onClick={() => {
                                SignOut();
                            }}
                        >
                            {t('Yes')}
                        </button>
                        <button
                            type="button"
                            style={{
                                fontSize: "large",
                                cursor: " pointer",
                                color: "white",
                                borderRadius: "4px",
                                background: "#6093fa",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            {t('No')}
                        </button>
                    </div>
                </div>
            </Modal>
            <InfoWraper>
                <div className="nav-author comNav">
                    <LanguageSelection />
                    <Tooltip placement="topRight" action="click"></Tooltip>
                    <Popover placement="bottomRight" content={userContent} style={{ margin: "auto" }} >
                        <Link to="#" className="head-example">
                            {adminData?.role === "user" || adminData?.role === undefined ? (
                                <>
                                    <Avatar src={admin_Profile?.profile_photo} />
                                </>
                            ) : (
                                <>
                                    <Avatar src={adminData?.profile_photo} />
                                </>
                            )}
                        </Link>
                    </Popover>
                </div>
            </InfoWraper>
        </>
    );
};

export default AuthInfo;
