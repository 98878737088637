/* eslint-disable no-shadow */
import React, { Component } from "react";
import { Layout, Button, Row, Col } from "antd";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import propTypes from "prop-types";
import MenueItems from "./MenueItems";
import { Div, TopMenuSearch } from "./style";
import AuthInfo from "../components/utilities/auth-info/info";
import { changeRtlMode, changeLayoutMode, changeMenuMode } from "../redux/themeLayout/actionCreator";
import favicon from "../static/img/community-connect.png";
import MobileAuthInfo from "../components/utilities/auth-info/mobileMenuinfo";
import { MoreOutlined } from "@ant-design/icons";

const { Header, Footer, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
    class LayoutComponent extends Component {
        constructor(props) {
            super(props);
            this.state = {
                collapsed: false,
                hide: true,
                searchHide: true,
                activeSearch: false,
            };
            this.updateDimensions = this.updateDimensions.bind(this);
        }

        componentDidMount() {
            // console.log("called === >>>>  : ")
            window.addEventListener("resize", this.updateDimensions);
            this.updateDimensions();
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateDimensions);
        }

        updateDimensions() {
            this.setState({
                collapsed: window.innerWidth <= 1200 && true,
            });
        }

        render() {
            const { collapsed, activeSearch } = this.state;
            const { ChangeLayoutMode, rtl, changeRtl, changeLayout, topMenu, changeMenuMode } = this.props;

            const left = !rtl ? "left" : "right";
            const darkMode = ChangeLayoutMode;
            const toggleCollapsed = () => {
                this.setState({
                    collapsed: !collapsed,
                });
            };

            const toggleCollapsedMobile = () => {
                if (window.innerWidth <= 990) {
                    this.setState({
                        collapsed: !collapsed,
                    });
                }
            };

            const toggleSearch = () => {
                this.setState({
                    activeSearch: !activeSearch,
                });
            };

            const footerStyle = {
                padding: "20px 30px 18px",
                color: "rgba(0, 0, 0, 0.65)",
                fontSize: "14px",
                background: "rgba(255, 255, 255, .90)",
                width: "100%",
                boxShadow: "0 -5px 10px rgba(146,153,184, 0.05)",
            };

            const SideBarStyle = {
                margin: "63px 0 0 0",
                padding: "15px 15px 55px 15px",
                overflowY: "auto",
                height: "100vh",
                position: "fixed",
                [left]: 0,
                zIndex: 998,
            };

            const renderView = ({ style, ...props }) => {
                const customStyle = {
                    marginRight: "auto",
                    [rtl ? "marginLeft" : "marginRight"]: "-17px",
                };
                return <div {...props} style={{ ...style, ...customStyle }} />;
            };

            const renderThumbVertical = ({ style, ...props }) => {
                const { ChangeLayoutMode } = this.props;
                const thumbStyle = {
                    borderRadius: 6,
                    backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
                    [left]: "2px",
                };
                return <div style={{ ...style, ...thumbStyle }} props={props} />;
            };

            const renderTrackVertical = () => {
                const thumbStyle = {
                    position: "absolute",
                    width: "6px",
                    transition: "opacity 200ms ease 0s",
                    opacity: 0,
                    [rtl ? "left" : "right"]: "2px",
                    bottom: "2px",
                    top: "2px",
                    borderRadius: "3px",
                };
                return <div style={thumbStyle} />;
            };

            const renderThumbHorizontal = ({ style, ...props }) => {
                const { ChangeLayoutMode } = this.props;
                const thumbStyle = {
                    borderRadius: 6,
                    backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
                };
                return <div style={{ ...style, ...thumbStyle }} props={props} />;
            };

            const onRtlChange = () => {
                const html = document.querySelector("html");
                html.setAttribute("dir", "rtl");
                changeRtl(true);
            };

            const onLtrChange = () => {
                const html = document.querySelector("html");
                html.setAttribute("dir", "ltr");
                changeRtl(false);
            };

            const modeChangeDark = () => {
                changeLayout(true);
            };

            const modeChangeLight = () => {
                changeLayout(false);
            };

            const modeChangeTopNav = () => {
                changeMenuMode(true);
            };

            const modeChangeSideNav = () => {
                changeMenuMode(false);
            };

            const onEventChange = {
                onRtlChange,
                onLtrChange,
                modeChangeDark,
                modeChangeLight,
                modeChangeTopNav,
                modeChangeSideNav,
            };

            return (
                <Div darkMode={darkMode}>
                    <Layout className="layout">
                        <Header
                            style={{
                                position: "fixed",
                                lineHeight: "20px",
                                width: "100%",
                                top: 0,
                                [!rtl ? "left" : "right"]: 0,
                            }}
                        >
                            <Row>
                                <Col lg={!topMenu ? 6 : 3} sm={12} xs={19} className="align-center-v navbar-brand">
                                    {!topMenu || window.innerWidth <= 991 ? (
                                        <Button type="link" onClick={toggleCollapsed}>
                                            <img src={require(`../static/img/icon/${collapsed ? "right.svg" : "left.svg"}`)} alt="menu" />
                                        </Button>
                                    ) : null}
                                    <Link
                                        style={{ color: "rgb(0, 110, 255)", fontWeight: "bold" }}
                                        className={topMenu && window.innerWidth > 991 ? "striking-logo top-menu" : "striking-logo"}
                                        to="/"
                                    >
                                        {/* Saransh-Community */}
                                        <img src={favicon} alt="logo" style={{ marginRight: "5px", height: "20%", width: "20%" }} />
                                        Community-Connect
                                    </Link>
                                </Col>

                                <Col lg={!topMenu ? 12 : 15} md={8} sm={0} xs={0}></Col>

                                <Col lg={6} md={4} sm={0} xs={0}>
                                    {topMenu && window.innerWidth > 991 ? (
                                        <TopMenuSearch>
                                            <div className="top-right-wrap d-flex">
                                                <Link
                                                    className={`${activeSearch ? "search-toggle active" : "search-toggle"}`}
                                                    onClick={() => {
                                                        toggleSearch();
                                                    }}
                                                    to="#"
                                                >
                                                    <FeatherIcon icon="search" />
                                                    <FeatherIcon icon="x" />
                                                </Link>
                                                <div className={`${activeSearch ? "topMenu-search-form show" : "topMenu-search-form"}`}>
                                                    <form action="">
                                                        <span className="search-icon">
                                                            <FeatherIcon icon="search" />
                                                        </span>
                                                        <input type="text" name="search" />
                                                    </form>
                                                </div>
                                                <AuthInfo />
                                            </div>
                                        </TopMenuSearch>
                                    ) : (
                                        <AuthInfo />
                                    )}
                                </Col>

                                <Col md={0} sm={12} xs={5}>
                                    <>
                                        <div className="mobile-action menuList">
                                            <MoreOutlined className="moreActionMenu" style={{ fontSize: "20px" }} />
                                            <div className="dropdown-content">
                                                <MobileAuthInfo />
                                            </div>
                                        </div>
                                    </>
                                </Col>
                            </Row>
                        </Header>
                        {/* <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                      <HeaderSearch rtl={rtl} />
                    </SmallScreenSearch>
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div> */}
                        <Layout>
                            {!topMenu || window.innerWidth <= 991 ? (
                                <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? "light" : "dark"}>
                                    <Scrollbars
                                        className="custom-scrollbar"
                                        autoHide
                                        autoHideTimeout={500}
                                        autoHideDuration={200}
                                        renderThumbHorizontal={renderThumbHorizontal}
                                        renderThumbVertical={renderThumbVertical}
                                        renderView={renderView}
                                        renderTrackVertical={renderTrackVertical}
                                    >
                                        {/* <p className="sidebar-nav-title"></p> */}
                                        <MenueItems
                                            topMenu={topMenu}
                                            rtl={rtl}
                                            toggleCollapsed={toggleCollapsedMobile}
                                            darkMode={darkMode}
                                            events={onEventChange}
                                        />
                                    </Scrollbars>
                                </Sider>
                            ) : null}
                            <Layout className="atbd-main-layout">
                                <Content>
                                    <WrappedComponent {...this.props} />
                                    <Footer className="admin-footer" style={footerStyle}>
                                        <Row>
                                            <Col md={12} xs={24}>
                                                <span style={{ color: "rgb(0, 110, 255)" }} className="admin-footer__copyright">
                                                    2023 © Sashanik Rao Community
                                                </span>
                                            </Col>
                                        </Row>
                                    </Footer>
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                </Div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            ChangeLayoutMode: state.ChangeLayoutMode.data,
            rtl: state.ChangeLayoutMode.rtlData,
            topMenu: state.ChangeLayoutMode.topMenu,
        };
    };

    const mapStateToDispatch = (dispatch) => {
        return {
            changeRtl: (rtl) => dispatch(changeRtlMode(rtl)),
            changeLayout: (show) => dispatch(changeLayoutMode(show)),
            changeMenuMode: (show) => dispatch(changeMenuMode(show)),
        };
    };

    LayoutComponent.propTypes = {
        ChangeLayoutMode: propTypes.bool,
        rtl: propTypes.bool,
        topMenu: propTypes.bool,
        changeRtl: propTypes.func,
        changeLayout: propTypes.func,
        changeMenuMode: propTypes.func,
    };

    return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;
