const actions = {
    GET_CONTACT: 'GET_CONTACT',
    ADD_CONTACT: 'ADD_CONTACT',

    getContact: data => {
        return {
            type: actions.GET_CONTACT,
            data,
        };
    },
    addContact: data => {
        return {
            type: actions.ADD_CONTACT,
            data,
        };
    },
};
export default actions;