import React from "react";
import useStateRef from "react-usestateref";
import { PageHeader } from "antd";
import { Table, Row, Col, Button, Modal, Form, Tooltip, Pagination, Empty, Spin, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { relationship } from "../../redux/relationshipMaster/actionCreator";
import { relationshipCombination, add_Relationship_Combination, UPDATE_RELATIONSHIP_COMBINATION } from "../../redux/relationshipCombination/actionCreator";
import { Get_Gender_Data } from "../../redux/gender/actionCreator";

const RelationShipCombination = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("Add RelationShip Combination");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const dispatch = useDispatch();

    const relationshipCombinationList = useSelector((state) => state.RelationShipCombinationReducer.relationshipList);
    const getDataRelationship = relationshipCombinationList && relationshipCombinationList?.data?.list;

    const relationshipList = useSelector((state) => state.RelationShipMasterReducer.relationship);
    const getRelationship = relationshipList && relationshipList?.data?.list;

    const getDataGenderList = useSelector((state) => state.GenderReducer.genderData.data);
    const getdataGender = getDataGenderList && getDataGenderList && getDataGenderList.list;
    const RelationShipPagination = relationshipCombinationList && relationshipCombinationList?.data;

    useEffect(
        () => {
            getRelationShipdata(currentPage);
            getRelationShip(currentPage);
            getGenderdata();
        },
        [dataPerPage],
        []
    );

    const getGenderdata = () => {
        dispatch(Get_Gender_Data());
    };

    const getRelationShipdata = (currentPage) => {
        let params = {
            limit: dataPerPage,
            page: currentPage,
        };
        dispatch(relationshipCombination(params));
    };

    const getRelationShip = () => {
        let params = {
            page: "",
            limit: 1000,
        };
        dispatch(relationship(params));
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getRelationShipdata(page);
        setDataPerPage(pageNumber);
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setModalTitle(t("Add_Relationship_Combination"));
    };
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Relationship_Combination"));
        form.setFieldsValue({
            relation_master_id: e.relation_master_id._id,
            relationTypeResultId: e.relationTypeResultId._id,
            gender_id: e.gender_id._id,
        });
    };

    // const deleteRelationShipData = async (value) => {
    //     console.log("value", value);
    //     onDelete(value);
    // };
    // const onDelete = async (values) => {
    //     let a = await dispatch(Delete_Relationship(values));
    //     console.log("values", values);
    //     if (a) {
    //         getRelationShipdata();
    //     }
    // };

    const handleSubmit = async (value) => {
        console.log("value in object", value);
        setIsModalOpen(false);
        form.resetFields();

        if (updateIdRef.current === "") {
            const AddRelationshipData = await dispatch(add_Relationship_Combination(value));
            if (AddRelationshipData) {
                getRelationShipdata();
            }
        } else {
            const updateRelationshipData = await dispatch(UPDATE_RELATIONSHIP_COMBINATION(value, updateIdRef.current));
            if (updateRelationshipData) {
                getRelationShipdata(currentPage, dataPerPage);
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Relation_Request"),
            dataIndex: "name",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.relation_master_id && value.relation_master_id[i18n.language] && value.relation_master_id[i18n.language].name}</>;
            },
        },
        {
            title: t("Gender"),
            dataIndex: "gender",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.gender_id && value.gender_id[i18n.language] && value.gender_id[i18n.language].name}</>;
            },
        },
        {
            title: t("Relation_Result"),
            dataIndex: "name",
            align: "center",
            render: (icon, value) => {
                return (
                    <>{value && value.relationTypeResultId && value.relationTypeResultId[i18n.language] && value.relationTypeResultId[i18n.language].name}</>
                );
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <Tooltip placement="right">
                            <FeatherIcon
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(t);
                                    setIsModalOpen(true);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    // let onChangeGender = (value) => {
    //     console.log("value", value.target.value);
    // };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    title={t("Relationship_Combination")}
                    extra={[
                        <Button key="1" type="primary" onClick={showModal}>
                            <PlusOutlined /> {t("Add_Relationship_Combination")}
                        </Button>,
                    ]}
                />
            </div>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Modal
                        open={isModalOpen}
                        okText={t("Submit")}
                        // onOk={handleOk}
                        cancelText={t("Cancel")}
                        onCancel={handleCancel}
                        onOk={form.submit}
                        title={modalTitle}
                    >
                        <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                            <Form.Item
                                label={t("Relation_Request")}
                                name="relation_master_id"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please_Select_User"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Search and Select User by name"
                                    name="relation_master_id"
                                    showSearch
                                    allowClear
                                    placement="bottomRight"
                                    style={{ width: "100%" }}
                                    notFoundContent="This Relation is Not Exist"
                                    filterOption={(input, option) => {
                                        return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    {getRelationship && getRelationship.length > 0
                                        ? getRelationship.map((data) => {
                                              return (
                                                  <Select.Option key={data._id} value={data._id}>
                                                      {data[i18n.language].name !== "" ? "" : "--"}
                                                      {`${data[i18n.language].name}`}
                                                  </Select.Option>
                                              );
                                          })
                                        : "-"}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={t("Gender")}
                                name="gender_id"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please_Select_Gender"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Search and Select Gender"
                                    name="gender_id"
                                    showSearch
                                    allowClear
                                    placement="bottomRight"
                                    style={{ width: "100%" }}
                                    notFoundContent="This Gender is Not Exist"
                                    filterOption={(input, option) => {
                                        return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    {getdataGender && getdataGender.length > 0
                                        ? getdataGender.map((data) => {
                                              console.log("data in selector>>", data);
                                              return (
                                                  <Select.Option key={data._id} value={data._id}>
                                                      {data[i18n.language].name !== "" ? "" : "--"}
                                                      {`${data[i18n.language].name}`}
                                                  </Select.Option>
                                              );
                                          })
                                        : "-"}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={t("Relation_Result")}
                                name="relationTypeResultId"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please_Select_User"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Search and Select User by name"
                                    name="relationTypeResultId"
                                    showSearch
                                    allowClear
                                    placement="bottomRight"
                                    style={{ width: "100%" }}
                                    notFoundContent="This Relation is Not Exist"
                                    filterOption={(input, option) => {
                                        return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    {getRelationship && getRelationship.length > 0
                                        ? getRelationship.map((data) => {
                                              // console.log("data in selector>>",data);
                                              return (
                                                  <Select.Option key={data._id} value={data._id}>
                                                      {data[i18n.language].name !== "" ? "" : "--"}
                                                      {`${data[i18n.language].name}`}
                                                  </Select.Option>
                                              );
                                          })
                                        : "-"}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Col>
            </Row>

            <Row>
                <Col xs={24} sm={24} md={24}>
                    {getDataRelationship && getDataRelationship.length > 0 ? (
                        <>
                            <div className="master_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    columns={columns}
                                    dataSource={getDataRelationship}
                                    pagination={false}
                                />
                            </div>
                            <Row className="pagination_Layout" justify="center">
                                <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                    <Pagination
                                        total={RelationShipPagination.total_counts}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        pageSize={dataPerPage}
                                        currentPage={currentPage}
                                        pageSizeOptions={[10, 20, 50, 100]}
                                        showSizeChanger
                                        onChange={handlePagination}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : Object.keys(relationshipCombinationList).length === 0 || (getDataRelationship && getDataRelationship.length === undefined) ? (
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="spinnerName">
                                    <Spin size="large"></Spin>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <div className="master_table">
                            <Table
                                scroll={{
                                    x: "3vh",
                                }}
                                locale={locale}
                                columns={columns}
                                dataSource={getDataRelationship}
                                pagination={false}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default RelationShipCombination;
