import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const { getPrefix, addPrefix, updatePrefix, deletePrefix } = actions;

export const prefix = (params) => {
    // console.log('params ',params)

    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";

    Object.keys(params).forEach((a) => {
        queMark += a + equalString + params[a] + ampersand;
    });

    //   console.log('uurrrrrrrrrrlll : ',queMark)
    return async (dispatch) => {
        const getData = await DataService.get(API.prefix.getPrefix + queMark);
        // console.log(" getData prefix;;;: --->:::''''", getData.data.data);
        if (!getData.data.error) {
            dispatch(getPrefix(getData?.data));
            return true;
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};
export const Add_Prefix = (payload) => {
    console.log("payload of prefix : ", payload);
    return async (dispatch) => {
        const resp = await DataService.post(API.prefix.addPrefix, payload);
        console.log("Add_prefix>>>> : ", resp.data.data);
        // console.log("Add_prefix payload:::",payload);
        if (!resp.data.error) {
            return dispatch(addPrefix(resp.data.data));
            // return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Update_Prefix = (payload, id) => {
    return async (dispatch) => {
        console.log("update payload & Id", payload, id);
        const resp = await DataService.put(API.prefix.updatePrefix + id, payload);
        console.log("update_prefix: ", resp.data.data);
        if (!resp.data.error) {
            return dispatch(updatePrefix(resp.data.data));
            // return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Delete_Prefix = (id) => {
    return async (dispatch) => {
        console.log("delete payload & Id", id);
        const resp = await DataService.delete(API.prefix.deletePrefix + id);
        console.log("delete_prefix: ", resp);
        if (!resp.data.error) {
            return dispatch(deletePrefix(true));
        } else {
            message.error(resp.data.data);
            return false;
        }
    };
};
