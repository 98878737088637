import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Modal, Avatar, Input, Select } from "antd";
import useStateRef from "react-usestateref";
import { UserOutlined, EditTwoTone } from "@ant-design/icons";
import { getRandomColor, getDateFormate } from "../../utility/commonFunction";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatusData, putUserDetailsUpdate, get_users_details } from "../../redux/AdminPannel/actionCreator";
import { bloodGroup } from "../../redux/bloodGroup/actionCreator";
import { maritalstatus } from "../../redux/maritalStatus/actionCreator";
import { Get_Gotra_Data } from "../../redux/gotra/actionCreator";
import { getOccupationList } from "../../redux/OccupationMaster/actionCreator";
import { Get_Education_Data } from "../../redux/education/actionCreator";
import { Get_Gender_Data } from "../../redux/gender/actionCreator";
import moment from "moment";
import { global_api_data } from "../../redux/globalAPIFetch/actionCreator";

const { TextArea } = Input;

function VerificationModal(prop) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const myRef = useRef();
    const [left_form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [setSelectedId, selectedIdRef] = useStateRef("");
    const GetDetailByIdApi = useSelector((state) => state.UsersStatusReducer.getUserDetail);
    const getOccupationData = useSelector((state) => state?.OccupationMasterReducer?.OccupationMaster?.data?.list);
    const getBloodGroupData = useSelector((state) => state?.BloodGroupReducer?.bloodGroup?.data?.list);
    const DataOfMaritalStatus = useSelector((state) => state?.MaritalStatusReducer?.maritalStatus?.data?.list);
    const dataGotra = useSelector((state) => state?.GotraReducer?.gotraData?.data?.list);
    const usersRelations = GetDetailByIdApi && GetDetailByIdApi;
    const relationMasterData = usersRelations && usersRelations.userRelationData;
    const getDataGenderList = useSelector((state) => state.GenderReducer.genderData.data);
    const getdataGender = getDataGenderList && getDataGenderList && getDataGenderList.list;
    const getDataEducationList = useSelector((state) => state.EducationReducer.educationData);
    const getdataEducation = getDataEducationList && getDataEducationList?.data?.list;
    const getGlobalData = useSelector((state) => state?.GlobalReducer?.global_api);

    useEffect(() => {
        if (getGlobalData) {
            getGlobalDataApi();
            updateUsersStatusData();
            UserGetDetailsList(prop?.target._id);
            getBloodGroup_data();
            OccupationMasterData();
            getMaritalStatus_data();
            GotraMasterData();
            getGenderdata();
            getEducationData();
        }
    }, []);

    const getGlobalDataApi = async () => {
        await dispatch(global_api_data(!getGlobalData));
    };

    const getGenderdata = async () => {
        await dispatch(Get_Gender_Data());
    };

    const getEducationData = async () => {
        let params = {
            search: "",
            page: "",
            limit: "",
        };
        await dispatch(Get_Education_Data(params));
    };

    const updateUsersStatusData = async () => {
        let params = {
            english: "",
            hindi: "",
            page: prop.currentPage,
            limit: prop.pageLimit,
            status: "pending",
        };
        await dispatch(getUserStatusData(params));
    };

    const UserGetDetailsList = async (id) => {
        await dispatch(get_users_details(prop?.target._id));
    };

    const getBloodGroup_data = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(bloodGroup(params));
    };
    const OccupationMasterData = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(getOccupationList(params));
    };

    const getMaritalStatus_data = () => {
        let params = {
            search: "",
            page: "",
            limit: "",
        };
        dispatch(maritalstatus(params));
    };
    const GotraMasterData = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(Get_Gotra_Data(params));
    };

    const formItemLayout = {
        labelCol: {
            xl: {
                span: 9,
            },
            lg: {
                span: 9,
            },
        },
        wrapperCol: {
            xl: {
                span: 15,
            },
            lg: {
                span: 15,
            },
        },
    };
    // console.log("userRelation langcode",usersRelations && usersRelations.lang_code);
    // console.log("usersRelationnnn", usersRelations && getDateFormate(usersRelations.date_of_birth));

    const showModal = () => {
        setOpen(true);
        setSelectedId(prop && prop.target && prop.target._id !== undefined ? prop.target._id : "");
        // for left side form
        if (prop.target.hn.name !== "") {
            left_form.setFieldsValue({
                profile_photo: usersRelations && usersRelations.profile_photo,
                // value && value.lang_code == "" ? "-" : value && value.lang_code == "en" ? "English" : "Hindi"
                lang_code:
                    usersRelations && usersRelations.lang_code === "" ? "-" : usersRelations && usersRelations.lang_code === "en" ? t("English") : t("Hindi"),
                username: usersRelations && usersRelations.hn && usersRelations.hn.name,
                surname: usersRelations && usersRelations.hn && usersRelations.hn.surname,
                gender: usersRelations && usersRelations.gender_id && usersRelations.gender_id.hn && usersRelations.gender_id.hn.name,
                age: usersRelations && usersRelations.hn && usersRelations.hn.age,
                date_of_birth: usersRelations && getDateFormate(usersRelations.date_of_birth),
                marital_status: usersRelations && usersRelations && usersRelations.marital_status_id?.hn?.marital_status,
                blood_group: usersRelations && usersRelations && usersRelations.blood_group_id?.hn?.blood_group,
                gotra: usersRelations && usersRelations && usersRelations.gotra_master_id?.hn?.name,
                education: usersRelations && usersRelations.education_id && usersRelations.education_id?.hn?.education,
                current_working_at: usersRelations && usersRelations.hn && usersRelations.hn.current_working_at,
                hobbies: usersRelations && usersRelations.hn && usersRelations.hn.hobbies,
                phone_number: usersRelations && usersRelations && usersRelations.phone_number,
                email: usersRelations && usersRelations.email,
                currentAddress: usersRelations && usersRelations.hn && usersRelations.hn.currentAddress,
                currentLocation: usersRelations && usersRelations.hn && usersRelations.hn.currentLocation,
                nativeAddress: usersRelations && usersRelations.hn && usersRelations.hn.nativeAddress,
                nativeLocation: usersRelations && usersRelations.hn && usersRelations.hn.nativeLocation,
                postal_address: usersRelations && usersRelations.hn && usersRelations.hn.postal_address,
                nanihalAddress: usersRelations && usersRelations.hn && usersRelations.hn.nanihalAddress,
                sasuralAddress: usersRelations && usersRelations.hn && usersRelations.hn.sasuralAddress,
                about_me: usersRelations && usersRelations.hn && usersRelations.hn.about_me,
                degree:
                    usersRelations &&
                    usersRelations.education_qualification &&
                    usersRelations.education_qualification.hn &&
                    usersRelations.education_qualification.hn.degree,
                university:
                    usersRelations &&
                    usersRelations.education_qualification &&
                    usersRelations.education_qualification.hn &&
                    usersRelations.education_qualification.hn.schoolName,
            });
        } else {
            // console.log("prop.target.en.name === ''--------->",prop.target.en);
            left_form.setFieldsValue({
                profile_photo: usersRelations && usersRelations.profile_photo,
                lang_code:
                    usersRelations && usersRelations.lang_code === "" ? "-" : usersRelations && usersRelations.lang_code === "en" ? t("English") : t("Hindi"),
                username: usersRelations && usersRelations.en && usersRelations.en.name,
                surname: usersRelations && usersRelations.en && usersRelations.en.surname,
                gender: usersRelations && usersRelations.gender_id && usersRelations.gender_id.en && usersRelations.gender_id.en.name,
                age: usersRelations && usersRelations.en && usersRelations.en.age,
                date_of_birth: usersRelations && getDateFormate(usersRelations.date_of_birth),
                marital_status: `${
                    (usersRelations && usersRelations && usersRelations.marital_status_id?.en?.marital_status !== null) || "" || undefined
                        ? usersRelations &&
                          usersRelations &&
                          usersRelations.marital_status_id?.en?.marital_status &&
                          usersRelations &&
                          usersRelations &&
                          usersRelations.marital_status_id?.en?.marital_status
                        : "-"
                }`,

                blood_group: usersRelations && usersRelations && usersRelations.blood_group_id?.en?.blood_group,
                gotra: usersRelations && usersRelations && usersRelations.gotra_master_id?.en?.name,
                education: usersRelations && usersRelations.education_id && usersRelations.education_id?.en?.education,
                // occupation: usersRelations && usersRelations.en && usersRelations.en.occupation && usersRelations.en.occupation.en && usersRelations.en.occupation.en.occupation,
                current_working_at: usersRelations && usersRelations.en && usersRelations.en.current_working_at,
                hobbies: usersRelations && usersRelations.en && usersRelations.en.hobbies,
                phone_number: usersRelations && usersRelations && usersRelations.phone_number,
                email: usersRelations && usersRelations.email,
                currentAddress: usersRelations && usersRelations.en && usersRelations.en.currentAddress,
                currentLocation: usersRelations && usersRelations.en && usersRelations.en.currentLocation,
                nativeAddress: usersRelations && usersRelations.en && usersRelations.en.nativeAddress,
                nativeLocation: usersRelations && usersRelations.en && usersRelations.en.nativeLocation,
                postal_address: usersRelations && usersRelations.en && usersRelations.en.postal_address,
                nanihalAddress: usersRelations && usersRelations.en && usersRelations.en.nanihalAddress,
                sasuralAddress: usersRelations && usersRelations.en && usersRelations.en.sasuralAddress,
                about_me: usersRelations && usersRelations.en && usersRelations.en.about_me,
                degree: usersRelations && usersRelations.en && usersRelations.en.education_qualification && usersRelations.en.education_qualification.degree,
                university:
                    usersRelations && usersRelations.en && usersRelations.en.education_qualification && usersRelations.en.education_qualification.university,
            });
        }
        // for validation form
        if (prop.target) {
            form.setFieldsValue(prop.target);
        } else {
            form.resetFields();
        }
    };

    // const handleOk = () => {
    //     setTimeout(() => {
    //         setOpen(false);
    //     }, 3000);
    // };

    const handleCancel = () => {
        setOpen(false);
        form.resetFields();
    };

    const handleSubmit = async (val) => {
        delete val.profile_photo;
        const form_data = new FormData();
        for (let key in val) {
            form_data.append(key, val[key]);
        }
        for (const pair of form_data.entries()) {
            console.log("{{{{{-pair-}}}}}}}}} : ", pair);
        }

        var z = Object.keys(val)
            .filter(function(k) {
                return k.indexOf("static_info") === 0;
            })
            .reduce(function(newData, k) {
                newData[k] = val[k];
                return newData;
            }, {});

        // replace keys with only id of object
        let replacedItems = Object.keys(z).map((key) => {
            var newKey = key.slice(12) || key;
            return { [newKey]: z[key] };
        });

        replacedItems.forEach((v, i) => {
            var id = Object.keys(v)[0];
            var value = Object.values(v)[0];
            var RelObj = {
                static_info: {
                    hn: {},
                    en: {},
                },
            };
            const staticRelName = relationMasterData && relationMasterData.find((e) => e._id === id);

            if (prop?.target.hn.name === "") {
                RelObj.static_info.hn.name = value;
                RelObj.static_info.en.name = staticRelName && staticRelName.static_info && staticRelName.static_info.en && staticRelName.static_info.en.name;
            } else {
                RelObj.static_info.en.name = value;
                RelObj.static_info.hn.name = staticRelName && staticRelName.static_info && staticRelName.static_info.hn && staticRelName.static_info.hn.name;
            }
            console.log("RelObj~~>", RelObj);
            // if (selectedIdRef.current !== '') {
            //     const updateUserStaticRelInfo = dispatch(update_User_Relationship(RelObj, id));
            // }
        });

        let obj = {};
        obj.blood_group_id = val.blood_group_id;
        obj.gender_id = val.gender_id;
        obj.gotra_master_id = val.gotra_master_id;
        obj.education_id = val.education_master;
        obj.marital_status_id = val.marital_status_id;
        obj.occupation = val.occupation;
        obj.date_of_birth = moment(val.date_of_birth).format("YYYY-MM-DD");
        obj.status = "verified";
        if (prop?.target.hn.name === "") {
            obj.hn = val;
            delete obj.hn.blood_group_id;
            delete obj.hn.gender_id;
            delete obj.hn.gotra_master_id;
            delete obj.hn.marital_status_id;
            delete obj.hn.occupation;
            delete obj.hn.date_of_birth;
        } else {
            obj.en = val;
            delete obj.en.blood_group_id;
            delete obj.en.gender_id;
            delete obj.en.gotra_master_id;
            delete obj.en.marital_status_id;
            delete obj.en.occupation;
            delete obj.en.date_of_birth;
        }
        // console.log("obj in handle submit", obj);

        setOpen(false);
        form.resetFields();
        // console.log('Obj~~>', obj);
        if (selectedIdRef.current !== "") {
            const updateUserStatusToVerify = await dispatch(putUserDetailsUpdate(obj, selectedIdRef.current));
            // console.log("handleSubmit ~ updateUserStatusToVerify :: ", updateUserStatusToVerify)
            if (updateUserStatusToVerify) {
                updateUsersStatusData();
                setSelectedId("");
            }
        }
    };

    return (
        <div>
            <Tooltip placement="left">
                <EditTwoTone onClick={showModal} style={{ fontSize: "20px" }} />
            </Tooltip>

            <Row gutter={30}>
                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                    <Modal
                        open={open}
                        title={t("Verify_User_To_Confirm_Login")}
                        okText={t("Submit")}
                        onOk={form.submit}
                        cancelText={t("Cancel")}
                        onCancel={handleCancel}
                        width={900}
                    >
                        <Row>
                            <Col span={12} xs={12} sm={12} md={12} lg={12}>
                                <div className="Verification_form_left">
                                    <Form {...formItemLayout} form={left_form}>
                                        <Form.Item label={t("Language")} name="lang_code">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("User_Profile")} valuePropName="fileList" name="profile_photo">
                                            {prop.target.profile_photo !== "" ? (
                                                <img
                                                    src={`${prop.target.profile_photo}`}
                                                    width="60"
                                                    height="60"
                                                    className="addImg"
                                                    style={{ border: "1px solid black" }}
                                                    crossOrigin="anonymous"
                                                    alt=""
                                                />
                                            ) : (
                                                <Avatar icon={<UserOutlined />} shape="square" style={{ backgroundColor: getRandomColor() }} size={64} />
                                            )}
                                        </Form.Item>
                                        <Form.Item label={t("UserName")} name="username">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("Surname")} name="surname">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("Gender")} name="gender">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        {/* <Form.Item label={t('Age')} name='age'>
                                            <Input disabled size='small' />
                                        </Form.Item> */}
                                        <Form.Item label={t("Birth_Date")} name="date_of_birth">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("Marital_Status")} name="marital_status">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("Blood_Group")} name="blood_group">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("Gotras")} name="gotra">
                                            <Input disabled size="small" />
                                        </Form.Item>

                                        {relationMasterData && relationMasterData.length > 0
                                            ? new Array(relationMasterData && relationMasterData.length)
                                                  .fill(relationMasterData && relationMasterData)
                                                  .map((val, index) => {
                                                      const relation = val && val[index];
                                                      // console.log("<<~~~~~` relationMasterData val ~~~~~~~>>", relation && relation.relation_user_id && relation.relation_user_id.en && relation.relation_user_id.en.name)

                                                      if (prop.target.hn.name !== "") {
                                                          if (Object.keys(relation).includes("relation_user_id") === true) {
                                                              return (
                                                                  <Form.Item
                                                                      label={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                          // + " " + "Name"
                                                                      }
                                                                      name={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                      }
                                                                  >
                                                                      <Input
                                                                          disabled
                                                                          size="small"
                                                                          addonBefore={
                                                                              relation &&
                                                                              relation.prefix_id &&
                                                                              relation.prefix_id.hn &&
                                                                              relation.prefix_id.hn.name
                                                                          }
                                                                          defaultValue={
                                                                              (relation &&
                                                                                  relation.relation_user_id &&
                                                                                  relation.relation_user_id.hn &&
                                                                                  relation.relation_user_id.hn.name !== "") ||
                                                                              null
                                                                                  ? relation &&
                                                                                    relation.relation_user_id &&
                                                                                    relation.relation_user_id.hn &&
                                                                                    relation.relation_user_id.hn.name +
                                                                                        " " +
                                                                                        relation.relation_user_id.hn.surname
                                                                                  : "-"
                                                                          }
                                                                      />
                                                                  </Form.Item>
                                                              );
                                                          } else {
                                                              return (
                                                                  <Form.Item
                                                                      label={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                          // + " " + "Name"
                                                                      }
                                                                      name={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                      }
                                                                  >
                                                                      <Input
                                                                          disabled
                                                                          size="small"
                                                                          addonBefore={
                                                                              relation &&
                                                                              relation.prefix_id &&
                                                                              relation.prefix_id.hn &&
                                                                              relation.prefix_id.hn.name
                                                                          }
                                                                          defaultValue={
                                                                              relation && relation.static_info && relation.static_info.hn !== ""
                                                                                  ? relation &&
                                                                                    relation.static_info &&
                                                                                    relation.static_info.hn &&
                                                                                    relation.static_info.hn.name
                                                                                  : "-"
                                                                          }
                                                                      />
                                                                  </Form.Item>
                                                              );
                                                          }
                                                      } else {
                                                          if (Object.keys(relation).includes("relation_user_id") === true) {
                                                              return (
                                                                  <Form.Item
                                                                      label={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                          //  + " " + "Name"
                                                                      }
                                                                      name={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                      }
                                                                  >
                                                                      <Input
                                                                          disabled
                                                                          size="small"
                                                                          addonBefore={
                                                                              relation &&
                                                                              relation.prefix_id &&
                                                                              relation.prefix_id.en &&
                                                                              relation.prefix_id.en.name
                                                                          }
                                                                          defaultValue={
                                                                              (relation &&
                                                                                  relation.relation_user_id &&
                                                                                  relation.relation_user_id.en &&
                                                                                  relation.relation_user_id.en.name !== "") ||
                                                                              null
                                                                                  ? relation &&
                                                                                    relation.relation_user_id &&
                                                                                    relation.relation_user_id.en &&
                                                                                    relation.relation_user_id.en.name +
                                                                                        " " +
                                                                                        relation.relation_user_id.en.surname
                                                                                  : "-"
                                                                          }
                                                                      />
                                                                  </Form.Item>
                                                              );
                                                          } else {
                                                              return (
                                                                  <Form.Item
                                                                      label={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                          // + " " + "Name"
                                                                      }
                                                                      name={
                                                                          relation &&
                                                                          relation.relation_master_id &&
                                                                          relation.relation_master_id[i18n.language] &&
                                                                          relation.relation_master_id[i18n.language].name
                                                                      }
                                                                  >
                                                                      <Input
                                                                          disabled
                                                                          size="small"
                                                                          addonBefore={
                                                                              relation &&
                                                                              relation.prefix_id &&
                                                                              relation.prefix_id.en &&
                                                                              relation.prefix_id.en.name
                                                                          }
                                                                          defaultValue={
                                                                              (relation &&
                                                                                  relation.static_info &&
                                                                                  relation.static_info.en &&
                                                                                  relation.static_info.en.name !== "") ||
                                                                              null
                                                                                  ? relation &&
                                                                                    relation.static_info &&
                                                                                    relation.static_info.en &&
                                                                                    relation.static_info.en.name
                                                                                  : "-"
                                                                          }
                                                                      />
                                                                  </Form.Item>
                                                              );
                                                          }
                                                      }
                                                  })
                                            : " "}

                                        <Form.Item label={t("Occupation")} name="occupation">
                                            {usersRelations?.occupation?.map((value) => {
                                                // console.log("occupationValue", value[i18n.language]?.occupation);
                                                return (
                                                    <Input
                                                        disabled
                                                        size="small"
                                                        style={{ marginBottom: "5px" }}
                                                        defaultValue={value[i18n.language]?.occupation}
                                                    />
                                                );
                                            })}

                                            {/* <Input disabled size='small' /> */}
                                        </Form.Item>
                                        <Form.Item label={t("Working_At")} name="current_working_at">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("Hobbies")} name="hobbies">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        {/* </Form.Item> */}
                                        <Form.Item name="phone_number" label={t("Contact_Number")}>
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item label={t("Email")} name="email">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                        <Form.Item name="currentAddress" label={t("Current_Address")}>
                                            <TextArea rows={1} disabled />
                                        </Form.Item>
                                        <Form.Item name="currentLocation" label={t("Current_location")}>
                                            <TextArea rows={1} disabled />
                                        </Form.Item>
                                        {/* <Form.Item name='nativeAddress' label={t('Native_Address')} >
                                            <TextArea rows={1} disabled />
                                        </Form.Item> */}
                                        <Form.Item name="nativeLocation" label={t("Native_location")}>
                                            <TextArea rows={1} disabled />
                                        </Form.Item>
                                        <Form.Item name="postal_address" label={t("Postal_Address")}>
                                            <TextArea rows={1} disabled />
                                        </Form.Item>
                                        <Form.Item name="nanihalAddress" label={t("nanihal_Address")}>
                                            <TextArea rows={1} disabled />
                                        </Form.Item>
                                        <Form.Item name="sasuralAddress" label={t("sasural_Address")}>
                                            <TextArea rows={1} disabled />
                                        </Form.Item>
                                        <Form.Item label={t("About_Me")} name="about_me">
                                            <TextArea rows={1} disabled />
                                        </Form.Item>
                                        {/* <Form.Item label={t('Education')} name='education' >
                                            {
                                                (educationQualification && educationQualification.length > 0) ?
                                                    (educationQualification.map((val, index) => {
                                                        return (
                                                            <Input.Group layout="vertical" className='qualificationField'>
                                                                <Form.Item label={t('Degree_Name')} name='degree'>
                                                                    <TextArea disabled size='small'
                                                                        defaultValue={val && val[index] && val[index].degree} />
                                                                </Form.Item>
                                                                <Form.Item label={t('College/school_Name')} name='university'>
                                                                    <TextArea disabled size='small'
                                                                        defaultValue={val && val[index] && val[index].university} />
                                                                </Form.Item>
                                                            </Input.Group>
                                                        )
                                                    })
                                                    )
                                                    :
                                                    <Input disabled size='small' defaultValue={"-"} />
                                            }
                                        </Form.Item> */}
                                        <Form.Item label={t("Education")} name="education">
                                            <Input disabled size="small" />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>

                            <Col span={11} xs={11} sm={11} md={11} lg={11}>
                                <div className="Verification_form">
                                    <Form className="Vform" {...formItemLayout} form={form} onFinish={handleSubmit}>
                                        <Form.Item label={t("Language")}>
                                            <Input
                                                size="small"
                                                ref={myRef}
                                                defaultValue={
                                                    usersRelations && usersRelations.lang_code === ""
                                                        ? "-"
                                                        : usersRelations?.lang_code === "en"
                                                        ? t("English")
                                                        : t("Hindi")
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("User_Profile")} valuePropName="fileList" name="profile_photo">
                                            {prop.target.profile_photo !== "" ? (
                                                <img
                                                    src={`${prop.target.profile_photo}`}
                                                    width="60"
                                                    height="60"
                                                    className="addImg"
                                                    style={{ border: "1px solid black" }}
                                                    crossOrigin="anonymous"
                                                    alt=""
                                                />
                                            ) : (
                                                <Avatar icon={<UserOutlined />} shape="square" style={{ backgroundColor: getRandomColor() }} size={64} />
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            label={t("UserName")}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter User Name!",
                                                },
                                                {
                                                    pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                    // pattern: new RegExp(/^(?!\s)[\u0900-\u097Fa-zA-Z\s]+[^\s]$/),
                                                    message: "field only accept string value(should be More than 1 character)!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" ref={myRef} />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("Surname")}
                                            name="surname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter Surname!",
                                                },
                                                {
                                                    pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                    message: "field only accept string value(should be More than 1 character)!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" />
                                        </Form.Item>

                                        {/* <Form.Item label={t('Gender')} name='gender'>
                                            {
                                                (prop.target.en.name === '') ? (
                                                    <Radio.Group>
                                                        <Radio value="Female"> {t('Female')} </Radio>
                                                        <Radio value="Male"> {t('Male')} </Radio>
                                                    </Radio.Group>
                                                ) :
                                                    <Radio.Group>
                                                        <Radio value="महिला"> {t('Female')} </Radio>
                                                        <Radio value="पुस्र्ष"> {t('Male')} </Radio>
                                                    </Radio.Group>
                                            }
                                        </Form.Item> */}

                                        <Form.Item label={t("Gender")} name="gender_id">
                                            <Select>
                                                {new Array(getdataGender && getdataGender.length).fill(getdataGender && getdataGender).map((val, index) => {
                                                    // console.log('gender in verification-->', val)

                                                    if (prop.target.hn.name === "") {
                                                        return prop.target.en._id !== "" || null || undefined ? (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {val && val[index] && val[index].hn && val[index].hn.name}
                                                            </Select.Option>
                                                        ) : (
                                                            "-"
                                                        );
                                                    } else {
                                                        return prop.target.hn._id !== "" || null || undefined ? (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {val && val[index] && val[index].en && val[index].en.name}
                                                            </Select.Option>
                                                        ) : (
                                                            "-"
                                                        );
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label={t("Birth_Date")}
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^[0-9]+$/),
                                                    //     message: "field only accept numbers (No space allowed!!*)",
                                                    // },
                                                ]
                                            }
                                        >
                                            <Input size="small" defaultValue={usersRelations && getDateFormate(usersRelations.date_of_birth)} />
                                        </Form.Item>

                                        <Form.Item label={t("Marital_Status")} name="marital_status_id">
                                            <Select>
                                                {new Array(DataOfMaritalStatus && DataOfMaritalStatus.length)
                                                    .fill(DataOfMaritalStatus && DataOfMaritalStatus)
                                                    .map((val, index) => {
                                                        // console.log('Marital Status options:-->',val && val[index] && val[index].hn && val[index].hn.marital_status)

                                                        if (prop.target.hn.name === "") {
                                                            return prop.target.en.marital_status_id !== "" || null || undefined ? (
                                                                <Select.Option value={val && val[index] && val[index]._id}>
                                                                    {val && val[index] && val[index].hn && val[index].hn.marital_status}
                                                                </Select.Option>
                                                            ) : (
                                                                "-"
                                                            );
                                                        } else {
                                                            return prop.target.hn.marital_status_id !== "" || null || undefined ? (
                                                                <Select.Option value={val && val[index] && val[index]._id}>
                                                                    {val && val[index] && val[index].en && val[index].en.marital_status}
                                                                </Select.Option>
                                                            ) : (
                                                                "-"
                                                            );
                                                        }
                                                    })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label={t("Blood_Group")} name="blood_group_id">
                                            <Select
                                                name="_id"
                                                mode="single"
                                                placeholder="Select your gotra"
                                                showSearch
                                                allowClear
                                                placement="bottomRight"
                                                style={{ width: "100%" }}
                                                notFoundContent="This Gotra is Not Exist"
                                                optionFilterProp="children"
                                            >
                                                {new Array(getBloodGroupData && getBloodGroupData.length)
                                                    .fill(getBloodGroupData && getBloodGroupData)
                                                    .map((val, index) => {
                                                        if (prop.target.hn.name === "") {
                                                            return (
                                                                <Select.Option value={val && val[index] && val[index]._id}>
                                                                    {val && val[index] && val[index].hn && val[index].hn.blood_group}
                                                                </Select.Option>
                                                            );
                                                        } else {
                                                            return (
                                                                <Select.Option value={val && val[index] && val[index]._id}>
                                                                    {val && val[index] && val[index].en && val[index].en.blood_group}
                                                                </Select.Option>
                                                            );
                                                        }
                                                    })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label={t("Gotras")} name="gotra_master_id">
                                            <Select
                                                mode="single"
                                                placeholder="Select your gotra"
                                                showSearch
                                                allowClear
                                                placement="bottomRight"
                                                style={{ width: "100%" }}
                                                notFoundContent="This Gotra is Not Exist"
                                                optionFilterProp="children"
                                            >
                                                {new Array(dataGotra && dataGotra.length).fill(dataGotra && dataGotra).map((val, index) => {
                                                    if (prop.target.hn.name === "") {
                                                        return (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {(val && val[index] && val[index].hn && val[index].hn.name) === "" || undefined
                                                                    ? ""
                                                                    : val && val[index] && val[index].hn && val[index].hn.name}
                                                            </Select.Option>
                                                        );
                                                    } else {
                                                        return (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {val && val[index] && val[index].en && val[index].en.name}
                                                            </Select.Option>
                                                        );
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>

                                        {relationMasterData && relationMasterData.length > 0
                                            ? new Array(relationMasterData && relationMasterData.length).fill(relationMasterData).map((val, index) => {
                                                  const relation = val && val[index];

                                                  if (prop.target.hn.name !== "") {
                                                      if ("relation_user_id" in relation) {
                                                          return (
                                                              <Form.Item
                                                                  label={
                                                                      relation &&
                                                                      relation.relation_master_id &&
                                                                      relation.relation_master_id[i18n.language] &&
                                                                      relation.relation_master_id[i18n.language].name
                                                                      // + " " + "Name"
                                                                  }
                                                                  name={
                                                                      relation &&
                                                                      relation.relation_master_id &&
                                                                      relation.relation_master_id.en &&
                                                                      relation.relation_master_id.en.name
                                                                  }
                                                              >
                                                                  <Input
                                                                      size="small"
                                                                      disabled
                                                                      addonBefore={
                                                                          relation && relation.prefix_id && relation.prefix_id.en && relation.prefix_id.en.name
                                                                      }
                                                                      defaultValue={
                                                                          (relation &&
                                                                              relation.relation_user_id &&
                                                                              relation.relation_user_id.en &&
                                                                              relation.relation_user_id.en.name !== "") ||
                                                                          null
                                                                              ? relation &&
                                                                                relation.relation_user_id &&
                                                                                relation.relation_user_id.en &&
                                                                                relation.relation_user_id.en.name + " " + relation.relation_user_id.en.surname
                                                                              : "-"
                                                                      }
                                                                  />
                                                              </Form.Item>
                                                          );
                                                      } else {
                                                          return (
                                                              <Form.Item
                                                                  label={
                                                                      relation &&
                                                                      relation.relation_master_id &&
                                                                      relation.relation_master_id[i18n.language] &&
                                                                      relation.relation_master_id[i18n.language].name
                                                                      // + " " + "Name"
                                                                  }
                                                                  //   name={"static_info_" + `${relation && relation._id}`}
                                                                  name={`static_info_${relation && relation._id}`}
                                                                  rules={[
                                                                      {
                                                                          pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                                          message: "field only accept string value(should be More than 1 character)!",
                                                                      },
                                                                  ]}
                                                              >
                                                                  <Input
                                                                      size="small"
                                                                      addonBefore={
                                                                          relation && relation.prefix_id && relation.prefix_id.en && relation.prefix_id.en.name
                                                                      }
                                                                  />
                                                              </Form.Item>
                                                          );
                                                      }
                                                  } else {
                                                      if ("relation_user_id" in relation) {
                                                          return (
                                                              <Form.Item
                                                                  label={
                                                                      relation &&
                                                                      relation.relation_master_id &&
                                                                      relation.relation_master_id[i18n.language] &&
                                                                      relation.relation_master_id[i18n.language].name
                                                                      // + " " + "Name"
                                                                  }
                                                                  name={
                                                                      relation &&
                                                                      relation.relation_master_id &&
                                                                      relation.relation_master_id.en &&
                                                                      relation.relation_master_id.en.name
                                                                  }
                                                              >
                                                                  <Input
                                                                      size="small"
                                                                      disabled
                                                                      addonBefore={
                                                                          relation && relation.prefix_id && relation.prefix_id.hn && relation.prefix_id.hn.name
                                                                      }
                                                                      defaultValue={
                                                                          (relation &&
                                                                              relation.relation_user_id &&
                                                                              relation.relation_user_id.hn &&
                                                                              relation.relation_user_id.hn.name !== "") ||
                                                                          null
                                                                              ? relation &&
                                                                                relation.relation_user_id &&
                                                                                relation.relation_user_id.hn &&
                                                                                relation.relation_user_id.hn.name + " " + relation.relation_user_id.hn.surname
                                                                              : "-"
                                                                      }
                                                                  />
                                                              </Form.Item>
                                                          );
                                                      } else {
                                                          return (
                                                              <Form.Item
                                                                  label={
                                                                      relation &&
                                                                      relation.relation_master_id &&
                                                                      relation.relation_master_id[i18n.language] &&
                                                                      relation.relation_master_id[i18n.language].name
                                                                  }
                                                                  //   name={"static_info_" + `${relation && relation._id}`}
                                                                  name={`static_info_${relation && relation._id}`}
                                                                  rules={[
                                                                      {
                                                                          pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                                          message: "field only accept string value(should be More than 1 character)!",
                                                                      },
                                                                  ]}
                                                              >
                                                                  <Input
                                                                      size="small"
                                                                      addonBefore={
                                                                          relation && relation.prefix_id && relation.prefix_id.hn && relation.prefix_id.hn.name
                                                                      }
                                                                  />
                                                              </Form.Item>
                                                          );
                                                      }
                                                  }
                                              })
                                            : " "}

                                        <Form.Item label={t("Occupation")} name="occupation">
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Your Occupation"
                                                name="_id"
                                                showSearch
                                                allowClear
                                                placement="bottomRight"
                                                style={{ width: "100%" }}
                                                notFoundContent="This Occupation is Not Exist"
                                                optionFilterProp="children"
                                            >
                                                {getOccupationData && getOccupationData.length > 0
                                                    ? getOccupationData.map((data) => {
                                                          if (prop.target.hn.name === "") {
                                                              return (
                                                                  <Select.Option key={data._id} value={data._id}>
                                                                      {(data && data.hn && data.hn.occupation !== "") || undefined || null
                                                                          ? data && data.hn && data.hn.occupation
                                                                          : data && data.en && data.en.occupation}
                                                                  </Select.Option>
                                                              );
                                                          } else {
                                                              return (
                                                                  <Select.Option key={data._id} value={data._id}>
                                                                      {(data && data.en && data.en.occupation !== "") || undefined || null
                                                                          ? data && data.en && data.en.occupation
                                                                          : data && data.hn && data.hn.occupation}
                                                                  </Select.Option>
                                                              );
                                                          }
                                                      })
                                                    : "-"}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label={t("Working_At")}
                                            name="current_working_at"
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "No space allowed at beginning and end!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("Hobbies")}
                                            name="hobbies"
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "No space allowed at beginning and end!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="phone_number"
                                            label={t("Contact_Number")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter contact number*!",
                                                },
                                                {
                                                    pattern: new RegExp(/^[0-9]+$/),
                                                    message: "field only accept numbers (No space allowed!!*)",
                                                },
                                                {
                                                    max: 11,
                                                    message: "Maximum 10 Numbers are allowed!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("Email")}
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter Email ID*!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="currentAddress"
                                            label={t("Current_Address")}
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "Please enter valid Address(No space allowed at beginning and end)!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="currentLocation"
                                            label={t("Current_location")}
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        {/* <Form.Item name='nativeAddress' label={t('Native_Address')} rules={[
                                            // {
                                            //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                            //     message: "Please enter valid Address(No space allowed at beginning and end)!!*",
                                            // },
                                        ]}
                                        >
                                            <TextArea rows={1} size='small' />
                                        </Form.Item> */}
                                        <Form.Item
                                            name="nativeLocation"
                                            label={t("Native_location")}
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            name="postal_address"
                                            label={t("Postal_Address")}
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            name="nanihalAddress"
                                            label={t("nanihal_Address")}
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            name="sasuralAddress"
                                            label={t("sasural_Address")}
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                    //     message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("About_Me")}
                                            name="about_me"
                                            rules={
                                                [
                                                    // {
                                                    //     pattern: new RegExp(/^\S(?:.*\S)?$/),
                                                    //     message: "No space allowed at beginning and end!!*",
                                                    // },
                                                ]
                                            }
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        {/* <Form.Item label={t("Education")}>
                                            <Input disabled bordered={false} size='small' />
                                            <Form.List
                                                name="education_qualification"
                                            >
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }) => (
                                                            <>
                                                                <div className='qualificationField'>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'degree']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing Degree name',
                                                                            },
                                                                            {
                                                                                pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                                                message: "No space allowed at beginning and end!!*",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input size='small' placeholder="Enter Degree Name" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'university']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'School/College Name',
                                                                            },
                                                                            {
                                                                                pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                                                message: "No space allowed at beginning and end!!*",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input size='small' placeholder="Enter School/College Name" />
                                                                    </Form.Item>
                                                                </div>
                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                            </>
                                                        ))}
                                                        <Form.Item>
                                                            <Button className='qualificationBtn' type="dashed"
                                                                onClick={() => add()} icon={<PlusOutlined />}>
                                                                {t('Add_Qualification')}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}

                                            </Form.List>
                                        </Form.Item> */}

                                        <Form.Item label={t("Education")} name="education_master">
                                            <Select
                                                mode="single"
                                                placeholder="Select your education"
                                                showSearch
                                                allowClear
                                                placement="bottomRight"
                                                style={{ width: "100%" }}
                                                notFoundContent="This education is Not Exist"
                                                optionFilterProp="children"
                                            >
                                                {new Array(getdataEducation && getdataEducation.length)
                                                    .fill(getdataEducation && getdataEducation)
                                                    .map((val, index) => {
                                                        if (prop.target.hn.name === "") {
                                                            return (
                                                                <Select.Option value={val && val[index] && val[index]._id}>
                                                                    {(val && val[index] && val[index].hn && val[index].hn.education) === "" || undefined
                                                                        ? ""
                                                                        : val && val[index] && val[index].hn && val[index].hn.education}
                                                                </Select.Option>
                                                            );
                                                        } else {
                                                            return (
                                                                <Select.Option value={val && val[index] && val[index]._id}>
                                                                    {val && val[index] && val[index].en && val[index].en.education}
                                                                </Select.Option>
                                                            );
                                                        }
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal>
                </Col>
            </Row>
        </div>
    );
}

export default VerificationModal;
