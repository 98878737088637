const actions = {
    GET_GENDER: 'GET_GENDER',
    ADD_GENDER: 'ADD_GENDER',
    UPDATE_GENDER: 'UPDATE_GENDER',
    DELETE_GENDER: 'DELETE_GENDER',


    getGender: data => {
        return {
            type: actions.GET_GENDER,
            data,
        };
    },
    addGender: data => {
        return {
            type: actions.ADD_GENDER,
            data,
        };
    },
    updateGender: data => {
        return {
            type: actions.UPDATE_GENDER,
            data,
        };
    },
    deleteGender: data => {
        return {
            type: actions.DELETE_GENDER,
            data,
        };
    },
};

export default actions;