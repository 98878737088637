import axios from "axios";
import { getItem } from "../../utility/localStorageControl";
import { message } from "antd";
// const useTranslation = require( "react-i18next");

// const API_ENDPOINT = `http://172.105.41.30:4100/api/`;
// const API_ENDPOINT = `http://192.168.1.57:4100/api/`; // local

// const API_ENDPOINT = `http://172.232.75.138:4100/api/`;   //staging new

const API_ENDPOINT = `https://api.communityconnect.org.in/api/`; //production

// const API_ENDPOINT = `http://dev.devstree.com:4100/api/`; //staging

const authHeader = () => ({
    Authorization: `Bearer ${getItem("access_token")}`,
    language_code: `${getItem("apiLanguageSelection")}`,
});

const client = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
        Authorization: `Bearer ${getItem("access_token")}`,
        // Accept: 'application/json',
        "Content-Type": "application/json",
        language_code: `${getItem("apiLanguageSelection")}`,
        // 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
        // "Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8',
    },
});

class DataService {
    static get(path = "") {
        return client({
            method: "GET",
            url: path,
            headers: { ...authHeader() },
        });
    }

    static post(path = "", data = {}, optionalHeader = {}) {
        return client({
            method: "POST",
            url: path,
            data,
            headers: { ...authHeader(), ...optionalHeader },
        });
    }

    static patch(path = "", data = {}) {
        return client({
            method: "PATCH",
            url: path,
            data,
            headers: { ...authHeader() },
        });
    }

    static put(path = "", data = {}) {
        return client({
            method: "PUT",
            url: path,
            data,
            headers: { ...authHeader() },
        });
    }
    static delete(path = "") {
        return client({
            method: "DELETE",
            url: path,
            headers: { ...authHeader() },
        });
    }

    //for delete multiple notification
    static deleteMany(path = "", data = {}) {
        return client({
            method: "DELETE",
            url: path,
            data,
            headers: { ...authHeader() },
        });
    }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;
    requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem("access_token")}` };

    return requestConfig;
});

// client.interceptors.response.use(
//   (response) => {
// console.log("response.status",response.status);
// console.log("response.status",response);
// console.log("response.status:::",response?.data?. message);

//     if (response) {
//       if (response.status === 200) {

//       } else {
//         return message.success(response?.data?.message);
//       }
//     }
//     return Promise.resolve(response);
//   },
//   (error) => {
//     /**
//       Do something in case the response returns an error code [3*, 4**, 5**] etc
//      * For example, on token expiration retrieve a new access token, retry a failed request etc
//      */
//     const { response } = error;
//     const originalRequest = error.config;
//     if (response) {
//       if (response.status === 500) {
//         message.error(response?.data?.message);
//       } else if (response.status === 400) {
//         message.error(response?.data?.message);
//       } else if (response.status === 403) {
//         message.error(response?.data?.message);
//       } else if (response.status === 404) {
//         message.error(response?.data?.message);
//       } else {
//         return originalRequest;
//       }
//     }
//     return Promise.reject(error);
//   }
// );

client.interceptors.response.use(
    (response) => {
        // console.log("response>>>>>",response);
        if (response) {
            if (response.status === 200) {
            } else if (response.status === 202) {
                message.success(response?.data?.message);
            } else if (response.status === 201) {
                if (response?.data?.message !== "RelationShip Updated Successfully") {
                    message.success(response?.data?.message);
                }
            } else {
                return response;
            }
        }
        return Promise.resolve(response);
    },
    (error) => {
        /**
      Do something in case the response returns an error code [3*, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
        const { response } = error;
        // console.log("response",response);
        if (response) {
            if (response.status === 500) {
                // do something here
            } else if (response.status === 400 && response.data.data == []) {
                message.error(response?.data?.message);
            } else if (response.status === 403) {
                message.error(response?.data?.message);
            } else if (response.status === 404) {
                message.error(response?.data?.message);
                // console.log('error data ::', response?.data?.data)
                // return response?.data?.data
            } else {
                return response;
            }
        }
        return Promise.reject(error);
    }
);

export { DataService };
