import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const { getOccupation, addOccupation, editOccupation, deleteOccupation } = actions;

export const getOccupationList = (params) => {
    let queMark = "?";
    let equalString = "=";
    Object.keys(params).forEach((a) => {
        queMark += a + equalString + params[a] + "&";
    });
    return async (dispatch) => {
        const getOccupationResp = await DataService.get(API.occupation.GetOccupation + queMark);
        if (!getOccupationResp.data.error) {
            dispatch(getOccupation(getOccupationResp?.data));
            return true;
        } else {
            message.error(getOccupationResp.data.message);
            return false;
        }
    };
};
export const addNewOccupation = (payload) => {
    return async (dispatch) => {
        const addOccupationResp = await DataService.post(API.occupation.AddOccupation, payload);
        if (!addOccupationResp.data.error) {
            return dispatch(addOccupation(addOccupationResp.data.data));
            // return true;
        } else {
            message.error(addOccupationResp.data.message);
            return false;
        }
    };
};

export const updateOccupationData = (payload, id) => {
    return async (dispatch) => {
        const updateOccupationResp = await DataService.put(API.occupation.UpdateOccupation + id, payload);
        if (!updateOccupationResp.data.error) {
            dispatch(editOccupation(updateOccupationResp.data.data));
            return true;
        } else {
            message.error(updateOccupationResp.data.message);
            return false;
        }
    };
};

export const deleteOccupationData = (id) => {
    return async (dispatch) => {
        const deleteOccupationResp = await DataService.delete(API.occupation.DeleteOccupation + id);
        if (!deleteOccupationResp.data.error) {
            return dispatch(deleteOccupation(true));
        } else {
            message.error(deleteOccupationResp.data.data);
            return false;
        }
    };
};
