const actions = {
    GET_DASHBOARD: 'GET_DASHBOARD',
    GET_USER : 'GET_USER',
    GET_GRAPH : 'GET_GRAPH',
    
    getDashboard: data => {
        return {
            type: actions.GET_DASHBOARD,
            data,
        };
    },
    getDashboardUser: data => {
        return {
            type: actions.GET_USER,
            data,
        };
    },
    getGraphUser: data => {
        return {
            type: actions.GET_GRAPH,
            data,
        };
    },
};

export default actions;