const actions = {
    GET_BROADCAST_LIST: "GET_BROADCAST_LIST",
    GET_BROADCAST_DETAIL: "GET_BROADCAST_DETAIL",

    getBroadcastList: (data) => {
        return {
            type: actions.GET_BROADCAST_LIST,
            data,
        };
    },
    getBroadcastDetail: (data) => {
        return {
            type: actions.GET_BROADCAST_DETAIL,
            data,
        };
    },
};

export default actions;
