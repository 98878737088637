const actions = {
    GET_EVENT: 'GET_EVENT',
    ADD_EVENT: 'ADD_EVENT',
    UPDATE_EVENT: 'UPDATE_EVENT',
    DELETE_EVENT: 'DELETE_EVENT',


    getEvent: data => {
        return {
            type: actions.GET_EVENT,
            data,
        };
    },
    addEvent: data => {
        return {
            type: actions.ADD_EVENT,
            data,
        };
    },
    updateEvent: data => {
        return {
            type: actions.UPDATE_EVENT,
            data,
        };
    },
    deleteEvent: data => {
        return {
            type: actions.DELETE_EVENT,
            data,
        };
    },
};

export default actions;