import actions from "./actions";
const { GET_BROADCAST_LIST, GET_BROADCAST_DETAIL } = actions;

const initState = {
    broadcastData: {},
    detailData: {},
};
const BroadcastReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_BROADCAST_LIST:
            return {
                ...state,
                broadcastData: data,
            };
        case GET_BROADCAST_DETAIL:
            return {
                ...state,
                detailData: data,
            };
        default:
            return state;
    }
};
export default BroadcastReducer;
