import { combineReducers } from "redux";
import authReducer from "./authentication/reducers";
import ChangeLayoutMode from "./themeLayout/reducers";
import MaritalStatusReducer from "./maritalStatus/reducers";
import UserRelationShipReducer from "./relationship/reducers";
import BloodGroupReducer from "./bloodGroup/reducers";
import RelationShipMasterReducer from "./relationshipMaster/reducers";
import RelationShipCombinationReducer from "./relationshipCombination/reducers";
import PrefixReducer from "./prefix/reducers";
import UsersStatusReducer from "./AdminPannel/reducers";
import AdvertisementReducer from "./advertisementList/reducers";
import ContactReducer from "./Contact/reducers";
import FAQReducer from "./FaqMaster/reducers";
import GotraReducer from "./gotra/reducers";
import EducationReducer from "./education/reducers";
import GenderReducer from "./gender/reducers";
import OccupationMasterReducer from "./OccupationMaster/reducers";
import NotificationReducer from "./Notifications/reducers";
import EventReducer from "./event/reducers";
import DashboardReducer from "./Dashboard/reducers";
import GalleryReducer from "./gallery/reducers";
import SubscriptionReducer from "./subscription/reducers";
import BroadcastReducer from "./Broadcast/reducers";
import { GlobalReducer } from "./globalAPIFetch/reducer";

const rootReducers = combineReducers({
    auth: authReducer,
    ChangeLayoutMode,
    MaritalStatusReducer,
    BloodGroupReducer,
    RelationShipMasterReducer,
    PrefixReducer,
    UsersStatusReducer,
    AdvertisementReducer,
    UserRelationShipReducer,
    ContactReducer,
    GotraReducer,
    GenderReducer,
    EducationReducer,
    FAQReducer,
    OccupationMasterReducer,
    NotificationReducer,
    EventReducer,
    RelationShipCombinationReducer,
    DashboardReducer,
    GalleryReducer,
    SubscriptionReducer,
    BroadcastReducer,
    GlobalReducer,
});

export default rootReducers;
