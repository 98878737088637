const actions = {
    GET_GOTRA: 'GET_GOTRA',
    ADD_GOTRA: 'ADD_GOTRA',
    UPDATE_GOTRA: 'UPDATE_GOTRA',
    DELETE_GOTRA: 'DELETE_GOTRA',


    getGotra: data => {
        return {
            type: actions.GET_GOTRA,
            data,
        };
    },
    addGotra: data => {
        return {
            type: actions.ADD_GOTRA,
            data,
        };
    },
    updateGotra: data => {
        return {
            type: actions.UPDATE_GOTRA,
            data,
        };
    },
    deleteGotra: data => {
        return {
            type: actions.DELETE_GOTRA,
            data,
        };
    },
};

export default actions;