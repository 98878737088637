const actions = {
    GET_USERSLIST: 'GET_USERSLIST',
    ADD_USERSLIST: 'ADD_USERSLIST',
    UPDATE_USERSLIST: 'UPDATE_USERSLIST',
    DELETE_USERSLIST: 'DELETE_USERSLIST',


    getUsersList: data => {
        return {
            type: actions.GET_USERSLIST,
            data,
        };
    },
    addUsersList: data => {
        return {
            type: actions.ADD_USERSLIST,
            data,
        };
    },
    updateUsersList: data => {
        return {
            type: actions.UPDATE_USERSLIST,
            data,
        };
    },
    deleteUsersList: data => {
        return {
            type: actions.DELETE_USERSLIST,
            data,
        };
    },
};

export default actions;