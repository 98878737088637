import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";
import withAdminLayout from "../../layout/withAdminLayout";
import UsersList from "../../container/UsersList/UsersList";
import Gotras from "../../container/MasterPages/Gotras";
import BloodGroups from "../../container/MasterPages/BloodGroups";
import MaritalStatus from "../../container/MasterPages/MaritalStatus";
import RelationShip from "../../container/MasterPages/RelationShip";
import RelationshipCombination from "../../container/MasterPages/RelationshipCombination";
import Prefix from "../../container/MasterPages/Prefix";
import Gender from "../../container/MasterPages/Gender";
import Education from "../../container/MasterPages/education";
import ContactMe from "../../container/MasterPages/ContactMe";
import event from "../../container/Event/event";
import NotificationList from "../../container/NotificationList/NotificationList";
import UserDetail from "../../container/UsersList/UserDetail";
import AdvertisementList from "../../container/AdvertisementList/AdvertisementList";
import ViewAdvertisement from "../../container/AdvertisementList/ViewAdvertisement";
import GeneralAdvertisement from "../../container/GeneralAdvertisement/GeneralAdvertisement";
import ViewGeneralAdvertisement from "../../container/GeneralAdvertisement/ViewGeneralAdvertisement";
import { checkNotFound } from "../../utility/commonFunction";
import NotFound from "../../container/pages/404";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import FAQModule from "../../container/FAQ/FAQ";
import OccupationMaster from "../../container/OccupationMaster/OccupationMaster";
import profile from "../../components/utilities/auth-info/profile";
import Gallery from "../../container/Gallery/Gallery";
import ViewGallery from "../../container/Gallery/ViewGallery";
import Subscription from "../../container/subscription/Subscription";
import ViewSubscriptionDetails from "../../container/subscription/ViewSubscriptionDetails";
import Broadcast from "../../container/Broadcast/Broadcast";
import ViewBroadcastDetail from "../../container/Broadcast/ViewBroadcastDetail";

const Admin = () => {
    const { path } = useRouteMatch();
    const { i18n } = useTranslation();

    let history = useHistory();
    history.location.key = i18n.language;
    //  console.log("history history.location.key.",history.location.key);

    return (
        <Switch>
            <Suspense
                fallback={
                    <div className="spin">
                        <Spin />
                    </div>
                }
            >
                <Route exact path={path} component={Dashboard} />
                <Route exact path={`${path}users_list`} component={UsersList} />
                <Route exact path={`${path}users_list/user_detail`} component={UserDetail} />
                <Route exact path={`${path}blood_groups`} component={BloodGroups} />
                <Route exact path={`${path}marital_status`} component={MaritalStatus} />
                <Route exact path={`${path}contact_us`} component={ContactMe} />
                <Route exact path={`${path}event`} component={event} />
                <Route exact path={`${path}notification_list`} component={NotificationList} />
                <Route exact path={`${path}notification_list/user_detail`} component={UserDetail} />
                <Route exact path={`${path}advertisement_list`} component={AdvertisementList} />
                <Route exact path={`${path}advertisement_list/view_advertisement`} component={ViewAdvertisement} />

                <Route exact path={`${path}advertisement_list/view_advertisement/user_detail`} component={UserDetail} />
                <Route exact path={`${path}general_advertisement_list`} component={GeneralAdvertisement} />
                <Route exact path={`${path}general_advertisement_list/view_general_advertisement`} component={ViewGeneralAdvertisement} />
                <Route exact path={`${path}general_advertisement_list/view_general_advertisement/user_detail`} component={UserDetail} />
                <Route exact path={`${path}gotras`} component={Gotras} />
                <Route exact path={`${path}relationship`} component={RelationShip} />
                <Route exact path={`${path}relationship_combination`} component={RelationshipCombination} />
                <Route exact path={`${path}prefix`} component={Prefix} />
                <Route exact path={`${path}gender`} component={Gender} />
                <Route exact path={`${path}education`} component={Education} />
                <Route exact path={`${path}faq_list`} component={FAQModule} />
                <Route exact path={`${path}gallery`} component={Gallery} />
                <Route exact path={`${path}subscription`} component={Subscription} />
                <Route exact path={`${path}broadcast`} component={Broadcast} />
                <Route exact path={`${path}broadcast/view_broadcastDetails`} component={ViewBroadcastDetail} />
                <Route exact path={`${path}gallery/view_gallery`} component={ViewGallery} />
                <Route exact path={`${path}subscription/view_subscriptionDetails`} component={ViewSubscriptionDetails} />
                <Route exact path={`${path}occupation_master`} component={OccupationMaster} />
                <Route exact path={`${path}profile`} component={profile} />

                {checkNotFound(history.location.pathname) ? "" : <Route component={NotFound} />}
            </Suspense>
        </Switch>
    );
};

export default withAdminLayout(Admin);
