const actions = {
    GLOBAL_API: 'GLOBAL_API',

  
    global_api: () => {
      return {
        type: actions.GLOBAL_API,
      };
    },
  
  };
  
  export default actions;
  