const actions = {
    GET_FAQ_LIST: 'GET_FAQ_LIST',
    ADD_FAQ: 'ADD_FAQ',
    EDIT_FAQ: 'EDIT_FAQ',
    DELETE_FAQ: 'DELETE_FAQ',

    getFaq: data => {
        return {
            type: actions.GET_FAQ_LIST,
            data,
        };
    },
    addFaq: data => {
        return {
            type: actions.ADD_FAQ,
            data,
        };
    },
    editFaq: data => {
        return {
            type: actions.EDIT_FAQ,
            data,
        };
    },
    deleteFaq: data => {
        return {
            type: actions.DELETE_FAQ,
            data,
        };
    },
};
export default actions;