import React from "react";
import useStateRef from "react-usestateref";
import { PageHeader } from "antd";
import { Table, Row, Col, Modal, Form, Input, Tooltip, Empty, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css";
import FeatherIcon from "feather-icons-react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Get_Gender_Data, Add_Gender_Data, Update_Gender_data } from "../../redux/gender/actionCreator";

const Gender = () => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [modalTitle, setModalTitle] = useState("Add Gender");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");

    const getDataGenderList = useSelector((state) => state.GenderReducer.genderData.data);
    const getdataGender = getDataGenderList && getDataGenderList && getDataGenderList.list;

    useEffect(() => {
        getGenderdata();
    }, []);
    const getGenderdata = () => {
        dispatch(Get_Gender_Data());
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Gender"));
        form.setFieldsValue({
            en_gender: e.en.name,
            hn_gender: e.hn.name,
        });
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setModalTitle(t("Add_Gender"));
    };

    const handleSubmit = async (value) => {
        setIsModalOpen(false);
        form.resetFields();
        const obj = {
            en: {},
            hn: {},
        };

        obj.en.name = value.en_gender;
        obj.hn.name = value.hn_gender;

        if (updateIdRef.current === "") {
            const AddGenderData = await dispatch(Add_Gender_Data(obj));
            if (AddGenderData) {
                getGenderdata();
            }
        } else {
            const obj = {
                en: {},
                hn: {},
            };
            obj.en.name = value.en_gender;
            obj.hn.name = value.hn_gender;
            const updateGenderData = await dispatch(Update_Gender_data(obj, updateIdRef.current));
            if (updateGenderData) {
                getGenderdata();
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Gender"),
            dataIndex: "name",
            align: "center",

            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].name}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <Tooltip placement="right">
                            <FeatherIcon
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(t);
                                    setIsModalOpen(true);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Gender")}
                    extra={[
                        <Button key="1" type="primary" onClick={showModal}>
                            <PlusOutlined /> {t("Add_Gender")}
                        </Button>,
                    ]}
                />
            </div>
            <div>
                <Modal open={isModalOpen} okText={t("Submit")} cancelText={t("Cancel")} onCancel={handleCancel} title={modalTitle} onOk={form.submit}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                                <Form.Item
                                    label={t("Enter_Gender_In_English")}
                                    name="en_gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[a-zA-Z ./{}()-]+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Gender_In_English")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("Enter_Gender_In_Hindi")}
                                    name="hn_gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F ./{}()-]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Gender_In_Hindi")} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getdataGender && getdataGender.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getdataGender}
                                        pagination={false}
                                    />
                                </div>
                            </>
                        ) : getdataGender && getdataGender.length === undefined ? (
                            <div className="master_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getdataGender}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <div className="master_table">
                                <Table
                                    showSorterTooltip={false}
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getdataGender}
                                    pagination={false}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Gender;
