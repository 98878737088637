import actions from './actions';
const { GET_USER_RELATIONSHIP, ADD_USER_RELATIONSHIP, UPDATE_USER_RELATIONSHIP, DELETE_USER_RELATIONSHIP } = actions;

const initState = {
  userRelationshipList: {},
  addUserRelationshipList: {}
};
const UserRelationShipReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_USER_RELATIONSHIP:
      return {
        ...state,
        userRelationshipList: data,
      };
    case ADD_USER_RELATIONSHIP:
      return {
        ...state,
        addUserRelationshipList: data,
      };
    case UPDATE_USER_RELATIONSHIP:
      return {
        ...state,
        addUserRelationshipList: data,
      };
    case DELETE_USER_RELATIONSHIP:
      return {
        ...state,
        userRelationshipList: data,
      };

    default:
      return state;
  }
};
export default UserRelationShipReducer;
