import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Form, Input, Button } from "antd";
import { AuthWrapper } from "./style";
import Heading from "../../../../components/heading/heading";
import { resetPassword } from "../../../../redux/authentication/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

const reset = () => {
    const [state, setState] = useState({
        values: null,
    });
    const [password, setPassword] = useState("");

    const [password1, setPassword1] = useState("");
    const [Mail, setMail] = useState("");

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible1, setPasswordVisible1] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const id = location && location.state && location.state.id;
    console.log("id", id);
    const handleSubmit = async (values) => {
        console.log("values : ", values);

        const reset = await dispatch(resetPassword(values, id));
        console.log("reset", reset);

        if (reset) {
            setMail("");
            history.push({
                pathname: "/",
                state: {
                    id: id,
                },
            });
        }
    };
    const handleChange = (event) => {
        const input = event.target.value;
        setPassword(input);
    };
    const handleChange1 = (event) => {
        const input = event.target.value;
        setPassword1(input);
    };

    const blockInvalidChar = (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    return (
        <AuthWrapper>
            <div className="auth-contents">
                <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
                    <Heading as="h3">Reset Password</Heading>
                    <p className="forgot-text">Enter the email address you used when you joined.</p>
                    <Form.Item
                        name="newPassword"
                        label="Enter new password"
                        rules={[
                            { required: true, message: "Please add a password" },
                            { min: 8, message: "Password must have a minimum length of 8" },
                            {
                                pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"),
                                message: "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
                            },
                        ]}
                    >
                        <Input.Password
                            visibilityToggle={{
                                visible: passwordVisible1,
                                onVisibleChange: setPasswordVisible1,
                            }}
                            type="password"
                            defaultValue=""
                            onChange={handleChange1}
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        label="Confirm new password"
                        rules={[
                            { required: true, message: "Please add a password" },
                            { min: 8, message: "Password must have a minimum length of 8" },
                            {
                                pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"),
                                message: "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
                            },
                        ]}
                    >
                        <Input.Password
                            visibilityToggle={{
                                visible: passwordVisible,
                                onVisibleChange: setPasswordVisible,
                            }}
                            type="password"
                            defaultValue=""
                            onChange={handleChange}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button className="btn-reset" htmlType="submit" type="primary" size="large">
                            Submit
                        </Button>
                    </Form.Item>
                    <p className="return-text">
                        Return to <NavLink to="/">Sign In</NavLink>
                    </p>
                </Form>
            </div>
        </AuthWrapper>
    );
};

export default reset;
