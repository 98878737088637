import actions from './actions';
const { GET_EDUCATION, ADD_EDUCATION, UPDATE_EDUCATION, DELETE_EDUCATION } = actions;

const initState = {
    educationData: {},
    AddEducation: {}
};
const EducationReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_EDUCATION:
            return {
                ...state,
                educationData: data,
            };
        case ADD_EDUCATION:
            return {
                ...state,
                AddEducation: data,
            };
        case UPDATE_EDUCATION:
            return {
                ...state,
                AddEducation: data,
            };
        case DELETE_EDUCATION:
            return {
                ...state,
                educationData: data,
            };

        default:
            return state;
    }
};
export default EducationReducer;
