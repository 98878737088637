import actions from './actions';
const { GET_PREFIX, ADD_PREFIX, UPDATE_PREFIX, DELETE_PREFIX } = actions;

const initState = {
  prefix: {},
  addPrefix: {}
};
const PrefixReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_PREFIX:
      return {
        ...state,
        prefix: data,
      };
    case ADD_PREFIX:
      return {
        ...state,
        addPrefix: data,
      };
    case UPDATE_PREFIX:
      return {
        ...state,
        addPrefix: data,
      };
    case DELETE_PREFIX:
      return {
        ...state,
        prefix: data,
      };

    default:
      return state;
  }
};
export default PrefixReducer;
