import actions from "./actions";
import { API } from "../../config/api";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";

const { getUserStatusList, updateUserDetailList, getUserDetail, getUpdatedUser, getUserUpdateDetailList, deactivateUser } = actions;

//API= fetch data from [admin pannel --> get userActivityStatus api]
export const getUserStatusData = (params) => {
    let queryString = "?";
    let equalString = "=";
    Object.keys(params).forEach((v) => {
        queryString += v + equalString + params[v] + "&";
    });
    return async (dispatch) => {
        const getUserStatusResp = await DataService.get(API.usersList.userStatus + queryString);
        if (!getUserStatusResp?.data?.error) {
            // console.log("getUserStatusResp?.data", getUserStatusResp?.data);
            dispatch(getUserStatusList(getUserStatusResp?.data));
            return true;
        } else {
            message.error(getUserStatusResp?.data?.message);
            return false;
        }
    };
};

//API= fetch data from [admin pannel --> PUT updateUser api]
export const putUserDetailsUpdate = (payload, id) => {
    return async (dispatch) => {
        const usersDetailUpdateResp = await DataService.put(API.usersList.updateUserDetail + id, payload);
        if (!usersDetailUpdateResp.data.error) {
            return dispatch(updateUserDetailList(usersDetailUpdateResp?.data?.data));
            // return true;
        } else {
            message.error(usersDetailUpdateResp.data.error);
            return false;
        }
    };
};

//API= fetch data from  [admin pannel--> get detail api]
export const get_users_details = (id) => {
    // console.log("get_users_details ~ id  :", id);
    return async (dispatch) => {
        const usersDetailsResp = await DataService.get(API.usersList.userDetail + id);
        // console.log("usersDetailsRespActionCreator>>>>", usersDetailsResp);
        if (!usersDetailsResp.data.error) {
            return dispatch(getUserDetail(usersDetailsResp?.data?.data));
        } else {
            message.error(usersDetailsResp.data.error);
            return false;
        }
    };
};

//API= fetch data from  [admin pannel--> get updated user detail]
export const getUpdateUserDetailList = (params) => {
    let queryString = "?";
    let equalString = "=";
    Object.keys(params).forEach((v) => {
        queryString += v + equalString + params[v] + "&";
    });
    return async (dispatch) => {
        const updateDetailsResp = await DataService.get(API.usersList.updatedUsersDetail + queryString);
        if (!updateDetailsResp?.data?.error) {
            dispatch(getUpdatedUser(updateDetailsResp?.data));
            return true;
        } else {
            message.error(updateDetailsResp.data.error);
            return false;
        }
    };
};

//API= fetch data from  [admin pannel--> get update track]
export const updateUserDetailTrack = () => {
    return async (dispatch) => {
        const updateDetailsResp = await DataService.get(API.usersList.updateDetailTrack);
        if (!updateDetailsResp.data.error) {
            dispatch(getUserUpdateDetailList(updateDetailsResp?.data));
            return true;
        } else {
            message.error(updateDetailsResp.data.error);
            return false;
        }
    };
};
export const DeactivateUserData = (id) => {
    return async (dispatch) => {
        const resp = await DataService.delete(API.usersList.deactivateUser + id);
        if (!resp.data.error) {
            return dispatch(deactivateUser(true));
        } else {
            message.error(resp.data.data);
            return false;
        }
    };
};
