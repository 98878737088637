const actions = {
    GET_PREFIX: 'GET_PREFIX',
    ADD_PREFIX: 'ADD_PREFIX',
    UPDATE_PREFIX: 'UPDATE_PREFIX',
    DELETE_PREFIX: 'DELETE_PREFIX',

    getPrefix: data => {
        return {
            type: actions.GET_PREFIX,
            data,
        };
    },
    addPrefix: data => {
        return {
            type: actions.ADD_PREFIX,
            data,
        };
    },
    updatePrefix: data => {
        return {
            type: actions.UPDATE_PREFIX,
            data,
        };
    },
    deletePrefix: data => {
        return {
            type: actions.DELETE_PREFIX,
            data,
        };
    },
};

export default actions;