import React, { useState } from "react";
import "./style.css";
import { Tooltip, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { MobileInfoWraper } from "./auth-info-style";
import { logOut } from "../../../redux/authentication/actionCreator";
import LanguageSelection from "./languageSelection/languageSelection";
import { useTranslation } from "react-i18next";
import { getItem } from "../../../utility/localStorageControl";

const MobileAuthInfo = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const adminData = useSelector((state) => state?.UsersStatusReducer?.getUserDetail);
    const admin_Profile = getItem("user_details");

    const SignOut = (e) => {
        // e.preventDefault();
        dispatch(logOut());
        setOpenModal(false);
    };

    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0px 15px",
                        }}
                    >
                        {t("Are_you_sure_you_want_to_signout")}
                    </p>

                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                color: "white",
                                cursor: " pointer",
                                background: "#6093fa",
                                fontSize: "large",
                                borderRadius: " 4px",
                                // background: "Black",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                // margin: "0 5px",
                                marginRight: "80px",
                            }}
                            onClick={() => {
                                SignOut();
                            }}
                        >
                            {t("Yes")}
                        </button>
                        <button
                            type="button"
                            style={{
                                fontSize: "large",
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "white",
                                borderRadius: " 4px",
                                // background: "#6093fa",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            {t("No")}
                        </button>
                    </div>
                </div>
            </Modal>
            <MobileInfoWraper>
                <div className="nav-author mobileMenu">
                    <LanguageSelection />
                    <Tooltip placement="topRight" action="click"></Tooltip>
                    <Link to="#" className="head-example">
                        {adminData?.role === "user" || adminData?.role === undefined ? (
                            <>
                                {/* <Avatar src={admin_Profile?.profile_photo} /> */}
                                <div className="user-dropdwon">
                                    <ul className="user-dropdwon__links">
                                        <li className="hoverbtn">
                                            <Link to="/profile">
                                                <FeatherIcon icon="user" style={{ marginBottom: "-4px", marginRight: "8px" }} />{" "}
                                                {adminData?.role === "user" || adminData?.role === undefined
                                                    ? admin_Profile[i18n.language]?.name
                                                    : adminData[i18n.language].name}{" "}
                                                {t("Profile")}
                                            </Link>
                                        </li>
                                    </ul>{" "}
                                    <Button
                                        onClick={() => {
                                            setOpenModal(true);
                                        }}
                                        to="#"
                                        style={{ backgroundColor: "#5f63f2", width: "100%", height: "40px", color: "white", borderRadius: "5px" }}
                                    >
                                        <FeatherIcon icon="log-out" style={{ marginBottom: "-5px", marginRight: "10px" }} />
                                        {t("Sign_Out")}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                {/* <Avatar src={adminData?.profile_photo} /> */}
                                <div className="user-dropdwon">
                                    <ul className="user-dropdwon__links">
                                        <li className="hoverbtn">
                                            <Link to="/profile">
                                                <FeatherIcon icon="user" style={{ marginBottom: "-4px", marginRight: "8px" }} />{" "}
                                                {adminData?.role === "user" || adminData?.role === undefined
                                                    ? admin_Profile[i18n.language]?.name
                                                    : adminData[i18n.language].name}{" "}
                                                {t("Profile")}
                                            </Link>
                                        </li>
                                    </ul>{" "}
                                    <Button
                                        onClick={() => {
                                            setOpenModal(true);
                                        }}
                                        to="#"
                                        style={{ backgroundColor: "#5f63f2", width: "100%", height: "40px", color: "white", borderRadius: "5px" }}
                                    >
                                        <FeatherIcon icon="log-out" style={{ marginBottom: "-5px", marginRight: "10px" }} />
                                        {t("Sign_Out")}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Link>
                </div>
            </MobileInfoWraper>
        </>
    );
};

export default MobileAuthInfo;
