import actions from "./actions";
const { GET_ADVERTISEMENT, GET_ADVERTISEMENT_DETAIL, GET_GENERAL_ADVERTISEMENT_LIST, ADD_ADVERTISEMENT, UPDATE_ADVERTISEMENT, DELETE_ADVERTISEMENT } = actions;

const initState = {
    advertisement: {},
    generalAdvList: {},
    advertisement_detail: [],
    AddAdvertisement: {},
};
const AdvertisementReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_ADVERTISEMENT:
            return {
                ...state,
                advertisement: data,
            };
        case GET_ADVERTISEMENT_DETAIL:
            return {
                ...state,
                advertisement_detail: data,
            };
        case GET_GENERAL_ADVERTISEMENT_LIST:
            return {
                ...state,
                generalAdvList: data,
            };
        case ADD_ADVERTISEMENT:
            return {
                ...state,
                AddAdvertisement: data,
            };
        case UPDATE_ADVERTISEMENT:
            return {
                ...state,
                AddAdvertisement: data,
            };
        case DELETE_ADVERTISEMENT:
            return {
                ...state,
                advertisment: data,
            };

        default:
            return state;
    }
};
export default AdvertisementReducer;
