import actions from "./actions";
const { GET_GALLERY, DELETE_GALLERY, GET_GALLERY_DETAIL, ADD_GALLERY, UPDATE_GALLERY } = actions;

const initState = {
    galleryData: {},
    AddGallery: {},
};
const GalleryReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_GALLERY:
            return {
                ...state,
                galleryData: data,
            };
        case GET_GALLERY_DETAIL:
            return {
                ...state,
                galleryData: data,
            };
        case ADD_GALLERY:
            return {
                ...state,
                AddGallery: data,
            };
        case UPDATE_GALLERY:
            return {
                ...state,
                AddGallery: data,
            };

        case DELETE_GALLERY:
            return {
                ...state,
                galleryData: data,
            };

        default:
            return state;
    }
};
export default GalleryReducer;
