import Cookies from "js-cookie";
import actions from "./actions";
import { message } from "antd";
import { removeItem, setItem } from "../../utility/localStorageControl";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { loginSuccess, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.login, payload);
        if (!resp.data.error) {
            Cookies.set("logedIn", true);
            setItem("access_token", resp?.data?.data?.token);
            setItem("userListActiveTab", "pending");
            setItem("apiLanguageSelection", "en");
            setItem("user_details", resp?.data?.data);
            return dispatch(loginSuccess(true));
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

const logOut = () => {
    return async (dispatch) => {
        try {
            dispatch(logoutBegin());
            removeItem("access_token");
            removeItem("user_details");
            removeItem("userListActiveTab");
            removeItem("User_status");
            removeItem("i18nextLng");
            removeItem("apiLanguageSelection");
            // removeItem("User_status")
            Cookies.remove("logedIn");
            dispatch(logoutSuccess(null));
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};
const forgotPassword = (payloads) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.Forgot, payloads);
        if (!resp.data.error) {
            return resp.data.data;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

const resetPassword = (payloads, id) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.reset + "/" + id, payloads);
        if (!resp.data.error) {
            return resp.data.data;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};
const verifyUser = (id, payloads) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.verify + "/" + id, payloads);
        if (!resp.data.error) {
            return resp.data.data;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export { login, logOut, forgotPassword, resetPassword, verifyUser };
