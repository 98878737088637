import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { Table, Divider, Row, Col, Button, Modal, Form, Input, Pagination, Empty, Spin, PageHeader } from "antd";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import i18n from "i18next";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Add_Gallery_Data, Delete_Gallery_data, Get_Gallery_Data, Update_Gallery_data } from "../../redux/gallery/actionCreator";
import { getDateFormate } from "../../utility/commonFunction";
import * as DOMAIN_URL from "../../config/constatant";
import { getItem } from "../../utility/localStorageControl";

const Gallery = () => {
    const loginDetail = getItem("user_details");
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { path } = useRouteMatch();
    const history = useHistory();
    const [modalTitle, setModalTitle] = useState(t("Add_Gallery"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [galleryId, setGalleryId] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [formDataImage, setFormDataImage] = useState([]);

    const dispatch = useDispatch();

    const Push = (targetValue) => {
        history.push({
            pathname: `${path}/view_gallery`,
            state: {
                id: targetValue,
            },
        });
    };

    useEffect(
        () => {
            getGalleryData(currentPage);
            setFormDataImage([]);
        }, [dataPerPage]);

    const getGalleryData = (currentPage, pageNumber) => {
        let params = {
            page: currentPage,
            limit: dataPerPage,
        };
        dispatch(Get_Gallery_Data(params));
    };

    const getDataGalleryList = useSelector((state) => state.GalleryReducer.galleryData);
    const getDataGallery = getDataGalleryList && getDataGalleryList?.data?.list;
    const EventPagination = getDataGalleryList && getDataGalleryList?.data;

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getGalleryData(page);
        setDataPerPage(pageNumber);
    };

    function uploadSingleFile(e) {
        let ImagesArray = Object.entries(e.target.files).map((e) => {
            return URL.createObjectURL(e[1]);
        });
        for (let data of Object.values(e.target.files)) {
            setFormDataImage((oldArray) => [...oldArray, data]);
        }
        setImageFile([...imageFile, ...ImagesArray]);
    }

    function deleteFile(e) {
        setFormDataImage(formDataImage.filter((item, index) => index !== e));
        const s = imageFile.filter((item, index) => index !== e);
        setImageFile(s);
    }

    const showModal = () => {
        setIsModalOpen(true);
        setupdateID("");
        setFormDataImage([]);
        setModalTitle(t("Add_Gallery"));
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        setImageFile([]);
        setFormDataImage([]);
    };

    const deleteGallery = async (values) => {
        let a = await dispatch(Delete_Gallery_data(values));
        if (a) {
            getGalleryData();
            setFormDataImage([]);
        }
        setOpenModal(false);
    };

    const showEditModal = (e) => {
        setFormDataImage([]);
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Gallery"));
        form.setFieldsValue({
            en_title: e?.en?.title,
            hn_title: e?.hn?.title,
            en_description: e?.en?.description,
            hn_description: e?.hn?.description,
        });

        for (let data of e.image) {
            setFormDataImage((oldArray) => [...oldArray, data]);
        }

        // setFormDataImage((oldArray) => [...oldArray, e.image]);
        setImageFile(e.image);
    };

    const handleSubmit = async (value) => {
        delete value.image;
        const form_data = new FormData();
        setIsModalOpen(false);
        form.resetFields();

        const obj = {
            en: {},
            hn: {},
        };
        obj.en.title = value.en_title;
        obj.hn.title = value.hn_title;
        obj.en.description = value.en_description;
        obj.hn.description = value.hn_description;
        form_data.append("en", JSON.stringify(obj.en));
        form_data.append("hn", JSON.stringify(obj.hn));

        [...formDataImage].forEach((file, i) => {
            if (Object.keys(file).includes("url")) {
                form_data.append(`image${i}`, `${file.url}/${file.type}`);
            } else {
                form_data.append(`image${i}`, file);
            }
        });

        if (updateIdRef.current === "") {
            form_data.append("created_by", loginDetail._id);
            form_data.append("updated_by", loginDetail._id);
            const AddGalleryData = await dispatch(Add_Gallery_Data(form_data));
            if (AddGalleryData) {
                getGalleryData();
                setFormDataImage([]);
            }
        } else {
            form_data.append("updated_by", loginDetail._id);
            const updateGalleryData = await dispatch(Update_Gallery_data(form_data, updateIdRef.current));
            if (updateGalleryData) {
                getGalleryData();
                setupdateID("");
                setFormDataImage([]);
            }
        }
    };

    const columns = [
        {
            title: t("Title"),
            dataIndex: "title",
            align: "center",
            sorter: (a, b) => {
                return a.en.title.localeCompare(b.en.title);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].title}</>;
            },
        },
        {
            title: t("Description"),
            dataIndex: "description",
            align: "center",
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].description}</>;
            },
        },
        {
            title: t("Date"),
            dataIndex: "date",
            align: "center",
            render: (icon, value) => {
                return <>{value && getDateFormate(value.created_at)}</>;
            },
        },
        {
            title: t("Created_By"),
            dataIndex: "date",
            align: "center",
            render: (icon, value) => {
                return <>{value && value?.created_by && value?.created_by[i18n.language]["name"] ? value?.created_by[i18n.language]["name"] : "-"}</>;
            },
        },
        {
            title: t("Updated_By"),
            dataIndex: "date",
            align: "center",
            render: (icon, value) => {
                return <>{value && value?.updated_by && value?.updated_by[i18n.language]["name"] ? value?.updated_by[i18n.language]["name"] : "-"}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, tareget) => {
                return (
                    <div className="actionContentBox">
                        <FeatherIcon icon="more-vertical" style={{ cursor: "pointer" }} size="20px" color="blue" />
                        <div className="tableActionContent">
                            <FeatherIcon
                                style={{ cursor: "pointer" }}
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(tareget);
                                    setIsModalOpen(true);
                                }}
                            />
                            <Divider type="vertical" />
                            <FeatherIcon
                                style={{ cursor: "pointer" }}
                                icon="trash"
                                size="20px"
                                color="red"
                                onClick={() => {
                                    setGalleryId(tareget._id);
                                    setOpenModal(true);
                                }}
                            />
                            <Divider type="vertical" />
                            <FeatherIcon style={{ cursor: "pointer" }} icon="eye" size="20px" color="orange" onClick={() => Push(tareget._id)} />
                        </div>
                    </div>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} galleryId={galleryId}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={require("./images.png")} style={{ height: "120px" }} alt="" />
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0 -10px",
                        }}
                    >
                        {t("Are_you_sure?")}
                    </p>
                    <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem" }}>{t("Do_you_really_want_to_delete_these_records_This_process_cannot_be_undone")}</p>
                    </div>
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                // background: "#60c7c1",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            {t("Cancel")}
                        </button>
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                // background: "#f15e5e",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                deleteGallery(galleryId);
                            }}
                        >
                            {t("Delete")}
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header galleryHeader"
                    title={t("Gallery")}
                    extra={[
                        <Button key="1" type="primary" onClick={showModal}>
                            <PlusOutlined /> {t("Add")}
                        </Button>,
                    ]}
                />
            </div>
            <div>
                <Modal open={isModalOpen} okText={t("Submit")} cancelText={t("Cancel")} onCancel={handleCancel} title={modalTitle} onOk={form.submit}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                                <h4>{t("Enter_Image_Title")} :</h4>
                                <Form.Item
                                    label={t("english")}
                                    name="en_title"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Image title in english",
                                        },
                                        {
                                            pattern: new RegExp(/^[a-zA-Z. ]+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Image_Title")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("hindi")}
                                    name="hn_title"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Image title in hindi",
                                        },
                                        {
                                            pattern: new RegExp(/^[\u0900-\u097F .,]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Image_Title")} />
                                </Form.Item>

                                <h4 style={{ marginTop: "30px" }}>{t("Enter_Image_Description")} :</h4>
                                <Form.Item
                                    label={t("english")}
                                    name="en_description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Image description in english",
                                        },
                                        {
                                            pattern: new RegExp(/^[a-zA-Z. ']+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Image_Description")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("hindi")}
                                    name="hn_description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Event in hindi",
                                        },
                                        {
                                            pattern: new RegExp(/^[\u0900-\u097F .]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Image_Description")} />
                                </Form.Item>

                                <Form.Item label={t("Upload_Image")} valuePropName="fileList" name="image">
                                    <div className="form-group preview">
                                        {imageFile.length > 0 &&
                                            imageFile.map((item, index) => {
                                                return (
                                                    <div key={item}>
                                                        {item?.url?.charAt(0) === "/" ? (
                                                            <img
                                                                src={DOMAIN_URL.DOMAIN_URL + item.url}
                                                                alt=""
                                                                width={400}
                                                                height={190}
                                                                style={{ marginTop: "15px", marginBottom: "15px" }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={item.url}
                                                                alt=""
                                                                width={400}
                                                                height={190}
                                                                style={{ marginTop: "15px", marginBottom: "15px" }}
                                                            />
                                                        )}

                                                        <span>
                                                            <FeatherIcon
                                                                style={{ cursor: "pointer", marginLeft: "20px" }}
                                                                icon="trash"
                                                                size="20px"
                                                                color="red"
                                                                onClick={() => deleteFile(index)}
                                                            />
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    <div className="form-group">
                                        <Input type="file" className="form-control" onChange={uploadSingleFile} multiple />
                                    </div>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getDataGallery && getDataGallery.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table
                                        showSorterTooltip={false}
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getDataGallery}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                        <Pagination
                                            total={EventPagination.total_counts}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showSizeChanger
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getDataGalleryList)?.length === 0 || getDataGallery?.length === 0 ? (
                            <div className="event_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getDataGallery}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Gallery;
