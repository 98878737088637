const actions = {
    GET_MARITALSTATUS: 'GET_MARITALSTATUS',
    ADD_MARITALSTATUS: 'ADD_MARITALSTATUS',
    UPDATE_MARITALSTATUS: 'UPDATE_MARITALSTATUS',
    DELETE_MARITALSTATUS: 'DELETE_MARITALSTATUS',


    getMaritalStatus: data => {
        return {
            type: actions.GET_MARITALSTATUS,
            data,
        };
    },
    addMaritalStatus: data => {
        return {
            type: actions.ADD_MARITALSTATUS,
            data,
        };
    },
    updateMaritalStatus: data => {
        return {
            type: actions.UPDATE_MARITALSTATUS,
            data,
        };
    },
    deleteMaritalStatus: data => {
        return {
            type: actions.DELETE_MARITALSTATUS,
            data,
        };
    },
};

export default actions;