const actions = {
    GET_USER_RELATIONSHIP: 'GET_USER_RELATIONSHIP',
    ADD_USER_RELATIONSHIP: 'ADD_USER_RELATIONSHIP',
    UPDATE_USER_RELATIONSHIP: 'UPDATE_USER_RELATIONSHIP',
    DELETE_USER_RELATIONSHIP: 'DELETE_USER_RELATIONSHIP',

    getUserRelationship: data => {
        return {
            type: actions.GET_USER_RELATIONSHIP,
            data,
        };
    },
    addUserRelationship: data => {
        return {
            type: actions.ADD_USER_RELATIONSHIP,
            data,
        };
    },
    updateUserRelationship: data => {
        return {
            type: actions.UPDATE_USER_RELATIONSHIP,
            data,
        };
    },
    deleteUserRelationship: data => {
        return {
            type: actions.DELETE_USER_RELATIONSHIP,
            data,
        };
    },
};

export default actions;