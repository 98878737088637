import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const {
  getUserRelationship,
  addUserRelationship,
  updateUserRelationship,
  // deleteUserRelationship,
} = actions;

export const user_relationship = (params) => {
  return async (dispatch) => {
    const getUserRelation = await DataService.get(
      API.userRelation.usersRelationLists
    );
    if (!getUserRelation.data.error) {
      dispatch(getUserRelationship(getUserRelation?.data));
      return true;
    } else {
      message.error(getUserRelation.data.message);
      return false;
    }
  };
};
export const add_user_Realtionship = (payload) => {
  return async (dispatch) => {
    const addRelationResp = await DataService.post(
      API.userRelation.addUserRelation,
      payload
    );
    if (!addRelationResp.data.error) {
      dispatch(addUserRelationship(addRelationResp.data.data));
      return true;
    } else {
      message.error(addRelationResp.data.message);
      return false;
    }
  };
};

export const update_User_Relationship = (payload, id) => {
  return async (dispatch) => {
    const UpdateRelationResp = await DataService.put(
      API.userRelation.updateUserRelation + id,
      payload
    );
    if (!UpdateRelationResp.data.error) {
      dispatch(updateUserRelationship(UpdateRelationResp.data.data));
      return true;
    } else {
      message.error(UpdateRelationResp.data.message);
      return false;
    }
  };
};

export const update_User_details_Relationship = (payload, id) => {
  return async (dispatch) => {
    const UpdateRelationResp = await DataService.put(
      API.usersList.updateUserRelationDetail + id,
      payload
    );
    if (!UpdateRelationResp.data.error) {
      return true;
    } else {
      message.error(UpdateRelationResp.data.message);
      return false;
    }
  };
};

// export const Delete_User_Relationship = (id) => {
//     return async dispatch => {
//         console.log("delete payload & Id", id)
//         const del_userRelation_resp = await DataService.delete(API. + id);
//         console.log("del_userRelation_resp::: ", del_userRelation_resp);
//         if (!del_userRelation_resp.data.error) {
//             return dispatch(deleteUserRelationship(true))

//         } else {
//             message.error(del_userRelation_resp.data.data);
//             return false;
//         }

//     };
// };
