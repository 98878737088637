import actions from './actions';
const { GET_GENDER, ADD_GENDER, UPDATE_GENDER } = actions;

const initState = {
    genderData: {},
    AddGender: {}
};
const GenderReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_GENDER:
            return {
                ...state,
                genderData: data,
            };
        case ADD_GENDER:
            return {
                ...state,
                AddGender: data,
            };
        case UPDATE_GENDER:
            return {
                ...state,
                AddGender: data,
            };


        default:
            return state;
    }
};
export default GenderReducer;
