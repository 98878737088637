const actions = {
    ADD_SUBSCRIBE_USER: "ADD_SUBSCRIBE_USER",
    GET_SUBSCRIBE_USER_LIST: "GET_SUBSCRIBE_USER_LIST",
    GET_SUBSCRIPTION_DETAIL: "GET_SUBSCRIPTION_DETAIL",
    UPDATE_SUBSCRIBE_USER: "UPDATE_SUBSCRIBE_USER",

    addSubscribeUser: (data) => {
        return {
            type: actions.ADD_SUBSCRIBE_USER,
            data,
        };
    },
    getSubscribeUserList: (data) => {
        return {
            type: actions.GET_SUBSCRIBE_USER_LIST,
            data,
        };
    },
    getSubscriptionDetail: (data) => {
        return {
            type: actions.GET_SUBSCRIPTION_DETAIL,
            data,
        };
    },
    updateSubscribeUser: (data) => {
        return {
            type: actions.UPDATE_SUBSCRIBE_USER,
            data,
        };
    },
};

export default actions;
