const actions = {
    GET_GALLERY: "GET_GALLERY",
    GET_GALLERY_DETAIL:"GET_GALLERY_DETAIL",
    ADD_GALLERY: 'ADD_GALLERY',
    UPDATE_GALLERY: 'UPDATE_GALLERY',
    DELETE_GALLERY: "DELETE_GALLERY",

    getGallery: (data) => {
        return {
            type: actions.GET_GALLERY,
            data,
        };
    },
    getGalleryDetail: (data) => {
        return {
            type: actions.GET_GALLERY_DETAIL,
            data,
        };
    },
    addGallery: data => {
        return {
            type: actions.ADD_GALLERY,
            data,
        };
    },
    updateGallery: data => {
        return {
            type: actions.UPDATE_GALLERY,
            data,
        };
    },
    deleteGallery: (data) => {
        return {
            type: actions.DELETE_GALLERY,
            data,
        };
    },
};

export default actions;
