import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getFaq, addFaq, editFaq, deleteFaq } = actions;

export const getFaqList = (params) => {
    let queMark = "?";
    let equalString = "=";
    Object.keys(params).forEach((a) => {
        queMark += a + equalString + params[a] + "&";
    });
    return async (dispatch) => {
        const getFaqResp = await DataService.get(API.Faqs.GetFaq + queMark);
        if (!getFaqResp.data.error) {
            dispatch(getFaq(getFaqResp?.data));
            return true;
        } else {
            message.error(getFaqResp.data.message);
            return false;
        }
    };
};
export const addNewFaq = (payload) => {
    return async (dispatch) => {
        const addFaqResp = await DataService.post(API.Faqs.AddFaq, payload);
        if (!addFaqResp.data.error) {
            return dispatch(addFaq(addFaqResp.data.data));
        } else {
            message.error(addFaqResp.data.message);
            return false;
        }
    };
};

export const updateFaqData = (payload, id) => {
    return async (dispatch) => {
        const updateFaqResp = await DataService.put(API.Faqs.UpdateFaq + id, payload);
        if (!updateFaqResp.data.error) {
            dispatch(editFaq(updateFaqResp.data.data));
            return true;
        } else {
            message.error(updateFaqResp.data.message);
            return false;
        }
    };
};

export const deleteFaqData = (id) => {
    return async (dispatch) => {
        const deleteFaqResp = await DataService.delete(API.Faqs.DeleteFaq + id);
        if (!deleteFaqResp.data.error) {
            return dispatch(deleteFaq(true));
        } else {
            message.error(deleteFaqResp.data.data);
            return false;
        }
    };
};
