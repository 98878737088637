const actions = {
    GET_OCCUPATION_LIST: 'GET_OCCUPATION_LIST',
    ADD_OCCUPATION: 'ADD_OCCUPATION',
    EDIT_OCCUPATION: 'EDIT_OCCUPATION',
    DELETE_OCCUPATION: 'DELETE_OCCUPATION',

    getOccupation: data => {
        return {
            type: actions.GET_OCCUPATION_LIST,
            data,
        };
    },
    addOccupation: data => {
        return {
            type: actions.ADD_OCCUPATION,
            data,
        };
    },
    editOccupation: data => {
        return {
            type: actions.EDIT_OCCUPATION,
            data,
        };
    },
    deleteOccupation: data => {
        return {
            type: actions.DELETE_OCCUPATION,
            data,
        };
    },
};
export default actions;