import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Table, Modal, PageHeader, Row, Col, Empty, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { contact, sendContactReply } from "../../redux/Contact/actionCreator";
import "./ContactMeCss.css";

const ContactMe = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState(null);
    const [editorChangeData, setEditorChangeData] = useState("");
    const [emaildata, setEmailData] = useState("");

    const dispatch = useDispatch();
    const getDataContact = useSelector((state) => state.ContactReducer.contact.data);

    useEffect(() => {
        getContactdata();
    }, []);

    const getContactdata = () => {
        dispatch(contact());
    };

    const contactArray = [];
    contactArray.push(getDataContact);

    const handleChange = (e, editor) => {
        setEditorChangeData(editor?.getData());
    };

    const SendReplyModal = (id, name, email, message) => {
        const messages = message ? message : "";
        setIsModalOpen(true);
        if (id) {
            setTitle(t("Send_Reply") + " " + "to" + " " + name);
            setEditorChangeData(messages);
        } else {
            setTitle(t("Send_Reply"));
        }
        setEmailData(email);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = async () => {
        const ContactData = {
            message: editorChangeData,
            email: emaildata,
        };
        const response = await dispatch(sendContactReply(ContactData));
        if (response) {
            setIsModalOpen(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: t("Name"),
            dataIndex: "name",
            align: "center",
            render: (index, value) => {
                return value && value.name === "" ? "-" : value && value.name;
            },
        },
        {
            title: t("Email"),
            dataIndex: "email",
            align: "center",
            render: (index, value) => {
                return value && value.email === "" ? "-" : value && value.email;
            },
        },
        {
            title: t("Contact_No"),
            dataIndex: "contactNumber",
            align: "center",
            render: (index, value) => {
                return value && value.contactNumber === "" ? "-" : value && value.contactNumber;
            },
        },
        {
            title: t("Comment"),
            dataIndex: "comment",
            align: "center",
            render: (index, value) => {
                return value && value.comment === "" ? "-" : value && value.comment;
            },
        },
        {
            title: "Status",
            render(index, value) {
                return {
                    children: (
                        <span>
                            {value && value?.status === "sent" ? (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "green",
                                        borderStyle: "solid",
                                        paddingRight: "16px",
                                        paddingLeft: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        backgroundColor: "green",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                    }}
                                    className="sentStyle"
                                >
                                    {" "}
                                    Sent{" "}
                                </span>
                            ) : (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "red",
                                        borderStyle: "solid",
                                        padding: "5px",
                                        backgroundColor: "red",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                    }}
                                >
                                    {" "}
                                    Pending{" "}
                                </span>
                            )}
                        </span>
                    ),
                };
            },
        },
        // {
        //     title: t("Actions"),
        //     render: (index, value) => (
        //         <div className="table-actions">
        //             <>
        //                 <a className="btn-icon" to="#" shape="circle" onClick={() => SendReplyModal(value._id, value.name, value.email, value.message)}>
        //                     <FeatherIcon icon="message-square" size={16} />
        //                 </a>
        //             </>
        //         </div>
        //     ),
        // },
        {
            title: t("Actions"),
            render: (index, value) => (
                <div className="table-actions">
                    <>
                        <button className="btn-icon" type="button" onClick={() => SendReplyModal(value._id, value.name, value.email, value.message)}>
                            <FeatherIcon icon="message-square" size={16} />
                        </button>
                    </>
                </div>
            ),
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    title={t("ContactDetail")}
                    extra={[
                        <Button key="1" type="primary" onClick={showModal}>
                            <PlusOutlined /> {t("Add_ContactInfo")}
                        </Button>,
                    ]}
                />
            </div>
            <Modal width={570} open={isModalOpen} title={title} onCancel={handleCancel} footer={null} maskClosable={false}>
                <Row gutter={25}>
                    <Col>
                        <CKEditor editor={ClassicEditor} onChange={(e, editor) => handleChange(e, editor)} data={editorChangeData} />
                    </Col>
                    <Col md={12}>
                        <Button
                            className="ant-btn ant-btn-primary ant-btn-md"
                            style={{ width: "30%", marginLeft: "425px", marginTop: "15px" }}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {t("Send")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Row>
                <Col xs={24} sm={24} md={24}>
                    {contactArray[0]?.list && contactArray[0]?.list.length > 0 ? (
                        <div className="master_table">
                            <Table
                                style={{ marginBottom: 24 }}
                                scroll={{
                                    x: "3vh",
                                }}
                                columns={columns}
                                dataSource={contactArray[0]?.list}
                                pagination={false}
                            />
                        </div>
                    ) : (
                        <div className="master_table">
                            <Table
                                showSorterTooltip={false}
                                scroll={{
                                    x: "3vh",
                                }}
                                locale={locale}
                                columns={columns}
                                dataSource={contactArray[0]?.list}
                                pagination={false}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default ContactMe;
