import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { Table, Row, Col, Button, Modal, Form, Input, Pagination, Empty, Spin, PageHeader, Image, Avatar } from "antd";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, CloseOutlined, FilePdfTwoTone } from "@ant-design/icons";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Get_Event_Data, Add_Event_Data, Update_Event_data, Delete_Event_data } from "../../redux/event/actionCreator";
import { getDateFormate } from "../../utility/commonFunction";

const event = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [img, setImg] = useState("");
    const [modalTitle, setModalTitle] = useState(t("Add_Event"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [previewMedia, setPreviewMedia] = useState("");
    const [dataPerPage, setDataPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [eventId, setEventId] = useState("");
    const dispatch = useDispatch();

    useEffect(
        () => {
            getEventData(currentPage);
        },
        [dataPerPage],
        []
    );
    const getEventData = (currentPage, pageNumber) => {
        let params = {
            page: currentPage,
            limit: dataPerPage,
        };
        dispatch(Get_Event_Data(params));
    };

    const getDataEventList = useSelector((state) => state.EventReducer.eventData);
    const getDataEvent = getDataEventList && getDataEventList?.data?.list;
    const EventPagination = getDataEventList && getDataEventList?.data;

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getEventData(page);
        setDataPerPage(pageNumber);
    };

    const changeHandler = (e) => {
        setImg(e.target.files[0]);
        if (
            (e.target.files.length !== 0 && e.target.files[0].type == "image/jpeg") ||
            e.target.files[0].type == "image/png" ||
            e.target.files[0].type == "image/jpg"
        ) {
            setPreviewMedia(
                new Array(e.target.files.length).fill(e.target.files).map((imgFile, i) => {
                    return <img src={URL.createObjectURL(imgFile[i])} width="70" height="70" className="addImg" name="advertisementBanner" />;
                })
            );
        }
    };

    const removeImg = () => {
        setPreviewMedia([]);
        setImg([]);
    };

    const showModal = () => {
        setIsModalOpen(true);
        setupdateID("");
        setModalTitle(t("Add_Event"));
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const deleteEvent = async (values) => {
        let a = await dispatch(Delete_Event_data(values));
        if (a) {
            getEventData();
        }
        setOpenModal(false);
    };

    const handleSubmit = async (value) => {
        console.log("value***************", value);
        delete value.image;

        const form_data = new FormData();
        setIsModalOpen(false);
        form.resetFields();

        const obj = {
            en: {},
            hn: {},
        };
        (obj.en.title = value.en_title),
            (obj.hn.title = value.hn_title),
            (obj.en.description = value.en_description),
            (obj.hn.description = value.hn_description),
            form_data.append("en", JSON.stringify(obj.en));
        form_data.append("hn", JSON.stringify(obj.hn));
        form_data.append("created_date", value.created_date);
        if (img !== "") {
            form_data.append("image", img);
        }

        if (updateIdRef.current === "") {
            const AddEventData = await dispatch(Add_Event_Data(form_data));
            if (AddEventData) {
                getEventData();
                setImg("");
            }
        } else {
            const updateEventData = await dispatch(Update_Event_data(form_data, updateIdRef.current));
            if (updateEventData) {
                getEventData();
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Image"),
            dataIndex: "image",
            align: "center",
            render: (index, value) => {
                const compareData = value?.image
                    ?.split("/")
                    ?.pop()
                    ?.split(".")
                    ?.pop();
                {
                    if (value?.image === "" || compareData == "pdf") {
                        return (
                            <>
                                <Avatar
                                    icon={<FilePdfTwoTone style={{ color: "#08c" }} />}
                                    style={{ backgroundColor: "rgb(235, 238, 241)" }}
                                    size="large"
                                    shape="square"
                                />
                            </>
                        );
                    } else {
                        return (
                            <Image
                                height={40}
                                width={40}
                                crossOrigin="anonymous"
                                alt=""
                                style={{
                                    borderRadius: 50,
                                }}
                                src={`${value.image}`}
                            />
                        );
                    }
                }
            },
        },
        {
            title: t("Event_Title"),
            dataIndex: "title",
            align: "center",
            sorter: (a, b) => {
                return a.en.title.localeCompare(b.en.title);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].title}</>;
            },
        },
        {
            title: t("Event_Description"),
            dataIndex: "description",
            align: "center",
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].description}</>;
            },
        },
        {
            title: t("Event_Create_Date"),
            dataIndex: "description",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.created_date ? getDateFormate(value.created_date) : "-"}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <FeatherIcon
                            icon="trash"
                            size="20px"
                            color="red"
                            onClick={() => {
                                setEventId(t._id);
                                setOpenModal(true);
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} eventId={eventId}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={require("./images.png")} style={{ height: "120px" }} />
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0 -10px",
                        }}
                    >
                        {t("Are_you_sure?")}
                    </p>
                    <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem" }}>{t("Do_you_really_want_to_delete_these_records_This_process_cannot_be_undone")}</p>
                    </div>
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#60c7c1",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            {t("Cancel")}
                        </button>
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#f15e5e",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                deleteEvent(eventId);
                            }}
                        >
                            {t("Delete")}
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header galleryHeader"
                    title={t("Event")}
                    extra={[
                        <Button key="1" type="primary" onClick={showModal}>
                            <PlusOutlined /> {t("Add_Event")}
                        </Button>,
                    ]}
                />
            </div>

            <div>
                <Modal open={isModalOpen} okText={t("Submit")} cancelText={t("Cancel")} onCancel={handleCancel} title={modalTitle} onOk={form.submit}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                                <Form.Item label={t("Upload_Image")} valuePropName="fileList" name="image">
                                    <Input
                                        size="small"
                                        type="file"
                                        title=""
                                        placeholder="Choose image"
                                        onChange={changeHandler}
                                        suffix={<CloseOutlined onClick={removeImg} />}
                                    />
                                    {previewMedia}
                                </Form.Item>

                                <h4 style={{ marginTop: "30px" }}>{t("Enter_Event_Title")} :</h4>
                                <Form.Item
                                    label={t("english")}
                                    name="en_title"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Event in english",
                                        },
                                        {
                                            pattern: new RegExp(/^[a-zA-Z. ]+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Event")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("hindi")}
                                    name="hn_title"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Event in hindi",
                                        },
                                        {
                                            pattern: new RegExp(/^[\u0900-\u097F .,]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Event")} />
                                </Form.Item>

                                <h4 style={{ marginTop: "30px" }}>{t("Enter_Event_Description")} :</h4>
                                <Form.Item
                                    label={t("english")}
                                    name="en_description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Event in english",
                                        },
                                        {
                                            pattern: new RegExp(/^[a-zA-Z. ']+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Event_Description")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("hindi")}
                                    name="hn_description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Event in hindi",
                                        },
                                        {
                                            pattern: new RegExp(/^[\u0900-\u097F .]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Event_Description")} />
                                </Form.Item>
                                <Form.Item
                                    label={t("Date")}
                                    name="created_date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter date",
                                        },
                                    ]}
                                >
                                    <Input size="small" type="date" />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getDataEvent && getDataEvent.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table
                                        showSorterTooltip={false}
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getDataEvent}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                        <Pagination
                                            total={EventPagination.total_counts}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showSizeChanger
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getDataEventList)?.length == 0 || getDataEvent?.length === 0 ? (
                            <div className="event_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getDataEvent}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default event;
