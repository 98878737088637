import actions from './actions';
import { message } from 'antd';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const { getGender, addGender, updateGender } = actions;


export const Get_Gender_Data = () => {

    return async (dispatch) => {
        const getData = await DataService.get(API.gender.getGender);
        if (!getData.data.error) {
            dispatch(getGender(getData?.data))
            return getData
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};

export const Add_Gender_Data = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.gender.addGender, payload);
        if (!resp.data.error) {
            dispatch(addGender(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Update_Gender_data = (payload, id) => {
    return async (dispatch) => {
        const resp = await DataService.put(API.gender.updateGender + "/" + id, payload);
        if (!resp.data.error) {
            dispatch(updateGender(resp.data.data))
            return resp.data;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};


