import actions from './actions';
import { message } from 'antd';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");


const { getUsersList } = actions;


const userLists = () => {
    return async (dispatch) => {
        const getData = await DataService.get(API.user.userLists);
        if (!getData.data.error) {

            dispatch(getUsersList(getData?.data?.data))
            return true

        } else {
            message.error(getData.data.message);
            return false;
        }

    };
};
export const userDataExport = (payload) => {
    return async (dispatch) => {
        const getData = await DataService.post(API.usersList.exportUser, payload);
        if (!getData.data.error) {
            return (getData.data.data);
        }
        else {
            message.error(getData.data.message);
            return false;
        }
    }
}

export default userLists