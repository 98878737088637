import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Descriptions, Col, PageHeader, Button, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { get_Broadcast_details } from "../../redux/Broadcast/actionCreator";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";
import "./Broadcast.css";

const ViewBroadcastDetail = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [chatId, setChatId] = useState("");
    const [messageID, setMessageID] = useState("");

    const BroadcastListData = useSelector((state) => state.BroadcastReducer.detailData);
    useEffect(() => {
        if (location && location.state && location.state.chatId && location.state.message_id !== undefined) {
            Broadcast(location.state.chatId, location.state.message_id);
            console.log("🚀 ~ file: ViewBroadcastDetail.js:37 ~ useEffect ~ location.state.message_id:", location.state.message_id);
            console.log("🚀 ~ file: ViewBroadcastDetail.js:37 ~ useEffect ~ location.state.chatId:", location.state.chatId);
        }
    }, []);

    const handleStatus = async (chatId, messageID, status) => {
        const broadcastDetailsResp = await DataService.post(API.broadcast.approveStatus, {
            chatId: location.state.chatId,
            message_id: location.state.message_id,
            status: status,
        });
        if (broadcastDetailsResp.data.error === false) {
            Broadcast(location.state.chatId, location.state.message_id);
            setOpenModal(false);
        }
        console.log("broadcastDetailsResp", broadcastDetailsResp);
    };

    const Broadcast = (chatId, message_id) => {
        dispatch(get_Broadcast_details(chatId, message_id));
    };

    return (
        <>
            {BroadcastListData && BroadcastListData ? (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="GeneralAdvViewPage">
                            <div className="viewAdvertisement">
                                <PageHeader ghost={false} className="site-page-header">
                                    <FeatherIcon
                                        icon="arrow-left-circle"
                                        size="28px"
                                        onClick={() => window.history.back()}
                                        style={{ cursor: "pointer", marginRight: "10px", marginTop: "5px", marginBottom: "-7px" }}
                                    />
                                    <span style={{ fontSize: "20px" }}>{t("Broadcast_message_details")}</span>
                                </PageHeader>
                            </div>

                            <div className="description_Container">
                                <Descriptions bordered column={1} labelStyle={{ width: "300px" }}>
                                    {console.log("BroadcastListData", BroadcastListData)}
                                    <Descriptions.Item label={t("Broadcast_name")}>{BroadcastListData && BroadcastListData?.broadcastName}</Descriptions.Item>
                                    <Descriptions.Item label={t("Sender_name")}>
                                        {BroadcastListData && BroadcastListData?.broadcastCreator?.name + " " + BroadcastListData?.broadcastCreator?.surname}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Broadcast_message")}>
                                        {BroadcastListData && BroadcastListData?.messageInfo?.message}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Status")}>
                                        <span
                                            style={{
                                                borderWidth: "1px",
                                                borderColor:
                                                    BroadcastListData?.messageInfo?.isApproved === "pending"
                                                        ? "orange"
                                                        : BroadcastListData?.messageInfo?.isApproved === "approved"
                                                        ? "green"
                                                        : "red",
                                                borderStyle: "solid",
                                                paddingRight: "16px",
                                                paddingLeft: "16px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                backgroundColor:
                                                    BroadcastListData?.messageInfo?.isApproved === "pending"
                                                        ? "orange"
                                                        : BroadcastListData?.messageInfo?.isApproved === "approved"
                                                        ? "green"
                                                        : "red",
                                                borderRadius: "5px",
                                                color: "white",
                                            }}
                                            className="sentStyle"
                                        >
                                            {" "}
                                            {BroadcastListData?.messageInfo?.isApproved.charAt(0).toUpperCase() +
                                                BroadcastListData?.messageInfo?.isApproved.slice(1)}{" "}
                                        </span>
                                        {/* {BroadcastListData && BroadcastListData?.messageInfo?.isApproved} */}
                                    </Descriptions.Item>

                                    <Descriptions.Item label={t("Send_date_&_time")}>
                                        {BroadcastListData && moment(BroadcastListData?.messageInfo?.created_at).format("LLL")}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Broadcast_participants")}>
                                        {BroadcastListData &&
                                            BroadcastListData?.participants?.map((user) => {
                                                return (
                                                    <div class="border-btn text-danger mr-2">
                                                        {user.name.charAt(0).toUpperCase() +
                                                            user.name.slice(1) +
                                                            " " +
                                                            user.surname.charAt(0).toUpperCase() +
                                                            user.surname.slice(1)}{" "}
                                                    </div>
                                                );
                                            })}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </div>
                    </Col>
                    {BroadcastListData && BroadcastListData?.messageInfo?.isApproved === "pending" ? (
                        <Button
                            type="primary"
                            style={{ marginLeft: "46px", marginBottom: "46px" }}
                            onClick={() => {
                                setOpenModal(true);
                            }}
                        >
                            {t("Approve_Reject")}
                        </Button>
                    ) : (
                        " "
                    )}
                </Row>
            ) : (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="spinnerName">
                            <Spin size="large"></Spin>
                        </div>
                    </Col>
                </Row>
            )}
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} chatId={chatId} messageID={messageID}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem", fontSize: "20px" }}>{t("You_want_to_sure_aprrove_this_message_?")}</p>
                    </div>
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                // background: "#60c7c1",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                handleStatus(chatId, messageID, "approved");
                            }}
                        >
                            {t("Approve")}
                        </button>
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                // background: "#f15e5e",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                // borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                handleStatus(chatId, messageID, "rejected");
                            }}
                        >
                            {t("Reject")}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ViewBroadcastDetail;
