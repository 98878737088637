import actions from "./actions";

const {
    GET_USERS_DETAIL,
    UPDATE_USERS_DETAIL,
    GET_USERS_STATUS_LIST,
    USER_LIST,
    GET_USER_DETAIL_LIST,
    UPDATED_USERS_DETAIL,
    GET_UPDATE_USER_DETAIL,
    DEACTIVATE_USER,
} = actions;

const initState = {
    userStatus: [],
    updatedUsers: [],
    userDetailList: [],
    updateDetailTrack: {},
    getUserDetail: [],
    updateUserDetail: {},
    deactivateUser: {},
};

const UsersStatusReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_USERS_STATUS_LIST:
            return {
                ...state,
                userStatus: data,
            };
        case GET_USER_DETAIL_LIST:
            return {
                ...state,
                userDetailList: data,
            };
        case GET_USERS_DETAIL:
            return {
                ...state,
                getUserDetail: data,
            };
        case UPDATE_USERS_DETAIL:
            return {
                ...state,
                updateUserDetail: data,
            };
        case USER_LIST:
            return {
                ...state,
                userStatus: data,
            };
        case UPDATED_USERS_DETAIL:
            return {
                ...state,
                updatedUsers: data,
            };
        case GET_UPDATE_USER_DETAIL:
            return {
                ...state,
                updateDetailTrack: data,
            };
        case DEACTIVATE_USER:
            return {
                ...state,
                deactivateUser: data,
            };
        default:
            return state;
    }
};

export default UsersStatusReducer;
