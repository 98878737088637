import { API } from "../../config/api";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";
const { addSubscribeUser, getSubscribeUserList, getSubscriptionDetail, updateSubscribeUser } = actions;

export const Add_Subscription_User_Data = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.subscription.addSubscribeUser, payload);
        if (!resp.data.error) {
            dispatch(addSubscribeUser(resp.data.data));
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Get_Subscribe_User_Data = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";
    Object.keys(params).forEach((a) => {
        queMark += a + equalString + params[a] + ampersand;
    });

    return async (dispatch) => {
        const getData = await DataService.get(API.subscription.getSubscribeUserList + queMark);
        if (!getData.data.error) {
            dispatch(getSubscribeUserList(getData?.data));
            return true;
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};

export const get_Subscription_details = (id) => {
    return async (dispatch) => {
        const subscriptionDetailsResp = await DataService.get(API.subscription.getSubscriptionDetail + id);
        if (!subscriptionDetailsResp.data.error) {
            return dispatch(getSubscriptionDetail(subscriptionDetailsResp?.data?.data));
            // return true;
        } else {
            message.error(subscriptionDetailsResp.data.error);
            return false;
        }
    };
};

export const Update_Subscription_User_Data = (payload, id) => {
    console.log("🚀 ~ file: actionCreator.js:54 ~ id:", id);
    return async (dispatch) => {
        const resp = await DataService.put(API.subscription.updateSubscribeuser + id, payload);
        if (!resp.data.error) {
            dispatch(updateSubscribeUser(resp.data.data));
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};
