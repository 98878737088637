import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getGallery, deleteGallery, getGalleryDetail, addGallery, updateGallery } = actions;

export const Get_Gallery_Data = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";
    Object.keys(params).forEach((a) => {
        queMark += a + equalString + params[a] + ampersand;
    });

    return async (dispatch) => {
        const getData = await DataService.get(API.gallery.getGallery + queMark);
        if (!getData.data.error) {
            dispatch(getGallery(getData?.data));
            return true;
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};

export const get_Gallery_details = (id) => {
    return async (dispatch) => {
        const galleryDetailsResp = await DataService.get(API.gallery.getGalleryDetail + id);
        if (!galleryDetailsResp.data.error) {
            return dispatch(getGalleryDetail(galleryDetailsResp?.data?.data));
            // return true;
        } else {
            message.error(galleryDetailsResp.data.error);
            return false;
        }
    };
};

export const Add_Gallery_Data = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.gallery.addGalleryData, payload);
        if (!resp.data.error) {
            dispatch(addGallery(resp.data.data));
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Update_Gallery_data = (payload, id) => {
    return async (dispatch) => {
        const resp = await DataService.put(API.gallery.updateGalleryData + id, payload);
        if (!resp.data.error) {
            dispatch(updateGallery(resp.data.data));
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Delete_Gallery_data = (id) => {
    return async (dispatch) => {
        const resp = await DataService.delete(API.gallery.deleteGallery + id);
        if (!resp.data.error) {
            return dispatch(deleteGallery(true));
        } else {
            message.error(resp.data.data);
            return false;
        }
    };
};
