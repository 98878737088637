/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
    auth: {
        login: "admin/login",
        signUp: "admin/signup",
        Forgot: "admin/forgot-password",
        verify: "admin/verifyForgetPasswordOtp",
        reset: "admin/resetPassword",
        getData: "admin/detail/",
    },
    usersList: {
        exportUser: "admin/exportUsers",
        userDetail: "admin/detail/",
        updateUserDetail: "admin/update/",
        updateUserRelationDetail: "admin/updateUserRelation/",
        userStatus: "admin/appStatus",
        updatedUsersDetail: "admin/updatedDetails",
        updateDetailTrack: "trackData/list",
        deactivateUser: "admin/deleteUser/",
    },
    marital: {
        maritalStatus: "marital/list",
        addMarital: "marital/create",
        deleteMarital: "marital/delete/",
        updateMarital: "marital/update/",
    },
    bloodGroup: {
        getBloodGroup: "bloodGroup/list",
        addBloodGroup: "bloodGroup/create",
        updateBloodGroup: "bloodGroup/update/",
        deleteBloodGroup: "bloodGroup/delete/",
    },
    relationShip: {
        getRelationship: "relationName/list",
        addRelationship: "relationName/create",
        updateRelationship: "relationName/update/",
        deleteRelationship: "relationName/delete/",
    },
    relationShip_Combination: {
        getRelationship_Combination: "matchRelation/list",
        addRelationship_combination: "matchRelation/create",
        updateRelationship_Combination: "matchRelation/update/",
        // deleteRelationship_Combination: 'relationName/delete/'
    },
    prefix: {
        getPrefix: "prefix/list",
        addPrefix: "prefix/create",
        updatePrefix: "prefix/update/",
        deletePrefix: "prefix/delete/",
    },
    gender: {
        getGender: "gender_master/list",
        addGender: "gender_master/create",
        updateGender: "gender_master/update",
        // deleteGender: 'prefix/delete/'
    },
    advertisement: {
        getAdvertisement: "advertisement/personal_ads",
        getAdvertisementDetail: "advertisement/detail/",
        addAdvertisement: "advertisement/create",
        updateAdvertisement: "advertisement/update/",
        deleteAdvertisement: "advertisement/delete/",
        getGeneralAdvList: "advertisement/general_ads",
    },
    userRelation: {
        usersRelationLists: "relation/list",
        addUserRelation: "relation/create",
        updateUserRelation: "relation//updateRelation/",
        // deleteUserRelation:'advertisement/delete/'
    },
    contact: {
        adminContactList: "contact/get",
        addContact: "contact/addContact",
        sendMessageReply: "contact/sendMessage",
    },
    Gotra: {
        Get_Gotra: "gotra/list",
        Add_Gotra: "gotra/create",
        Update_Gotra: "gotra/update/",
        Delete_Gotra: "gotra/delete/",
    },
    Education: {
        Get_Education: "education/list",
        Add_Education: "education/create",
        Update_Education: "education/update/",
    },
    Faqs: {
        GetFaq: "faq/listFAQ",
        AddFaq: "faq/addFAQ",
        UpdateFaq: "faq/updateFAQ/",
        DeleteFaq: "faq/deleteFAQ/",
    },
    occupation: {
        GetOccupation: "occupation/list",
        AddOccupation: "occupation/create",
        UpdateOccupation: "occupation/update/",
        DeleteOccupation: "occupation/delete/",
    },
    notification: {
        NotificationList: "notification/list",
        DeleteNotification: "notification/delete/",
        DeleteAllNotification: "notification/multipleDelete",
    },
    event: {
        EventList: "events/eventList",
        AddEvent: "events/addEvents",
        // UpdateEvent:'event/update/',
        DeleteEvent: "events/delete/",
    },
    dashboard: {
        getDashboard: "admin/dashboard",
        getUser: "admin/recentUser",
        getGraph: "admin/dashboardGraph",
    },
    gallery: {
        getGallery: "gallery/list",
        deleteGallery: "gallery/delete/",
        getGalleryDetail: "gallery/detail/",
        addGalleryData: "gallery/add",
        updateGalleryData: "gallery/update/",
    },
    subscription: {
        addSubscribeUser: "subscription/addSubscribeUser",
        getSubscribeUserList: "subscription/subscribeUserList",
        getSubscriptionDetail: "subscription/subscriptionDetails/",
        updateSubscribeuser: "subscription/updateSubscribeUser/",
    },
    broadcast: {
        getBroadcastList: "admin/broadcastMessagelistForAdmin",
        getBroadcastMessageDetails: "admin/broadcastUserInfo",
        approveStatus: "admin/approveBroadcastMessage",
    },
};

export { API };
