const actions = {
    GET_EDUCATION: 'GET_EDUCATION',
    ADD_EDUCATION: 'ADD_EDUCATION',
    UPDATE_EDUCATION: 'UPDATE_EDUCATION',
    DELETE_EDUCATION: 'DELETE_EDUCATION',


    getEducation: data => {
        return {
            type: actions.GET_EDUCATION,
            data,
        };
    },
    addEducation: data => {
        return {
            type: actions.ADD_EDUCATION,
            data,
        };
    },
    updateEducation: data => {
        return {
            type: actions.UPDATE_EDUCATION,
            data,
        };
    },
    deleteEducation: data => {
        return {
            type: actions.DELETE_EDUCATION,
            data,
        };
    },
};

export default actions;