import actions from './actions';
const { GET_DASHBOARD, GET_USER, GET_GRAPH } = actions;

const initState = {
    dashboardData: {},
    userData: {},
};
const DashboardReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_DASHBOARD:
            return {
                ...state,
                dashboardData: data,
            };
        case GET_USER:
            return {
                ...state,
                userData: data,
            };
        case GET_GRAPH:
            return {
                ...state,
                graphData: data,
            };
        default:
            return state;
    }
};
export default DashboardReducer;
