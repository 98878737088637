import React, { useState, useEffect } from "react";
import { Table, Row, Col, Pagination, Empty, Spin, PageHeader } from "antd";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Get_Broadcast_List_Data } from "../../redux/Broadcast/actionCreator";
import moment from "moment";
import { useRouteMatch, useHistory } from "react-router-dom";

const Broadcast = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const history = useHistory();

    const Push = (targetValue, taregetvalue1) => {
        history.push({
            pathname: `${path}/view_broadcastDetails`,
            state: {
                chatId: targetValue,
                message_id: taregetvalue1,
            },
        });
    };

    useEffect(
        () => {
            getBroadcastListData(currentPage);
        },
        [dataPerPage]);

    const getBroadcastListData = (currentPage, pageNumber) => {
        let params = {
            page: currentPage,
            limit: dataPerPage,
        };
        dispatch(Get_Broadcast_List_Data(params));
    };

    const getDataBroadcastList = useSelector((state) => state.BroadcastReducer.broadcastData);
    const getDataBroadcast = getDataBroadcastList && getDataBroadcastList?.data?.list;
    const BroadcastPagination = getDataBroadcastList && getDataBroadcastList?.data;

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getBroadcastListData(page);
        setDataPerPage(pageNumber);
    };

    const columns = [
        {
            title: t("Sender_name"),
            dataIndex: "sender_name",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.senderId[i18n.language] && value.senderId[i18n.language].name + " " + value.senderId[i18n.language].surname}</>;
            },
        },
        {
            title: t("Broadcast_name"),
            dataIndex: "broadcast_name",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.chatId.chatName}</>;
            },
        },
        {
            title: t("Broadcast_message"),
            dataIndex: "broadcast_message",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.message}</>;
            },
        },
        {
            title: t("Status"),
            dataIndex: "status",
            align: "center",
            render: (icon, value) => {
                console.log("value", value.isApproved);
                return {
                    children: (
                        <span
                            style={{
                                borderWidth: "1px",
                                borderColor: value.isApproved === "pending" ? "orange" : value.isApproved === "approved" ? "green" : "red",
                                borderStyle: "solid",
                                paddingRight: "16px",
                                paddingLeft: "16px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                backgroundColor: value.isApproved === "pending" ? "orange" : value.isApproved === "approved" ? "green" : "red",
                                borderRadius: "5px",
                                color: "white",
                            }}
                            className="sentStyle"
                        >
                            {" "}
                            {value.isApproved.charAt(0).toUpperCase() + value.isApproved.slice(1)}{" "}
                        </span>
                    ),
                };
            },
        },
        {
            title: t("Send_date_&_time"),
            dataIndex: "date_time",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.created_at ? moment(value.created_at).format("LLL") : "-"}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, tareget) => {
                console.log("tareget chat", tareget.chatId._id);
                console.log("tareget message", tareget._id);
                return (
                    <>
                        <FeatherIcon
                            style={{ cursor: "pointer" }}
                            icon="eye"
                            size="20px"
                            color="orange"
                            onClick={() => Push(tareget.chatId._id, tareget._id)}
                        />
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader ghost={false} className="site-page-header" title={t("Broadcast")} />
            </div>

            <div>
                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getDataBroadcast && getDataBroadcast.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table
                                        showSorterTooltip={false}
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getDataBroadcast}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24}>
                                        <Pagination
                                            total={BroadcastPagination.total_counts}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showSizeChanger
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getDataBroadcastList)?.length === 0 || getDataBroadcast?.length === 0 ? (
                            <div className="broadcast_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getDataBroadcast}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Broadcast;
