import actions from "./actions";

const { global_api } = actions;

const global_api_data = (payload) => {
    return async (dispatch) => {
        return dispatch(global_api(payload));

    };
};



export { global_api_data };
