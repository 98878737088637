import actions from './actions';
const { GET_GOTRA, ADD_GOTRA, UPDATE_GOTRA, DELETE_GOTRA } = actions;

const initState = {
    gotraData: {},
    AddGotra: {}
};
const GotraReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_GOTRA:
            return {
                ...state,
                gotraData: data,
            };
        case ADD_GOTRA:
            return {
                ...state,
                AddGotra: data,
            };
        case UPDATE_GOTRA:
            return {
                ...state,
                AddGotra: data,
            };
        case DELETE_GOTRA:
            return {
                ...state,
                gotraData: data,
            };

        default:
            return state;
    }
};
export default GotraReducer;
