import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const { getNotificationList, deleteNotification, deleteAllNotifications } = actions;

export const getNotificationListData = (params) => {
    let queMark = "?";
    let equalString = "=";
    Object.keys(params).forEach((a) => {
        queMark += a + equalString + params[a] + "&";
    });
    return async (dispatch) => {
        const getNotificationListResp = await DataService.get(API.notification.NotificationList + queMark);
        if (!getNotificationListResp.data.error) {
            dispatch(getNotificationList(getNotificationListResp?.data));
            return true;
        } else {
            message.error(getNotificationListResp.data.message);
            return false;
        }
    };
};

export const deleteNotificationData = (id) => {
    return async (dispatch) => {
        const deleteNotificationResp = await DataService.delete(API.notification.DeleteNotification + id);
        if (!deleteNotificationResp.data.error) {
            return dispatch(deleteNotification(true));
        } else {
            message.error(deleteNotificationResp.data.data);
            return false;
        }
    };
};

export const removeAllNotifications = (payload) => {
    return async (dispatch) => {
        const deleteALLNotificationResp = await DataService.deleteMany(API.notification.DeleteAllNotification, payload);
        if (!deleteALLNotificationResp.data.error) {
            return dispatch(deleteAllNotifications(true));
        } else {
            message.error(deleteALLNotificationResp.data.data);
            return false;
        }
    };
};
