import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const { getMaritalStatus, addMaritalStatus, updateMaritalStatus, deleteMaritalStatus } = actions;

export const maritalstatus = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";

    Object.keys(params).forEach((a) => {
        queMark += a + equalString + params[a] + ampersand;
    });
    return async (dispatch) => {
        const getData = await DataService.get(API.marital.maritalStatus + queMark);
        if (!getData.data.error) {
            dispatch(getMaritalStatus(getData?.data));
            return true;
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};

export const Add_Marital_Status = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.marital.addMarital, payload);
        if (!resp.data.error) {
            dispatch(addMaritalStatus(resp.data.data));
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Update_Marital_Status = (payload, id) => {
    return async (dispatch) => {
        const resp = await DataService.put(API.marital.updateMarital + id, payload);
        if (!resp.data.error) {
            return dispatch(updateMaritalStatus(resp.data));
            // return true
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Delete_Marital_Status = (id) => {
    return async (dispatch) => {
        const resp = await DataService.delete(API.marital.deleteMarital + id);
        if (!resp.data.error) {
            return dispatch(deleteMaritalStatus(true));
        } else {
            message.error(resp.data.data);
            return false;
        }
    };
};
