const actions = {
    GET_ADVERTISEMENT: 'GET_ADVERTISEMENT',
    GET_ADVERTISEMENT_DETAIL: 'GET_ADVERTISEMENT_DETAIL',
    ADD_ADVERTISEMENT: 'ADD_ADVERTISEMENT',
    UPDATE_ADVERTISEMENT: 'UPDATE_ADVERTISEMENT',
    DELETE_ADVERTISEMENT: 'DELETE_ADVERTISEMENT',
GET_GENERAL_ADVERTISEMENT_LIST:'GET_GENERAL_ADVERTISEMENT_LIST',

    getAdvertisement: data => {
        return {
            type: actions.GET_ADVERTISEMENT,
            data,
        };
    },
    getAdvertisementDetail: data => {
        return {
            type: actions.GET_ADVERTISEMENT_DETAIL,
            data,
        }
    },
    getGeneralAdvertisementList: data => {
        return {
            type: actions.GET_GENERAL_ADVERTISEMENT_LIST,
            data,
        }
    },
    addAdvertisement: data => {
        return {
            type: actions.ADD_ADVERTISEMENT,
            data,
        };
    },
    updateAdvertisement: data => {
        return {
            type: actions.UPDATE_ADVERTISEMENT,
            data,
        };
    },
    deleteAdvertisement: data => {
        return {
            type: actions.DELETE_ADVERTISEMENT,
            data,
        };
    },
};

export default actions;