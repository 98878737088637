const actions = {
    GET_NOTIFICATION_LIST: 'GET_NOTIFICATION_LIST',
    DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
    DELETE_ALL_NOTIFICATIONS:'DELETE_ALL_NOTIFICATIONS',

    getNotificationList: data => {
        return {
            type: actions.GET_NOTIFICATION_LIST,
            data,
        };
    },
    deleteNotification: data => {
        return {
            type: actions.DELETE_NOTIFICATION,
            data,
        };
    },
    deleteAllNotifications:data=>{
        return{
            type: actions.DELETE_ALL_NOTIFICATIONS,
            data,
        }
    }
};
export default actions;