const actions = {
    GET_RELATIONSHIP_COMBINATION: 'GET_RELATIONSHIP_COMBINATION',
    ADD_RELATIONSHIP_COMBINATION: 'ADD_RELATIONSHIP_COMBINATION',
    UPDATE_RELATIONSHIP_COMBINATION: 'UPDATE_RELATIONSHIP_COMBINATION',
    // DELETE_RELATIONSHIP_COMBINATION: 'DELETE_RELATIONSHIP_COMBINATION',

    getRelationshipCombination: data => {
        return {
            type: actions.GET_RELATIONSHIP_COMBINATION,
            data,
        };
    },
    addRelationshipCombination: data => {
        return {
            type: actions.ADD_RELATIONSHIP_COMBINATION,
            data,
        };
    },
    updateRelationshipCombination: data => {
        return {
            type: actions.UPDATE_RELATIONSHIP_COMBINATION,
            data,
        };
    },
    // deleteRelationshipCombination: data => {
    //     return {
    //         type: actions.DELETE_RELATIONSHIP_COMBINATION,
    //         data,
    //     };
    // },
};

export default actions;