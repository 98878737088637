import actions from "./actions";
const { ADD_SUBSCRIBE_USER, GET_SUBSCRIBE_USER_LIST, GET_SUBSCRIPTION_DETAIL, UPDATE_SUBSCRIBE_USER } = actions;

const initState = {
    subscriptionData: {},
    AddSubscribeuser: {},
};
const SubscriptionReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case ADD_SUBSCRIBE_USER:
            return {
                ...state,
                AddSubscribeuser: data,
            };
        case GET_SUBSCRIBE_USER_LIST:
            return {
                ...state,
                subscriptionData: data,
            };
        case GET_SUBSCRIPTION_DETAIL:
            return {
                ...state,
                subscriptionData: data,
            };
        case UPDATE_SUBSCRIBE_USER:
            return {
                ...state,
                AddSubscribeuser: data,
            };

        default:
            return state;
    }
};
export default SubscriptionReducer;
