import React, { useRef } from "react";
import useStateRef from "react-usestateref";
import { Table, Divider, Row, Col, Popover, Pagination, Button, Modal, Form, Input, Select, Image, PageHeader, Empty, Spin, Radio, Avatar, Switch } from "antd";
import "./style.css";
import { PlusOutlined, CloseOutlined, ExceptionOutlined, UserOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useRouteMatch, useHistory } from "react-router-dom";
import { advertisement, Add_Advertisement, Update_Advertisement, Delete_Advertisement } from "../../redux/advertisementList/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatusData } from "../../redux/AdminPannel/actionCreator";

const { Search } = Input;
const { TextArea } = Input;

const AdvertisementList = () => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [img, setImg] = useState("");
    const [smallImg, setsmallImg] = useState("");
    const [modalTitle, setModalTitle] = useState("Add Advertisement");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checked, setChecked] = useState(true);
    const [previewMedia, setPreviewMedia] = useState("");
    const [previewMediaSmall, setPreviewMediaSmall] = useState("");
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [radioBtn, setRadioBtn] = useState("true");
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [advertisementId, setAdvertisementId] = useState("");
    const [fileValue, setFileValue] = useState("");
    const [smallFileValue, setSmallFileValue] = useState("");

    const Push = (targetValue) => {
        history.push({
            pathname: `${path}/view_advertisement`,
            state: {
                id: targetValue,
            },
        });
    };

    const getdataAdvertisementApiData = useSelector((state) => state.AdvertisementReducer.advertisement);
    const userDetailsListData = useSelector((state) => state?.UsersStatusReducer?.userStatus?.data?.list);
    const getdataAdvertisement = getdataAdvertisementApiData && getdataAdvertisementApiData.data;
    const paginationData = getdataAdvertisementApiData && getdataAdvertisementApiData.pagination;

    useEffect(() => {
        getAdvertisementdata(currentPage, "", searchValue);
        getAll_users_Data();
    }, [dataPerPage]);

    const getAdvertisementdata = (page, pageNumber, search_Val) => {
        if (checked == true) {
            const search = search_Val === undefined ? "" : search_Val;
            let params = {
                english: search.match(/^[a-zA-Z ]+$/) ? search : "",
                hindi: search.match(/^[\u0900-\u097F ]+$/) ? search : "",
                page: page,
                type: "adv",
                limit: dataPerPage,
            };
            dispatch(advertisement(params));
        } else {
            const search = search_Val === undefined ? "" : search_Val;
            let params = {
                english: search.match(/^[a-zA-Z ]+$/) ? search : "",
                hindi: search.match(/^[\u0900-\u097F ]+$/) ? search : "",
                page: page,
                type: "user",
                limit: dataPerPage,
            };
            dispatch(advertisement(params));
        }
    };

    const getAll_users_Data = () => {
        let params = {
            english: "",
            hindi: "",
            page: "",
            limit: "",
            status: "",
        };
        dispatch(getUserStatusData(params));
    };

    // search data
    const onSearch = async (value) => {
        if (value) {
            setSearchValue(value.trim());
            getAdvertisementdata("", "", value.trim());
        }
    };

    const searchClear = () => {
        getAdvertisementdata("");
        setSearchValue();
    };

    // table pagination
    const handlePagination = (page, pageNumber) => {
        getAdvertisementdata(page, pageNumber, "");
        setCurrentPage(page);
        setDataPerPage(pageNumber);
    };

    let images = [];
    const changeHandler = (e) => {
        setImg(e.target.files[0]);
        setFileValue(e.target.files[0].name);
        if (e.target.files.length !== 0) {
            setPreviewMedia(
                new Array(e.target.files.length).fill(e.target.files).map((imgFile, i) => {
                    return <img src={URL.createObjectURL(imgFile[i])} width="70" height="70" className="addImg" name="advertisementBanner" />;
                })
            );
        }
    };

    let image = [];
    const changeHandlerSmallImg = (e) => {
        setsmallImg(e.target.files[0]);
        setSmallFileValue(e.target.files[0].name);
        if (e.target.files.length !== 0) {
            setPreviewMediaSmall(
                new Array(e.target.files.length).fill(e.target.files).map((imgFile, i) => {
                    return <img src={URL.createObjectURL(imgFile[i])} width="70" height="70" className="addImg" name="smallAdvertisementBanner" />;
                })
            );
        }
    };
    const aRef = useRef(null);
    const resetInput = () => {
        aRef.current.value = null;
    };

    const removeImg = () => {
        setPreviewMedia([]);
        setImg([]);
        setFileValue("");
        form.resetFields(["advertisementBanner"]);
    };

    const removeSmallImg = () => {
        setSmallFileValue("");
        setsmallImg([]);
        setPreviewMediaSmall([]);
        form.resetFields(["smallAdvertisementBanner"]);
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setPreviewMedia();
        setFileValue("");
        setSmallFileValue("");
        setModalTitle(t("Add_Advertisement"));
        setPreviewMediaSmall();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        setPreviewMedia("");
        setPreviewMediaSmall("");
        setImg("");
        setFileValue("");
        setSmallFileValue("");
        setsmallImg("");
    };

    const showEditModal = (e) => {
        console.log("🚀 ~ showEditModal ~ e:", e)
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Advertisement"));
        var theSplit = e.advertisementBanner !== "" ? e.advertisementBanner.split("/") : "";
        const chooseImg = theSplit !== 0 && theSplit !== undefined && theSplit !== null ? theSplit && theSplit[5] : "";
        setFileValue(chooseImg);
        if (e.smallAdvertisementBanner && e.smallAdvertisementBanner != "") {
            var smallImgSplit = e.smallAdvertisementBanner !== "" ? e.smallAdvertisementBanner.split("/") : "";
            const chooseImg2 = smallImgSplit !== 0 && smallImgSplit !== undefined && smallImgSplit !== null ? smallImgSplit && smallImgSplit[5] : "";
            setSmallFileValue(chooseImg2);
        }



        form.setFieldsValue({
            name: e?._id,
            en_title: e?.en?.title,
            hn_title: e?.hn?.title,
            en_description: e?.en?.description,
            hn_description: e?.hn?.description,
            en_payment_done_by: e?.en?.paymentDoneBy,
            hn_payment_done_by: e?.hn?.paymentDoneBy,
            paymentAmount: e?.paymentAmount,
            _id: e?.user_id?._id,
            isPaymentDone: e.isPaymentDone === true ? "true" : "false",
            type: e && e.type,
        });

        if (e.advertisementBanner !== "") {
            setPreviewMedia(<img src={e.advertisementBanner} width="70" height="70" className="addImg" name="advertisementBanner" />);
        } else if (e.smallAdvertisementBanner !== "") {
            setPreviewMediaSmall(<img src={e.smallAdvertisementBanner} width="70" height="70" className="addImg" name="smallAdvertisementBanner" />);
        } else {
            setPreviewMedia("-");
            setPreviewMediaSmall("-");
        }
    };

    const deleteGeneralADv = async (values) => {
        let a = await dispatch(Delete_Advertisement(values));
        if (a) {
            getAdvertisementdata();
        }
        setOpenModal(false);
    };

    const handleSubmit = async (value) => {
        delete value.advertisementBanner;

        const form_data = new FormData();
        setIsModalOpen(false);
        form.resetFields();
        const type = "personal_ads";
        const obj = {
            en: {},
            hn: {},
        };

        obj.en.title = value.en_title;
        obj.hn.title = value.hn_title;
        obj.en.description = value.en_description;
        obj.hn.description = value.hn_description;
        obj.en.paymentDoneBy = value.en_payment_done_by;
        obj.hn.paymentDoneBy = value.hn_payment_done_by;
        obj.paymentAmount = value.paymentAmount;
        obj.isPaymentDone = value.isPaymentDone;
        obj.user_id = value._id;
        obj.type = type;

        form_data.append("en", JSON.stringify(obj.en));
        form_data.append("hn", JSON.stringify(obj.hn));
        form_data.append("isPaymentDone", value.isPaymentDone);
        form_data.append("paymentAmount", value.paymentAmount);
        form_data.append("user_id", value._id);
        form_data.append("type", type);

        if (img != "") {
            form_data.append("advertisementBanner", img);
        }

        if (smallImg != "") {
            form_data.append("smallAdvertisementBanner", smallImg);
        }

        if (updateIdRef.current === "") {
            const AddAdvertisementData = await dispatch(Add_Advertisement(form_data));
            if (AddAdvertisementData) {
                getAdvertisementdata();
                setImg("");
                setsmallImg("");
            }
        } else {
            const updateAdvertisementData = await dispatch(Update_Advertisement(form_data, updateIdRef.current));
            if (updateAdvertisementData) {
                getAdvertisementdata(currentPage, dataPerPage);
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Image"),
            dataIndex: "advertisementBanner",
            align: "center",
            render: (index, value) => {
                {
                    if (value.advertisementBanner === "") {
                        return (
                            <>
                                <Avatar
                                    icon={<ExceptionOutlined style={{ color: "#08c" }} />}
                                    style={{ backgroundColor: "rgb(235, 238, 241)" }}
                                    size="large"
                                    shape="square"
                                />
                            </>
                        );
                    } else {
                        return (
                            <Image
                                height={45}
                                width={45}
                                crossOrigin="anonymous"
                                alt=""
                                style={{
                                    borderRadius: 50,
                                }}
                                src={`${value.advertisementBanner}`}
                            />
                        );
                    }
                }
            },
        },
        {
            title: t("Name"),
            dataIndex: ["user_id", "name"],
            align: "center",
            render: (icon, value) => {
                if ("user_id" in value) {
                    return (
                        value && value.user_id && value.user_id[i18n.language] && value.user_id[i18n.language].name + " " + value.user_id[i18n.language].surname
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            title: t("Advertisement_title"),
            dataIndex: "title",
            align: "center",
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].title}</>;
            },
        },

        {
            title: t("isPayment_Done"),
            dataIndex: "isPaymentDone",
            align: "center",
            render: (icon, v) => {
                // return v.isPaymentDone === true ? "Yes" : "No";
                return {
                    children: (
                        <span>
                            {v.isPaymentDone === true ? (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "green",
                                        borderStyle: "solid",
                                        paddingRight: "16px",
                                        paddingLeft: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        backgroundColor: "green",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                        margin: "auto",
                                    }}
                                    className="sentStyle"
                                >
                                    {" "}
                                    Yes{" "}
                                </span>
                            ) : (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "red",
                                        borderStyle: "solid",
                                        paddingRight: "16px",
                                        paddingLeft: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        backgroundColor: "red",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                        margin: "auto",
                                    }}
                                >
                                    {" "}
                                    No{" "}
                                </span>
                            )}
                        </span>
                    ),
                };
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, tareget) => {
                return (
                    <div className="actionContentBox">
                        <FeatherIcon icon="more-vertical" size="20px" color="blue" onClick={() => setVisible()} style={{ cursor: "pointer" }} />
                        <div className="Adv_tableActionContent">
                            <FeatherIcon
                                style={{ cursor: "pointer" }}
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(tareget), setIsModalOpen(true);
                                }}
                            />
                            <Divider type="vertical" />

                            <FeatherIcon
                                style={{ cursor: "pointer" }}
                                icon="trash"
                                size="20px"
                                color="red"
                                onClick={() => {
                                    setAdvertisementId(tareget._id);
                                    setOpenModal(true);
                                }}
                            />

                            <Divider type="vertical" />
                            <FeatherIcon style={{ cursor: "pointer" }} icon="eye" size="20px" color="orange" onClick={() => Push(tareget._id)} />
                        </div>
                    </div>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };
    const paymentAmount = (value) => {
        setRadioBtn(value.target.value);
    };

    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} advertisementId={advertisementId}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={require("./images.png")} style={{ height: "120px" }} />
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0 -10px",
                        }}
                    >
                        {t("Are_You_Sure_?")}
                    </p>
                    <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem" }}>{t("Do_you_really_want_to_delete_these_records_This_process_cannot_be_undone")}</p>
                    </div>
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#60c7c1",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            {t("Cancel")}
                        </button>
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#f15e5e",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                deleteGeneralADv(advertisementId);
                            }}
                        >
                            {t("Delete")}
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    // className="site-page-header"
                    title={t("Advertisement_List")}
                    className="userlist_pageHeader"
                    extra={[
                        <>
                            <Search
                                className="searchBox"
                                placeholder={t("Search")}
                                enterButton
                                size="small"
                                onSearch={(e) => onSearch(e)}
                                allowClear={{
                                    clearIcon: <CloseOutlined onClick={searchClear} />,
                                }}
                            />
                            <div className="headerBtns">
                                <Switch
                                    className="advertisement_switch"
                                    checkedChildren="Adv"
                                    unCheckedChildren="User"
                                    checked={checked}
                                    onChange={setChecked}
                                    style={{ backgroundColor: "#5F63F2" }}
                                />
                                <Button key="1" type="primary" onClick={showModal}>
                                    <PlusOutlined />
                                    {t("Add_Advertisement")}
                                </Button>
                            </div>
                        </>,
                    ]}
                />
            </div>
            <div>
                <Modal
                    open={isModalOpen}
                    okText={t("Submit")}
                    width={550}
                    onOk={form.submit}
                    cancelText={t("Cancel")}
                    onCancel={handleCancel}
                    title={modalTitle}
                    className="advertisement-modal"
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} className="AddForm">
                                <h4>{t("Image")}:</h4>
                                <Form.Item
                                    label={t("Large_Size_Image_size_1920_x_1080")}
                                    valuePropName="fileList"
                                    name="advertisementBanner"
                                    rules={[
                                        {
                                            message: "upload Large advertisement image (size : 1920 * 1080)",
                                        },
                                    ]}
                                >
                                    <Input
                                        size="small"
                                        type="file"
                                        title=""
                                        accept=".png,.jpg.jpeg,.PNG,.JPG,.JPEG"
                                        placeholder="Choose image"
                                        onChange={changeHandler}
                                        ref={aRef}
                                        suffix={
                                            <CloseOutlined
                                                onClick={() => {
                                                    removeImg();
                                                }}
                                            />
                                        }
                                    />
                                    {previewMedia}{" "}
                                    {fileValue !== undefined && fileValue !== null && fileValue !== ""
                                        ? fileValue.length > 40
                                            ? fileValue.slice(0, 40) + "..." + fileValue.split(".").pop()
                                            : fileValue
                                        : ""}
                                </Form.Item>

                                <Form.Item
                                    label={t("Small_Size_Image_size_800_x_480")}
                                    valuePropName="file"
                                    name="smallAdvertisementBanner"
                                    rules={[
                                        {
                                            message: "Please Upload Image*!",
                                        },
                                    ]}
                                >
                                    <Input
                                        size="small"
                                        type="file"
                                        title=""
                                        accept=".png,.jpg.jpeg,.PNG,.JPG,.JPEG"
                                        placeholder="choose image"
                                        onChange={changeHandlerSmallImg}
                                        ref={aRef}
                                        suffix={
                                            <CloseOutlined
                                                onClick={() => {
                                                    removeSmallImg();
                                                }}
                                            />
                                        }
                                    />
                                    {previewMediaSmall}
                                    {smallFileValue !== undefined && smallFileValue !== null && smallFileValue !== ""
                                        ? smallFileValue.length > 40
                                            ? smallFileValue.slice(0, 40) + "..." + smallFileValue.split(".").pop()
                                            : smallFileValue
                                        : ""}
                                </Form.Item>

                                <Form.Item
                                    label={t("UserName")}
                                    name="_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Select User*!",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Search and Select User by name"
                                        name="_id"
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent="This User is Not Exist"
                                        filterOption={(input, option) => {
                                            return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                    >
                                        {userDetailsListData && userDetailsListData.length > 0
                                            ? userDetailsListData.map((data) => {
                                                return (
                                                    <Select.Option key={data._id} value={data._id}>
                                                        {data.profile_photo !== "" ? (
                                                            <img
                                                                className="advUserAvatar"
                                                                height={30}
                                                                width={30}
                                                                crossOrigin="anonymous"
                                                                alt=""
                                                                src={data.profile_photo}
                                                                style={{
                                                                    borderRadius: 50,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                icon={<UserOutlined />}
                                                                style={{
                                                                    backgroundColor: "rgb(184, 184, 177)",
                                                                }}
                                                                className="advUserAvatar"
                                                            />
                                                        )}
                                                        {`${data[i18n.language].name + " " + data[i18n.language].surname}`}
                                                    </Select.Option>
                                                );
                                            })
                                            : "-"}
                                    </Select>
                                </Form.Item>
                                <h4>{t("Advertisement_Title")} :</h4>
                                <Form.Item>
                                    <Input.Group>
                                        <Form.Item
                                            label={t("english")}
                                            name="en_title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Title",
                                                },
                                                {
                                                    pattern: new RegExp(/^(?!\s)[a-zA-Z0-9 .,_?/{}()-]+$/i),
                                                    message: "Please enter valid title.",
                                                },
                                            ]}
                                        >
                                            <TextArea size="small" placeholder={t("Enter_Advertisement_title")} />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("hindi")}
                                            name="hn_title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Description",
                                                },
                                                {
                                                    pattern: new RegExp(/^(?!\s)[\u0900-\u097F0-9 .,_?/{}()-]+$/i),
                                                    message: "Please enter valid title.",
                                                },
                                            ]}
                                        >
                                            <TextArea rows={2} size="small" placeholder={t("Enter_Advertisement_title")} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>

                                <h4>{t("Advertisement_Description")} :</h4>
                                <Form.Item>
                                    <Input.Group>
                                        <Form.Item
                                            label={t("english")}
                                            name="en_description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Description",
                                                },
                                                {
                                                    pattern: new RegExp(/[a-zA-Z]/i),
                                                    message: "Please enter valid description.",
                                                },
                                            ]}
                                        >
                                            <TextArea size="small" placeholder={t("Enter_Advertisement_Description")} />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("hindi")}
                                            name="hn_description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Description",
                                                },
                                                {
                                                    pattern: new RegExp(/[\u0900-\u097F]/i),
                                                    message: "Please enter valid description.",
                                                },
                                            ]}
                                        >
                                            <TextArea rows={2} size="small" placeholder={t("Enter_Advertisement_Description")} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>

                                <Form.Item
                                    label={t("isPayment_Done")}
                                    name="isPaymentDone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Choose Any Option*!",
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={paymentAmount}>
                                        <Radio value="true"> {t("Yes")} </Radio>
                                        <Radio value="false"> {t("No")} </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {radioBtn == "true" ? (
                                    <>
                                        <h4>{t("Payment_Done_By")} :</h4>

                                        <Form.Item>
                                            <Input.Group>
                                                <Form.Item
                                                    label={t("english")}
                                                    name="en_payment_done_by"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please Enter Name*",
                                                        },
                                                        {
                                                            pattern: new RegExp(/^[^\s].*([A-Za-z]+\s)*[A-Za-z]+$/),
                                                            message: "Enter Name in English(should be More than 1 character)!",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={t("Enter_Name")} size="small" />
                                                </Form.Item>

                                                <Form.Item
                                                    label={t("hindi")}
                                                    name="hn_payment_done_by"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please Enter Name*",
                                                        },
                                                        {
                                                            pattern: new RegExp(/^[^\s].*([\u0900-\u097F]+\s)*[\u0900-\u097F]+$/),
                                                            message: "Enter Name in Hindi(should be More than 1 character)!",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={t("Enter_Name")} size="small" />
                                                </Form.Item>
                                            </Input.Group>
                                        </Form.Item>
                                        <Form.Item
                                            label={t("Payment_Amount")}
                                            name="paymentAmount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter Payment Amount*!",
                                                },
                                                {
                                                    pattern: new RegExp(/^[0-9]+$/),
                                                    message: "field only accept numbers(No space allowed)!!*)",
                                                },
                                            ]}
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getdataAdvertisement && getdataAdvertisement.length > 0 ? (
                            <>
                                <div className="Advertisement_table">
                                    <Table
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getdataAdvertisement}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                        {paginationData && paginationData !== undefined ? (
                                            <Pagination
                                                total={paginationData && paginationData.total_counts}
                                                currentPage={currentPage}
                                                onChange={handlePagination}
                                                pageSizeOptions={[5, 10, 20, 50, 100]}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                pageSize={dataPerPage}
                                                showSizeChanger
                                            />
                                        ) : (
                                            "-"
                                        )}
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getdataAdvertisementApiData).length == 0 ||
                            (getdataAdvertisement && getdataAdvertisement.length === undefined) ||
                            getdataAdvertisement == "" ? (
                            <div className="Advertisement_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getdataAdvertisement}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AdvertisementList;
