import moment from "moment";
// import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useRef, useEffect } from "react";

export const getDateFormate = (date) => {
    return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("DD/MM/YYYY");
};

export const getFileType = (fileUrl) => {
    return fileUrl?.substring(fileUrl.lastIndexOf(".") + 1);
};

export function time_ago(time) {
    switch (typeof time) {
        case "number":
            break;
        case "string":
            time = +new Date(time);
            break;
        case "object":
            if (time.constructor === Date) time = time.getTime();
            break;
        default:
            time = +new Date();
    }
    var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

    if (seconds === 0) {
        return "Just now";
    }
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
    }
    var i = 0,
        format;
    while ((format = time_formats[i++]))
        if (seconds < format[0]) {
            if (typeof format[2] == "string") return format[list_choice];
            else return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
        }
    return time;
}

export const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

// export const checkNotFound = (p = "") => {
//     const { path } = useRouteMatch();

//     console.log("🚀 ~ file: commonFunction.js:77 ~ checkNotFound ~ path:", path);

//     const knownRoutes = [
//         `${path}`,
//         `${path}users_list`,
//         `${path}users_list/user_detail`,
//         `${path}blood_groups`,
//         `${path}marital_status`,
//         `${path}contact_us`,
//         `${path}notification_list`,
//         `${path}notification_list/user_detail`,
//         `${path}event`,
//         `${path}advertisement_list`,
//         `${path}advertisement_list/view_advertisement`,
//         `${path}relationship`,
//         `${path}relationship_combination`,
//         `${path}prefix`,
//         `${path}gender`,
//         `${path}education`,
//         `${path}gotras`,
//         `${path}general_advertisement_list`,
//         `${path}general_advertisement_list/view_general_advertisement`,
//         `${path}general_advertisement_list/view_general_advertisement/user_detail`,
//         `${path}advertisement_list/view_advertisement/user_detail`,
//         `${path}faq_list`,
//         `${path}subscription`,
//         `${path}gallery`,
//         `${path}gallery/view_gallery`,
//         `${path}subscription/view_subscriptionDetails`,
//         `${path}occupation_master`,
//         `${path}profile`,
//         `${path}broadcast`,
//         `${path}broadcast/view_broadcastDetails`,
//     ];
//     const isUnknownRoutes = knownRoutes.filter((route) => p.includes(route));

//     let a = isUnknownRoutes.findIndex((i) => i === p);
//     {
//         return p == isUnknownRoutes[a] ? true : false;
//     }
// };

// autofocus input fields

/*
 *   New Check not found function
 */
export const checkNotFound = (p = "") => {
    const knownRoutes = [
        `/users_list`,
        `/users_list/user_detail`,
        `/blood_groups`,
        `/marital_status`,
        `/contact_us`,
        `/notification_list`,
        `/notification_list/user_detail`,
        `/event`,
        `/advertisement_list`,
        `/advertisement_list/view_advertisement`,
        `/relationship`,
        `/relationship_combination`,
        `/prefix`,
        `/gender`,
        `/education`,
        `/gotras`,
        `/general_advertisement_list`,
        `/general_advertisement_list/view_general_advertisement`,
        `/general_advertisement_list/view_general_advertisement/user_detail`,
        `/advertisement_list/view_advertisement/user_detail`,
        `/faq_list`,
        `/subscription`,
        `/gallery`,
        `/gallery/view_gallery`,
        `/subscription/view_subscriptionDetails`,
        `/occupation_master`,
        `/profile`,
        `/broadcast`,
        `/broadcast/view_broadcastDetails`,
    ];

    // Check if the current path is in the list of known routes
    const isUnknownRoute = knownRoutes.some((route) => p.includes(route));

    return isUnknownRoute;
};

export const useAutoFocus = () => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return inputRef;
};
