const actions = {
    GET_RELATIONSHIP: 'GET_RELATIONSHIP',
    ADD_RELATIONSHIP: 'ADD_RELATIONSHIP',
    UPDATE_RELATIONSHIP: 'UPDATE_RELATIONSHIP',
    DELETE_RELATIONSHIP: 'DELETE_RELATIONSHIP',

    getRealtionship: data => {
        return {
            type: actions.GET_RELATIONSHIP,
            data,
        };
    },
    addRealtionship: data => {
        return {
            type: actions.ADD_RELATIONSHIP,
            data,
        };
    },
    updateRealtionship: data => {
        return {
            type: actions.UPDATE_RELATIONSHIP,
            data,
        };
    },
    deleteRealtionship: data => {
        return {
            type: actions.DELETE_RELATIONSHIP,
            data,
        };
    },
};

export default actions;