import React from "react";
import useStateRef from "react-usestateref";
import { PageHeader } from "antd";
import { Table, Row, Col, Button, Modal, Form, Input, Tooltip, Pagination, Empty, Spin, Select } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import "./style.css";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { relationship, add_Relationship, update_Relationship, Delete_Relationship } from "../../redux/relationshipMaster/actionCreator";
import { Get_Gender_Data } from "../../redux/gender/actionCreator";

const { Search } = Input;

const RelationShip = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("Add RelationShip");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();

    const relationshipMasterList = useSelector((state) => state.RelationShipMasterReducer.relationship);
    const getdatarelationship = relationshipMasterList && relationshipMasterList?.data?.list;
    const RelationShipPagination = relationshipMasterList && relationshipMasterList?.data;

    const getDataGenderList = useSelector((state) => state.GenderReducer.genderData.data);
    const getdataGender = getDataGenderList && getDataGenderList && getDataGenderList.list;

    useEffect(
        () => {
            getRelationShipdata(currentPage, searchValue);
            getGenderdata();
        },
        [dataPerPage, searchValue],
        []
    );

    const getGenderdata = () => {
        let res = dispatch(Get_Gender_Data());
        if (res) {
        }
    };

    const getRelationShipdata = (currentPage, pageNumber) => {
        if (searchValue == "") {
            let params = {
                search: searchValue,
                page: currentPage,
                limit: dataPerPage,
            };
            dispatch(relationship(params));
        } else {
            let params = {
                search: searchValue,
                page: "",
                limit: "",
            };
            dispatch(relationship(params));
        }
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getRelationShipdata(page);
        setDataPerPage(pageNumber);
    };

    const onSearch = async (value) => {
        if (value) {
            setSearchValue(value.trim());
        }
    };

    const searchClear = () => {
        setSearchValue();
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setModalTitle(t("Add_Relationship"));
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Relationship"));
        form.setFieldsValue({
            en_relation_name: e.en.name,
            hn_relation_name: e.hn.name,
            gender: e.gender_id ? e.gender_id._id : "",
        });
    };

    const onDelete = async (values) => {
        let a = await dispatch(Delete_Relationship(values));
        if (a) {
            getRelationShipdata();
        }
    };

    const handleSubmit = async (value) => {
        setIsModalOpen(false);
        form.resetFields();

        const obj = {
            en: {},
            hn: {},
        };

        obj.en.name = value.en_relation_name;
        obj.hn.name = value.hn_relation_name;
        obj.gender_id = value.gender;

        if (updateIdRef.current === "") {
            const AddRelationshipData = await dispatch(add_Relationship(obj));
            if (AddRelationshipData) {
                getRelationShipdata();
            }
        } else {
            const obj = {
                en: {},
                hn: {},
            };
            obj.en.name = value.en_relation_name;
            obj.hn.name = value.hn_relation_name;
            obj.gender_id = value.gender;
            const updateRelationshipData = await dispatch(update_Relationship(obj, updateIdRef.current));
            if (updateRelationshipData) {
                getRelationShipdata(currentPage, dataPerPage);
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Relationship"),
            dataIndex: "name",
            align: "center",
            sorter: (a, b) => {
                return a.en.name.localeCompare(b.en.name);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].name}</>;
            },
        },
        {
            title: t("Gender"),
            dataIndex: "gender",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.gender_id && value.gender_id[i18n.language] && value.gender_id[i18n.language].name}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <Tooltip placement="right">
                            <FeatherIcon
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(t), setIsModalOpen(true);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    title={t("Relationship")}
                    extra={[
                        <>
                            <Search
                                className="searchBox"
                                placeholder={t("search_by_title")}
                                enterButton
                                size="small"
                                onSearch={(e) => onSearch(e)}
                                onChange={(e) => setSearchValue(e.target.value)}
                                allowClear={{
                                    clearIcon: <CloseOutlined onClick={searchClear} />,
                                }}
                            />
                            <Button key="1" type="primary" onClick={showModal}>
                                <PlusOutlined /> {t("Add_Relationship")}
                            </Button>
                        </>,
                    ]}
                />
            </div>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Modal
                        open={isModalOpen}
                        okText={t("Submit")}
                        // onOk={handleOk}
                        cancelText={t("Cancel")}
                        onCancel={handleCancel}
                        onOk={form.submit}
                        title={modalTitle}
                    >
                        <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                            <Form.Item
                                label={t("Enter_Relationship_In_English")}
                                name="en_relation_name"
                                rules={[
                                    {
                                        required: true,
                                        message: t("This_Field_Is_Required"),
                                    },
                                    {
                                        pattern: new RegExp(/^(?!\s)[a-zA-Z ./{}()-]+$/i),
                                        message: t("Field_Accept_Only_English_String_Value"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("Enter_Relationship")} />
                            </Form.Item>

                            <Form.Item
                                label={t("Enter_Relationship_In_Hindi")}
                                name="hn_relation_name"
                                rules={[
                                    {
                                        required: true,
                                        message: t("This_Field_Is_Required"),
                                    },
                                    {
                                        pattern: new RegExp(/^(?!\s)[\u0900-\u097F ./{}()-]+$/i),
                                        message: t("Field_Accept_Only_Hindi_String_Value"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("Enter_Relationship")} />
                            </Form.Item>
                            {/* 
              <Form.Item label={t('Gender')} name='hn_relation_name'
                rules={[
                  {
                    required: true,
                    message: t('This_Field_Is_Required'),
                  },
                  {
                    pattern: new RegExp(/^(?!\s)[\u0900-\u097F ./{}()-]+$/i),
                    message: t('Field_Accept_Only_Hindi_String_Value'),
                  },
                ]}>
                <Input placeholder={t('Gender')} />
              </Form.Item>
             */}

                            <Form.Item
                                label={t("Gender")}
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please_Select_Gender"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Search and Select Gender"
                                    name="gender_id"
                                    showSearch
                                    allowClear
                                    placement="bottomRight"
                                    style={{ width: "100%" }}
                                    notFoundContent="This User is Not Exist"
                                    filterOption={(input, option) => {
                                        return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    {getdataGender && getdataGender.length > 0
                                        ? getdataGender.map((data) => {
                                            return (
                                                <Select.Option key={data._id} value={data._id}>
                                                    {data[i18n.language].name !== "" ? "" : "--"}
                                                    {`${data[i18n.language].name}`}
                                                </Select.Option>
                                            );
                                        })
                                        : "-"}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Col>
            </Row>

            <Row>
                <Col xs={24} sm={24} md={24}>
                    {getdatarelationship && getdatarelationship.length > 0 ? (
                        <>
                            <div className="master_table">
                                <Table
                                    showSorterTooltip={false}
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    columns={columns}
                                    dataSource={getdatarelationship}
                                    pagination={false}
                                />
                            </div>
                            <Row className="pagination_Layout" justify="center">
                                <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                    <Pagination
                                        total={RelationShipPagination.total_counts}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        pageSize={dataPerPage}
                                        currentPage={currentPage}
                                        pageSizeOptions={[10, 20, 50, 100]}
                                        showSizeChanger
                                        onChange={handlePagination}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : Object.keys(relationshipMasterList).length == 0 ||
                        (getdatarelationship && getdatarelationship.length === undefined) ||
                        (getdatarelationship && getdatarelationship == "") ? (
                        <div className="master_table">
                            <Table
                                scroll={{
                                    x: "3vh",
                                }}
                                locale={locale}
                                columns={columns}
                                dataSource={getdatarelationship}
                                pagination={false}
                            />
                        </div>
                    ) : (
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="spinnerName">
                                    <Spin size="large"></Spin>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default RelationShip;
