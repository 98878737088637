const actions = {
    GET_BLOODGROUP: 'GET_BLOODGROUP',
    ADD_BLOODGROUP: 'ADD_BLOODGROUP',
    UPDATE_BLOODGROUP: 'UPDATE_BLOODGROUP',
    DELETE_BLOODGROUP: 'DELETE_BLOODGROUP',


    getBloodGroup: data => {
        return {
            type: actions.GET_BLOODGROUP,
            data,
        };
    },
    addBloodGroup: data => {
        return {
            type: actions.ADD_BLOODGROUP,
            data,
        };
    },
    updateBloodGroup: data => {
        return {
            type: actions.UPDATE_BLOODGROUP,
            data,
        };
    },
    deleteBloodGroup: data => {
        return {
            type: actions.DELETE_BLOODGROUP,
            data,
        };
    },
};

export default actions;